import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { IMAGE_FILE_URL } from '../../../../environments/environment';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  panelOpenState = false;
  imageFileUrl = IMAGE_FILE_URL;

  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appService: AppService, public data: DataProvider, public router: Router) {}

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
  }

  menuFinance() {
    var element = document.getElementById("menu-finance");
    var elem = document.getElementById('submenu-finance');
    if (elem.style.display === "none") {
      elem.style.display = "block";
      element.classList.add("menu-open");
    } else {
      elem.style.display = "none";
      element.classList.remove("menu-open");
    }
  }

  menuSettings() {
    var element = document.getElementById("menu-settings");
    var elem = document.getElementById('submenu-settings');
    if (elem.style.display === "none") {
      elem.style.display = "block";
      element.classList.add("menu-open");
    } else {
      elem.style.display = "none";
      element.classList.remove("menu-open");
    }
  }

  menuReport() {
    var element = document.getElementById("menu-report");
    var elem = document.getElementById('submenu-report');
    if (elem.style.display === "none") {
      elem.style.display = "block";
      element.classList.add("menu-open");
    } else {
      elem.style.display = "none";
      element.classList.remove("menu-open");
    }
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }
}
