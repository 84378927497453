<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4 fixed">
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link" title="Манай компани">
    <img
      src="assets/img/logo.png"
      alt="Logo"
      class="brand-image img-circle elevation-3"
      style="opacity: 1;"
    />
    <span class="brand-text font-weight-light">Simatai</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex align-items-center justify-content-araound" title="Миний хувийн мэдээлэл">
      <div class="image">
        <img
          src="assets/img/no-image.png"
          class="img-circle elevation-2"
          alt="User Image"
          *ngIf="!data.userData.image"
        />
        <img
          src="{{imageFileUrl}}/images/{{data.userData.image}}"
          class="img-circle elevation-2"
          alt="User Image"
          *ngIf="data.userData.image"
        />
      </div>
      <div class="info" >
        <p class="m-0 text">
          {{data.userData.lname.substring(0,1)}}. {{data.userData.fname}}
        </p>
        <a [routerLink]="['/profile']" class="d-block subtext">{{data.userData.name}}
          <i class="fas fa-play-circle" aria-hidden="true" style="margin-left: 10px;font-size: 12px;"></i>
        </a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item active" *ngIf="data.menuPageTitle == 'Home'">
          <a [routerLink]="['/']" class="nav-link" title="Нүүр хуудас">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Dashboard
            </p>
          </a>
        </li>
        <li class="nav-item" *ngIf="data.menuPageTitle != 'Home'">
          <a [routerLink]="['/']" class="nav-link" title="Нүүр хуудас">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Dashboard
            </p>
          </a>
        </li>
        <li class="nav-item active" *ngIf="data.menuPageTitle == 'Inquiry'">
          <a [routerLink]="['/inquiry']" class="nav-link" title="Асуулгууд">
            <i class="nav-icon fas fa-notes-medical"></i>
            <p>
              Inquiry
            </p>
          </a>
        </li>
        <li class="nav-item" *ngIf="data.menuPageTitle != 'Inquiry'">
          <a [routerLink]="['/inquiry']" class="nav-link" title="Асуулгууд">
            <i class="nav-icon fas fa-notes-medical"></i>
            <p>
              Inquiry
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'closed-inquiry'">
          <a [routerLink]="['/closed-inquiry']" class="nav-link" title="Хаагдсан асуулгууд">
            <i class="nav-icon fas fa-sticky-note"></i>
            <p>
              Closed Inquiry
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'closed-inquiry'">
          <a [routerLink]="['/closed-inquiry']" class="nav-link" title="Хаагдсан асуулгууд">
            <i class="nav-icon fas fa-sticky-note"></i>
            <p>
              Closed Inquiry
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'shipping-order'">
          <a [routerLink]="['/shipping-order']" class="nav-link" title="Захиалгууд">
            <i class="nav-icon fas fa-folder"></i>
            <p>
              Shipping Order
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'shipping-order'">
          <a [routerLink]="['/shipping-order']" class="nav-link" title="Захиалгууд">
            <i class="nav-icon fas fa-folder"></i>
            <p>
              Shipping Order
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'operation'">
          <a [routerLink]="['/operation']" class="nav-link" title="Захиалгын явц">
            <i class="nav-icon fas fa-folder-open"></i>
            <p>
              Operation
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'operation'">
          <a [routerLink]="['/operation']" class="nav-link" title="Захиалгын явц">
            <i class="nav-icon fas fa-folder-open"></i>
            <p>
              Operation
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'soc'">
          <a [routerLink]="['/soc']" class="nav-link" title="Өөрсдийн чингэлэг">
            <i class="nav-icon fas fa-clone"></i>
            <p>
              SOC Simatai
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'soc'">
          <a [routerLink]="['/soc']" class="nav-link" title="Өөрсдийн чингэлэг">
            <i class="nav-icon fas fa-clone"></i>
            <p>
              SOC Simatai
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'custom'">
          <a [routerLink]="['/custom']" class="nav-link" title="Customs">
            <i class="nav-icon fas fa-train"></i>
            <p>
              Customs
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'custom'">
          <a [routerLink]="['/custom']" class="nav-link" title="Customs">
            <i class="nav-icon fas fa-train"></i>
            <p>
              Customs
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'side-manager'">
          <a [routerLink]="['/side-manager']" class="nav-link" title="Site manager">
            <i class="nav-icon fas fa-inbox"></i>
            <p>
              Site Manager
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'side-manager'">
          <a [routerLink]="['/side-manager']" class="nav-link" title="Site manager">
            <i class="nav-icon fas fa-inbox"></i>
            <p>
              Site Manager
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'finished'">
          <a [routerLink]="['/finished']" class="nav-link" title="Дууссан захиалгууд">
            <i class="nav-icon fas fa-truck"></i>
            <p>
              Finished Shipments
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'finished'">
          <a [routerLink]="['/finished']" class="nav-link" title="Дууссан захиалгууд">
            <i class="nav-icon fas fa-truck"></i>
            <p>
              Finished Shipments
            </p>
          </a>
        </li>

        <li class="nav-item menu-down-up" id="menu-finance">
          <a class="nav-link" (click)="menuFinance()" title="Санхүүгийн хэсэг">
            <i class="nav-icon fas fa-credit-card"></i>
            <p>
              Finance
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" style="display: none;" id='submenu-finance'>
            <li class="nav-item active"  *ngIf="data.menuPageTitle == 'finance'">
              <a [routerLink]="['/finance']" class="nav-link">
                <p>
                  List
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="data.menuPageTitle != 'finance'">
              <a [routerLink]="['/finance']" class="nav-link">
                <p>
                  List
                </p>
              </a>
            </li>
            <li class="nav-item active"  *ngIf="data.menuPageTitle == 'invoice'">
              <a [routerLink]="['/invoice']" class="nav-link">
                <p>
                  Invoice
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="data.menuPageTitle != 'invoice'">
              <a [routerLink]="['/invoice']" class="nav-link">
                <p>
                  Invoice
                </p>
              </a>
            </li>
            <li class="nav-item active"  *ngIf="data.menuPageTitle == 'payable'">
              <a [routerLink]="['/payable']" class="nav-link">
                <p>
                  Payable
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="data.menuPageTitle != 'payable'">
              <a [routerLink]="['/payable']" class="nav-link">
                <p>
                  Payable
                </p>
              </a>
            </li>
            <li class="nav-item active"  *ngIf="data.menuPageTitle == 'receivable'">
              <a [routerLink]="['/receivable']" class="nav-link">
                <p>
                  Receivable
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="data.menuPageTitle != 'receivable'">
              <a [routerLink]="['/receivable']" class="nav-link">
                <p>
                  Receivable
                </p>
              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item active"  *ngIf="data.menuPageTitle == 'archive'">
          <a [routerLink]="['/archive']" class="nav-link" title="Архив">
            <i class="nav-icon fas fa-archive"></i>
            <p>
              Archive
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'archive'">
          <a [routerLink]="['/archive']" class="nav-link" title="Архив">
            <i class="nav-icon fas fa-archive"></i>
            <p>
              Archive
            </p>
          </a>
        </li> -->
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'reports'">
          <a [routerLink]="['/reports']" class="nav-link" title="Тайлангийн хэсэг">
            <i class="nav-icon fas fa-bars"></i>
            <p>
              Reports
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'reports'">
          <a [routerLink]="['/reports']" class="nav-link">
            <i class="nav-icon fas fa-bars"></i>
            <p>
              Reports
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'users'">
          <a [routerLink]="['/users']" class="nav-link" title="Системийн хэрэглэгчид">
            <i class="nav-icon fas fa-users"></i>
            <p>
              System Users
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'users'">
          <a [routerLink]="['/users']" class="nav-link" title="Системийн хэрэглэгчид">
            <i class="nav-icon fas fa-users"></i>
            <p>
              System Users
            </p>
          </a>
        </li>
        <li class="nav-item active"  *ngIf="data.menuPageTitle == 'currency'">
          <a [routerLink]="['/currency-list']" class="nav-link" title="Валютын ханш">
            <i class="nav-icon fas fa-industry"></i>
            <p>
              Exchange Rate
            </p>
          </a>
        </li>
        <li class="nav-item"  *ngIf="data.menuPageTitle != 'currency'">
          <a [routerLink]="['/currency-list']" class="nav-link" title="Валютын ханш">
            <i class="nav-icon fas fa-industry"></i>
            <p>
              Exchange Rate
            </p>
          </a>
        </li>

        <li class="nav-item menu-down-up" id="menu-settings">
          <a class="nav-link" (click)="menuSettings()" title="Нэмэлт мэдээллийн хэсэг">
            <i class="nav-icon fa fa-cog"></i>
            <p>
              Settings
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" style="display: none;" id='submenu-settings'>
            <li class="nav-item active"  *ngIf="data.menuPageTitle == 'settings-agents'">
              <a [routerLink]="['/settings-agents']" class="nav-link">
                <p>
                  Agents
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="data.menuPageTitle != 'settings-agents'">
              <a [routerLink]="['/settings-agents']" class="nav-link">
                <p>
                  Agents
                </p>
              </a>
            </li>
            <li class="nav-item active"  *ngIf="data.menuPageTitle == 'settings-consignees'">
              <a [routerLink]="['/settings-consignees']" class="nav-link">
                <p>
                  Consignees
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="data.menuPageTitle != 'settings-consignees'">
              <a [routerLink]="['/settings-consignees']" class="nav-link">
                <p>
                  Consignees
                </p>
              </a>
            </li>
            <li class="nav-item active"  *ngIf="data.menuPageTitle == 'settings-suppliers'">
              <a [routerLink]="['/settings-suppliers']" class="nav-link">
                <p>
                  Suppliers
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="data.menuPageTitle != 'settings-suppliers'">
              <a [routerLink]="['/settings-suppliers']" class="nav-link">
                <p>
                  Suppliers
                </p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>
