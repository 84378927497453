<li class="nav-item dropdown">
  <a class="nav-link" data-toggle="dropdown" (click)="toggleDropdownMenu()">
    <i class="far fa-bell" style="font-size: 25px;"></i>
    <span class="badge badge-danger navbar-badge">{{data.userData.notifications.inquiry + 
      data.userData.notifications.shipping_order + data.userData.notifications.custom + data.userData.notifications.site +
      data.userData.notifications.operation + data.userData.notifications.invoices}}</span>
  </a>
  <div #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    <span class="dropdown-item dropdown-header">{{data.userData.notifications.inquiry + 
      data.userData.notifications.shipping_order + data.userData.notifications.custom + data.userData.notifications.site +
      data.userData.notifications.operation + data.userData.notifications.invoices}} Notifications</span>
    <div class="dropdown-divider"></div>
    <a (click)="load('inquiry')" class="dropdown-item" *ngIf="data.userData.notifications.inquiry > 0">
      <i class="fas fa-notes-medical mr-2"></i> {{data.userData.notifications.inquiry}} Асуулга
      <span class="float-right text-muted text-sm">ирлээ</span>
    </a>
    <div class="dropdown-divider"></div>
    <a (click)="load('order')" class="dropdown-item" *ngIf="data.userData.notifications.shipping_order > 0">
      <i class="fas fa-book mr-2"></i> {{data.userData.notifications.shipping_order}} Shipping order
      <span class="float-right text-muted text-sm">ирлээ</span>
    </a>
    <div class="dropdown-divider"></div>
    <a (click)="load('operation')" class="dropdown-item" *ngIf="data.userData.notifications.operation > 0">
      <i class="fas fa-tasks mr-2"></i> {{data.userData.notifications.operation}} Operation
      <span class="float-right text-muted text-sm">ирлээ</span>
    </a>
    <div class="dropdown-divider"></div>
    <a (click)="load('custom')" class="dropdown-item" *ngIf="data.userData.notifications.custom > 0">
      <i class="fas fa-file mr-2"></i> {{data.userData.notifications.custom}} Customs
      <span class="float-right text-muted text-sm">ирлээ</span>
    </a>
    <div class="dropdown-divider"></div>
    <a (click)="load('site')" class="dropdown-item" *ngIf="data.userData.notifications.site > 0">
      <i class="fas fa-file mr-2"></i> {{data.userData.notifications.site}} Sites
      <span class="float-right text-muted text-sm">ирлээ</span>
    </a>
    <div class="dropdown-divider"></div>
    <a (click)="load('invoice')" class="dropdown-item" *ngIf="data.userData.notifications.invoices > 0">
      <i class="fas fa-file mr-2"></i> {{data.userData.notifications.invoices}} Invoice
      <span class="float-right text-muted text-sm">ирлээ</span>
    </a>
    <div class="dropdown-divider"></div>
    <!-- <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> -->
  </div>
</li>

<ng-template #notification>
  <a href="#" class="dropdown-item">
    <i class="fas fa-envelope mr-2"></i> 4 new messages
    <span class="float-right text-muted text-sm">3 mins</span>
  </a>
  <div class="dropdown-divider"></div>
</ng-template>
