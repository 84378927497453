<div class="inquiry-download">
<div class="pageA4">
  <div class="printArea">

     <div class="is-flex flex-row justify" style="margin-bottom: 15px;">
        <div class="flex-column text-align-center" style="padding-left: 40px;">
           <img src="assets/img/logo.png" width="100" /> 
        </div>
        <div class="flex-column text-align-center" style="flex: 1">
           <h2>Тээврийн үнийн санал</h2>
        </div>
     </div>


     <div>
        <table width="100%" class="table">
           <!-- Line 1 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Захиалагч нэгж:<br>
                 <span class="t-grey">Orderer</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                {{consignee_name}}
              </td>
              <td class="td text-align-center" width="15%">
                 Байгууллагын нэр: <br>
                 <span class="t-grey">Company name</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                 Simatai Mongolia LLC
              </td>
           </tr>
           <!-- Line 2 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Ажил үйлчилгээний нэр:<br>
                 <span class="t-grey">Description</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                  {{inquiry_type_name}}                                                                                                                                                                              
              </td>
              <td class="td text-align-center" width="15%">
                 Регистрийн дугаар: <br>
                 <span class="t-grey">Registration No</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                 6332404
              </td>
           </tr>
           <!-- Line 4 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 ХАА-ын ажилтан:<br>
                 <span class="t-grey">Buyer</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                  <br>
                 
              </td>
              <td class="td text-align-center" width="15%">
                 Хаяг: <br>
                 <span class="t-grey">Address</span>
              </td>
              <td class="td text-align-center back-grey fontSize10" width="35%">
                 Улаанбаатар хот, Хан-Уул дүүрэг, 15-р хороо, Махатма Гандигийн гудамж, Галакси тауэр 1005 тоот
              </td>
           </tr>
           <!-- Line 5 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Инкотерм:<br>
                 <span class="t-grey">Incoterm</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">               
                                         {{incoterm_name}}                     
                                                              
                                   </td>
              <td class="td text-align-center" width="15%">
                 Холбоо барих ажилтны мэдээлэл: <br>
                 <span class="t-grey">Contact person</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                                     {{user_name}} , {{user_phone}}
                                   </td>
           </tr>
           <!-- Line 6 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Огноо:<br>
                 <span class="t-grey">Date</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                 {{dateNow}}
              </td>
              <td class="td text-align-center" width="15%">
                 Хүчинтэй хугацаа: <br>
                 <span class="t-grey">Valid date</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                 {{validaty_date}}
              </td>
           </tr>
           <!-- Line 7 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Тээврийн төрөл:<br>
                 <span class="t-grey">Shipping mode</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">  
               {{transport_mode_name}}                         
              </td>
              <td class="td text-align-center" width="15%">
                 Тээврийн замнал: <br>
                 <span class="t-grey">Transportation route</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
               {{pol_country_name}}/ {{pol_city_name}} - {{final_country_name}}/{{final_city_name}}
              </td>
           </tr>
           <!-- Line 8 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Хаяг: <br>
                 <span class="t-grey">Pick-up address</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
               {{final_country_name}}, {{final_city_name}}
              </td>
              <td class="td text-align-center" width="15%">
                 Нийлүүлэгчийн мэдээлэл: <br>
                 <span class="t-grey">Supplier Info</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                 {{supplier_name}}
              </td>
           </tr>
           <!-- Line 9 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Хэмжээ/Эзлэхүүн:<br>
                 <span class="t-grey">Dimension/Cbm</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">                 
                 {{box_weight}} кг    -   {{cbm}} м3
              </td>
              <td class="td text-align-center" width="15%">
                 Баглаа/тоо ширхэг: <br>
                 <span class="t-grey">Package/qty</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                {{quantity}} ширхэг
              </td>
           </tr>
           <!-- Line 10 -->
           <tr class="tr">
              <td class="td text-align-center" width="15%">
                 Ачааны төрөл:<br>
                 <span class="t-grey">Commodity</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
                                                                    {{commodity}}
                                                                                                                                                                                                                                               </td>
              <td class="td text-align-center" width="15%">
                 Тээвэрлэлтийн хугацаа: <br>
                 <span class="t-grey">Transit time</span>
              </td>
              <td class="td text-align-center back-grey" width="35%">
               {{transit_time}}
                                                           </td>
           </tr>
        </table>
     </div>


     <div>

        <table width="100%" class="table">
           <tr class="tr">
              <td class="td back-grey text-align-center" width="30%">
                 Зардлын нэр <br>
                 Cost name
              </td>
              <td class="td back-grey text-align-center" width="20">
                 Тоо хэмжээ <br>
                 Quantity
              </td>
              <td class="td back-grey text-align-center">
                 Нэгж үнэ <br>
                 Unit Price
              </td>
              <td class="td back-grey text-align-center">
                 Дүн <br>
                 Amount
              </td>
              <td class="td back-grey text-align-center">
                 НӨАТ <br>
                 VAT
              </td>
              <td class="td back-grey text-align-center">
                 Нийт дүн <br>
                 Total amount
              </td>
           </tr>
           <tr class="tr" *ngFor = "let data of expenses">
              <td class="td" width="30%">
                 {{data.name}} <br>
                 <span class="t-grey">{{data.engname}}</span>
              </td>
              <td class="td back-grey text-align-center">
               {{data.quantity}}            
              </td>
              <td class="td back-grey text-align-center">
                {{data.price}}                                     
              </td>
              <td class="td back-grey text-align-center">
                {{data.total_amount}}
              </td>
              <td class="td back-grey text-align-center">
               {{data.vat}}
                </td>
              <td class="td back-grey text-align-center">
               {{data.total_amount}}
              </td>
           </tr>
                          <!-- <tr class="tr">
              <td class="td" width="30%">
                 Нийт / Зардлын дүнг EUR-р тооцно  /: <br>
                 <span class="t-grey">Total / Cost must be in EUR </span>
              </td>
              <td class="td back-grey text-align-center" width="20">
                 1.00
              </td>
              <td class="td back-grey text-align-center">
                 2,229.18 €
              </td>
              <td class="td back-grey text-align-center">
                 2,229.18 €
              </td>
              <td class="td back-grey text-align-center">
                 30.82 €
              </td>
              <td class="td back-grey text-align-center">
                 2,260.00 €
              </td>
           </tr> -->

           <tr class="tr">
              <td class="td back-grey text-align-center" colspan="3" style="font-size: 13px; line-height:14px;">
                 <strong>Нийт: </strong>  <br>
                 <span class="t-grey">Total</span>
              </td>
              <td class="td back-grey text-align-center" colspan="2" style="font-size: 13px; line-height:14px;">
                <strong> {{total}}  </strong>
              </td>
              <td class="td back-grey text-align-center">
                 
              </td>                  
           </tr>
           
                       </table>

     </div>

     <div class="text-align-center" style="padding: 10px 0;">
        <div class="bigtext">Нэмэлт мэдээлэл болон нөхцөл</div>
        <div class="bigtextgrey t-grey">Terms and Conditions</div>
     </div>

     <table class="table" width="100%">
        <tr class="tr">
           <td class="td back-grey text-align-center" colspan="2">
              <p class="redtext"></p>
           </td>
        </tr>
        <tr class="tr">
           <td class="td text-align-center">
              Гарын үсэг /тамга/: <br>
              <span class="t-grey">Signature / Stamp</span>
           </td>
           <td class="td back-grey" width="80%">
              <div style="position: relative">
                                      
              </div>
           </td>
        </tr>
     </table>

  </div>
</div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
   <p style="color: #bf2429 " > Уншиж байна... </p>
 </ngx-spinner>