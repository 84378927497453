import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.scss'],
})
export class InvoicePrintComponent implements OnInit {
  public id;
  public type;
  shipping_order_name;
  invoice_number;
  pol_country_name;
  pol_city_name;
  trans_type_name;
  payer_name;
  commodity;
  box_height;
  box_length;
  box_weight;
  box_width;
  invoice_date;
  rate;
  currency_name;
  price;
  inquiry_expences = [];
  is_vat;
  border_name;
  total_charac;
  invoice_amount;
  pre_payment;
  custom;

  constructor(
    private data: DataProvider,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private rest: CallapiService,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Invoice Print");
  }

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.type = this.route.snapshot.paramMap.get('type');
    if(this.route.snapshot.paramMap.get('custom')) {
      this.custom = this.route.snapshot.paramMap.get('custom');
    }
    this.spinner.show();
    this.rest.invoiceDetail(this.id).subscribe((res1) => {
      this.spinner.hide();
      this.shipping_order_name = res1.detail.shipping_order_name;
      this.invoice_number = res1.detail.shipping_order_name.substring(2);
      this.pol_country_name = res1.detail.pol_country_name;
      this.pol_city_name = res1.detail.pol_city_name;
      this.trans_type_name = res1.detail.trans_type_name;
      this.payer_name = res1.detail.payer_name;
      this.commodity = res1.detail.commodity;
      this.box_height = res1.detail.box_height;
      this.box_length = res1.detail.box_length;
      this.box_weight = res1.detail.box_weight;
      this.box_width = res1.detail.box_width;
      this.invoice_date = res1.detail.invoice_date;
      this.is_vat = res1.detail.is_vat;
      this.border_name = res1.detail.border_name;
      this.inquiry_expences = res1.inquiry_expences;
      if(this.inquiry_expences.length > 1) {
        let price = 0;
        let percent = 0;
        let vat = 0;
        let total_amount = 0;
        for(let i = 2; i < this.inquiry_expences.length; i++) {
          price = price + this.inquiry_expences[i].price;
          percent = percent + this.inquiry_expences[i].percentage;
          vat = vat + this.inquiry_expences[i].vat;
          total_amount = total_amount + this.inquiry_expences[i].total_amount;
        }
        if(this.inquiry_expences.length > 2) {
          this.inquiry_expences.splice(3);
          this.inquiry_expences[2].price = price;
          this.inquiry_expences[2].percentage = percent;
          this.inquiry_expences[2].vat = vat;
          this.inquiry_expences[2].total_amount = total_amount;
        }
      }
      this.rate = res1.inquiry_quotation.rate;
      if(res1.inquiry_quotation.currency_name == 'MNT'){
        this.currency_name = "₮";
      } else if (res1.inquiry_quotation.currency_name == 'USD-MGB'){
        this.currency_name = "$";
      } else if (res1.inquiry_quotation.currency_name == 'EURO'){
        this.currency_name = "€";
      } else if (res1.inquiry_quotation.currency_name == 'RMB-MGB'){
        this.currency_name = "¥";
      }
      this.price = res1.inquiry_quotation.price;
      this.invoice_amount = res1.detail.invoice_amount;
      this.pre_payment = res1.detail.pre_payment;
      
      this.total_charac = this.tooToStr(this.price);
      if(res1.inquiry_quotation.currency_name == 'MNT'){
        this.total_charac = this.total_charac  + ' төгрөг';
      } else if (res1.inquiry_quotation.currency_name == 'USD-MGB'){
        this.total_charac = this.total_charac  + ' доллар';
      } else if (res1.inquiry_quotation.currency_name == 'EURO'){
        this.total_charac = this.total_charac  + ' евро';
      } else if (res1.inquiry_quotation.currency_name == 'RMB-MGB'){
        this.total_charac = this.total_charac  + ' юань';
      }
    },(err) => {
      console.log(err);
      this.spinner.hide();
    });    
  }

  tooToStr(input_number) {
    const unit_one = [
      'тэг',
      'нэг',
      'хоёр',
      'гурав',
      'дөрөв',
      'тав',
      'зургаа',
      'долоо',
      'найм',
      'ес',
    ];

    const unit_two = [
      '',
      'арван',
      'хорин',
      'гучин',
      'дөчин',
      'тавин',
      'жаран',
      'далан',
      'наян',
      'ерэн'
    ];

    const unit_three = [
      '',
      'нэг зуун',
      'хоёр зуун',
      'гурван зуун',
      'дөрвөн зуун',
      'таван зуун',
      'зургаан зуун',
      'долоон зуун',
      'найман зуун',
      'есөн зуун'
    ];

    const unit_special = [
       '',
       'нэг',
       'хоёр',
       'гурван',
       'дөрвөн',
       'таван',
       'зургаан',
       'долоон',
       'найман',
       'есөн',
    ];

    const unit_name = [
      '',
      'мянга',
      'сая',
      'тэр бум',
      'наяд',
      'маш дэлгэмэл',
      'тунамал',
      'их тунамал',
      'ингүүмэл',
      'их ингүүмэл',
      'хямралгүй',
      'ялгаруулагч',
      'өвөр дээр',
      'хөөн удирдагч',
      'хязгаар үзэгдэл',
      'шалтгааны зүйл',
      'үзэсгэлэнт гэрэл',
      'эрхэт',
      'сайтар хүргэсэн',
      'онон одох',
      'живэх тоосон',
      'бэлэг тэмдэг',
      'хүчин нөхөр',
      'дохио мэдэхгүй',
      'тийн болсон',
      'хүчин нүдэн',
      'асрангуй',
      'нигүүлсэнгүй',
      'баясангуй',
      'тэгш',
      'тоомьгүй',
      'хэмжээлшгүй',
      'цаглашгүй',
      'өгүүлэшгүй',
      'хирлэшгүй',
      'үлэж дуусашгүй',
      'сэтгэшгүй'
    ];

    if (input_number > 0) {
      let number = this.str_pad(input_number, Math.ceil(input_number.toString().length / 3) * 3, 0, 'STR_PAD_LEFT');
      let parts = (this.str_split(number, 3)).reverse();
      let string = [];
      for(let i = 0; i < parts.length; i++) {
        if (parts[i] > 0) {
          let digits = [];
          let str_part = parts[i].toString();
          let arr_part = this.str_split(str_part, null);
          for(let j = 0; j < arr_part.length; j++) {
              if(j > 1) {
                  digits.push(i == 0 ? unit_one[arr_part[j]] : unit_special[arr_part[j]]);
              } else if(j == 1) {
                  digits.push(unit_two[arr_part[j]]);
              } else {
                  digits.push(unit_three[arr_part[j]]);
              }
          }
          digits.push(unit_name[i]);
          string.push(digits.join(' '));
        }
      }
      let str = string.join(' ');
      let strSplits = str.split('   ');
      str = strSplits.reverse().toString().replace(/[,]/g, ' ');
      return str;
    } else {
      return unit_one[input_number];
    }
  }

  str_pad (input, padLength, padString, padType) {
    let half = ''
    let padToGo
    const _strPadRepeater = function (s, len) {
      let collect = ''
      while (collect.length < len) {
        collect += s
      }
      collect = collect.substr(0, len)
      return collect
    }
    input += ''
    padString = padString !== undefined ? padString : ' '
    if (padType !== 'STR_PAD_LEFT' && padType !== 'STR_PAD_RIGHT' && padType !== 'STR_PAD_BOTH') {
      padType = 'STR_PAD_RIGHT'
    }
    if ((padToGo = padLength - input.length) > 0) {
      if (padType === 'STR_PAD_LEFT') {
        input = _strPadRepeater(padString, padToGo) + input
      } else if (padType === 'STR_PAD_RIGHT') {
        input = input + _strPadRepeater(padString, padToGo)
      } else if (padType === 'STR_PAD_BOTH') {
        half = _strPadRepeater(padString, Math.ceil(padToGo / 2))
        input = half + input + half
        input = input.substr(0, padLength)
      }
    }
    return input
  }

  str_split (string, splitLength) {
    if (splitLength === null) {
      splitLength = 1;
    }
    if (string === null || splitLength < 1) {
      return [];
    }
    string += ''
    let chunks = []
    let pos = 0
    const len = string.length
    while (pos < len) {
      chunks.push(string.slice(pos, pos += splitLength))
    }
    return chunks;
  }
}
