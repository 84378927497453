<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Profile</h1>
      </div>
      <!-- <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">User Profile</li>
        </ol>
      </div> -->
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <!-- Profile Image -->
        <div class="card card-primary card-outline">
          <div class="card-body box-profile">
            <div class="text-center" *ngIf="check">
              <img
                class="profile-user-img img-fluid img-circle"
                src="assets/img/no-image.png"
                alt="User profile picture"
                *ngIf="!data.userData.image"
              />
              <img
                class="profile-user-img img-fluid img-circle"
                src="{{imageFileUrl}}/images/{{data.userData.image}}"
                alt="User profile picture"
                *ngIf="data.userData.image"
              />
            </div>
            <div class="text-center" *ngIf="!check">
              <img
                class="profile-user-img img-fluid img-circle"
                [src]="imageFile"
                alt="User profile picture"
                *ngIf="!data.userData.image"
              />
            </div><br/>
            <div class="input-group">
              <div class="custom-file">
                <input type="file" (change)="setFileName(fileInput)" #fileInput class="custom-file-input" id="file" name="file" accept="image/x-png,image/gif,image/jpeg,image/png">
                <label class="custom-file-label" for="file" id="img-label">Зураг оруулах</label>
              </div>
            </div>
            <br/>
            <a class="btn btn-primary" (click)="uploadImage(fileInput)"><b>Хуулах</b></a>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </div>
      <!-- /.col -->
      <div class="col-md-6">
        <div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title">Үндсэн мэдээлэл</h3>
          </div>          
          <form>
            <div class="card-body">
              <div class="form-group">
                <label for="lastname">Lastname</label>
                <input type="text" class="form-control" name="lastname" placeholder="Овог" [(ngModel)]="lastname" disabled>
              </div>
              <div class="form-group">
                <label for="firstname">Firstname</label>
                <input type="text" class="form-control" name="firstname" placeholder="Нэр" [(ngModel)]="firstname" disabled>
              </div>
              <div class="form-group">
                <label for="phone">Phone</label>
                <input type="tel" class="form-control" name="phone" placeholder="Утасны дугаар" [(ngModel)]="phone">
              </div>
              <div class="form-group">
                <label for="email">Email address</label>
                <input type="email" class="form-control" name="email" placeholder="Имэйл хаяг" [(ngModel)]="email" disabled>
              </div>
              <div class="form-group">
                <label for="password">Old Password</label>
                <input type="password" class="form-control" name="password2" placeholder="Хуучин нууц үг" [(ngModel)]="oldpass" disabled>
              </div>
              <div class="form-group">
                <label for="password">New Password</label>
                <input type="password" class="form-control" name="password" placeholder="Нууц үг" [(ngModel)]="password">
              </div>
              <!-- <div class="form-group">
                <label for="exampleInputFile">Signature</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="exampleInputFile">
                    <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                  </div>
                  <div class="input-group-append">
                    <span class="input-group-text">Upload</span>
                  </div>
                </div>
              </div> -->
            </div>          
            <div class="card-footer">
              <button type="button" class="btn btn-primary" (click)="changePass()">Шинэчлэх</button>
            </div>
          </form>
          </div>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>