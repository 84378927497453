import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
})

export class AgentsComponent implements OnInit {
  toastr: any;
  filteredOptions: Observable<string[]>;
  nameFormControl = new FormControl();
  agents = [];
  agents_old = [];
  loading = false;
  searchTerm = '';
  consignee: any;
  page = 1;
  user_id;
  filteredItems = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  modalService: any;
  closeResult: string;
  public startDate;
  public endDate;
  constructor(public appService: AppService, public data: DataProvider, public router: Router, public rest: CallapiService,
    private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'settings-agents';
  }
  
  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    this.spinner.show();
    this.rest.agentsList().subscribe((res) =>{
      this.spinner.hide();
      this.agents = res;
      this.filteredItems = this.agents;
      this.agents_old = this.agents;
      this.currentIndex = 1;
      this.pageStart = 1;
      this.pages = 6;
      this.pageNumber = parseInt(
        '' + this.filteredItems.length / this.pageSize
      );
      if (this.filteredItems.length % this.pageSize != 0) {
        this.pageNumber++;
      }
      if (this.pageNumber < this.pages) {
        this.pages = this.pageNumber;
      }
      this.refreshItems();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.agents = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  clear() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.searchTerm = '';
    this.filteredItems = this.agents_old;
    this.refreshItems();
  }

  search() {
    let ch = 0;
    if(this.searchTerm) {
      this.filteredItems = this.filteredItems.filter(option => option.name.toLowerCase().includes(this.searchTerm));
    } else {
      ch++;
    }
    if(ch == 1) {
      this.filteredItems = this.agents_old;
    }
    this.refreshItems();
  }
  
  showAgent(bool, id: any, name: any, credit: any) {
    if(bool) {
      this.router.navigate(['/agent/add']);
    } else {
      this.router.navigate(['/agent/edit', {id: id, name: name,credit: credit}]);
    }
  }
}
