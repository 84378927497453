import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-notifications-dropdown-menu',
  templateUrl: './notifications-dropdown-menu.component.html',
  styleUrls: ['./notifications-dropdown-menu.component.scss'],
})
export class NotificationsDropdownMenuComponent implements OnInit {
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2, public data: DataProvider,
    private router: Router) {}

  ngOnInit() {}

  toggleDropdownMenu() {
    if (this.dropdownMenu.nativeElement.classList.contains('show')) {
      this.hideDropdownMenu();
    } else {
      this.showDropdownMenu();
    }
  }

  showDropdownMenu() {
    this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
    this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
  }

  load(type: any) {
    this.hideDropdownMenu();
    if(type == 'inquiry') {
      this.router.navigate(['/inquiry']);
    } else if(type == 'order') {
      this.router.navigate(['/shipping-order']);
    } else if(type == 'operation') {
      this.router.navigate(['/operation']);
    } else if(type == 'custom') {
      this.router.navigate(['/custom']);
    } else if(type == 'site') {
      this.router.navigate(['/side-manager']);
    } else if(type == 'invoice') {
      this.router.navigate(['/invoice']);
    }
  }
}
