<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6 col-xs-6 col-6">
          <h1>Receivable</h1>
        </div>
        <div class="col-sm-6 col-xs-6 col-6">
          <ol class="breadcrumb float-right" >
            <button
              type="button"
              class="btn btn-tool"
              data-widget="collapse"
              data-toggle="tooltip"
              title="Excel Export"
              (click)="exportExcel()"
            >
              <i class="fas fa-file-excel"></i>
            </button>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
            <div class="mb-3 row">
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">SM</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="nameFormControl"
                    [(ngModel)]="searchTerm"
                    name="searchName"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">Payer</mat-label>
                  <input type="text"
                        matInput
                        class="cons-input"
                        [formControl]="Consignee"
                        [matAutocomplete]="auto1"
                        (ngModelChange)="_filterCons()"
                        id="consignee"
                        >
                        
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="onConsigneeSelected($event.option)" >
                    <mat-option *ngFor="let option of consignees" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Start</mat-label>
                  <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="startDate" >
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
    
              <span class="col col-form-label" *ngIf="loading">Loading...</span>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Шинэчлэх"
                (click)="clear()"
              >
                <i class="fa fa-times"></i>
              </button>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Хайх"
                (click)="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          <div class="table_wrapper">
            <table class="table table-bordered table-hovered custom-table">
              <thead>
                <tr>
                  <th scope="col">Actions</th>
                  <!-- <th scope="col">Төлөв</th> -->
                  <th scope="col">SO/No</th>
                  <th scope="col">Payer</th>
                  <th scope="col">Total</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Rate</th>
                  <th scope="col">Total(MNT)</th>
                  <th scope="col">Income Amount(MNT)</th>
                  <th scope="col">Rest Amount(MNT)</th>
                  <th scope="col">Confirmed At</th>
                </tr>
              </thead>
              <tbody *ngFor="let finance of receivables">
                <tr *ngIf="finance.tr_amount != null">
                  <th scope="row">
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Төлөгдсөнөөр тооцох"
                      (click)="download(finance.tr_id, finance.tr_amount)"                      
                    >
                    <i class="fas fa-check-circle"></i>
                    </button>
                    <!-- <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title=""
                      (click)="print(finance.id, finance.pre_payment)"
                    >
                    <i class="fas fa-plus-circle"></i>
                    </button> -->
                  </th>
                  <th scope="row"
                  title="Shipping order харах"
                  (click)="shippingOrderBack(finance.id)"
                  class="edit">
                  #{{ finance.shipping_order_name }}</th>
                  <td style="color: green;font-weight: bold;" title="Симатай авсан контейнер">Simatai Container</td>
                  <td>{{finance.tr_price*(-1) | currency : ' '}}</td>
                  <td>{{finance.tr_currency_name}}</td>
                  <td>{{finance.tr_rate | currency : ' '}}</td>
                  <td>{{finance.tr_amount*(-1) | currency : ' '}}</td>
                  <td>0.00</td>
                  <td>{{finance.tr_amount*(-1) | currency : ' '}}</td>
                  <td>{{finance.confirmed}}</td>                 
                </tr>
                <tr *ngIf="finance.tr_amount != null">
                  <th scope="row">
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Төлөгдсөнөөр тооцох"
                      (click)="download(finance.tr_id, finance.tr_amount)"                      
                    >
                    <i class="fas fa-check-circle"></i>
                    </button>
                  </th>
                  <th scope="row"
                  title="Shipping order харах"
                  (click)="shippingOrderBack(finance.id)"
                  class="edit">
                  #{{ finance.shipping_order_name }}</th>
                  <td>{{finance.payer_name}}</td>
                  <td>{{finance.price | currency : ' '}}</td>
                  <td>{{finance.currency_name}}</td>
                  <td>{{finance.price_rate | currency : ' '}}</td>
                  <td>{{finance.price*finance.price_rate | currency : ' '}}</td>
                  <td>0.00</td>
                  <td>{{finance.price*finance.price_rate | currency : ' '}}</td>
                  <td>{{finance.confirmed}}</td>                 
                </tr>
                <tr *ngIf="finance.tr_amount == null">
                  <th scope="row">
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Төлөгдсөнөөр тооцох"
                      (click)="download(finance.id, finance.tr_amount)"                      
                    >
                    <i class="fas fa-check-circle"></i>
                    </button>
                  </th>
                  <th scope="row"
                  title="Shipping order харах"
                  (click)="shippingOrderBack(finance.id)"
                  class="edit">
                  #{{ finance.shipping_order_name }}</th>
                  <td>{{finance.payer_name}}</td>
                  <td>{{finance.price | currency : ' '}}</td>
                  <td>{{finance.currency_name}}</td>
                  <td>{{finance.price_rate | currency : ' '}}</td>
                  <td>{{finance.price*finance.price_rate | currency : ' '}}</td>
                  <td>0.00</td>
                  <td>{{finance.price*finance.price_rate | currency : ' '}}</td>
                  <td>{{finance.confirmed}}</td>                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
            <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
          </div>
          <div *ngIf="filteredItems.length >= 1" style="display: inline-block;float: right;">
            <label>НИЙТ ДҮН: {{allTotal | currency : ' '}}₮</label>
          </div>
          <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
            <label class="pages">No Receivable List</label>
          </div>
          <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
            <span
              [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
              (click)="prevPage()"
              class="prev"
              >&laquo;
            </span>
            <span
              *ngFor="let page of pagesIndex"
              [ngClass]="{ tod: currentIndex == page }"
              class="dot"
              (click)="setPage(page)"
            >{{page}}</span>
            <span
              [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
              (click)="nextPage()"
              class="next"
              >&raquo;
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
    <p style="color: #bf2429 " > Уншиж байна... </p>
  </ngx-spinner>
  