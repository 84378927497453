import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit {
  public isNew = true;
  id;
  year;
  month;
  name;
  rate;
  vis;

  constructor(private data: DataProvider, private appService: AppService,
    private route: ActivatedRoute,private rest: CallapiService,private toastr: ToastrService,
    private router: Router,private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'currency';
  }

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id != null) {
      this.isNew = false;
    }
    if(!this.isNew) {
      this.spinner.show();
      this.rest.currencyDetail(this.id).subscribe((res) => {
        this.year = res.detail.year;
        this.month = res.detail.month;
        this.name = res.detail.name;
        this.rate = res.detail.rate;
        if(res.detail.vis == 'y') {
          this.vis = 1;
        } else {
          this.vis = 0;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
    }
  }

  edit() {
    if(this.year == null || this.year == '') {
      this.toastr.error('Алдаа!', 'Та оноо оруулна уу!');
      return;
    }
    if(this.month == null || this.month == '') {
      this.toastr.error('Алдаа!', 'Та сараа оруулна уу!');
      return;
    }
    if(this.name == null || this.name == '') {
      this.toastr.error('Алдаа!', 'Та нэрээ оруулна уу!');
      return;
    }
    if(this.rate == null || this.rate == '') {
      this.toastr.error('Алдаа!', 'Та Ханшаа оруулна уу!');
      return;
    }
    let data = null;
    if(this.isNew) {
      data = {
        id: this.id,
        year: this.year,
        month: this.month,
        name: this.name,
        rate: this.rate,
        type: 'add'
      }
    } else {
      data = {
        id: this.id,
        year: this.year,
        month: this.month,
        name: this.name,
        rate: this.rate,
        type: 'edit',
        vis: this.vis == 1 ? 'y' : 'n'
      }
    }
    
    this.spinner.show();
    this.rest.editCurrency(data).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай засварлалаа.');
        this.router.navigate(['/currency-list']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  changeVis() {
    
  }

  backList() {
    this.router.navigate(['/currency-list']);
  }
}
