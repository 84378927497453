import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { DataProvider } from 'src/providers/data/data';
import { ENCRYPT_SECRET_KEY } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public user = {
    firstName: 'Alexander',
    lastName: 'Pierce',
    image: 'assets/img/user2-160x160.jpg',
  };

  constructor(private router: Router, public data: DataProvider) {}

  login(userdata) {    
    const encrypt = CryptoJS.AES.encrypt(
      JSON.stringify(userdata),
      ENCRYPT_SECRET_KEY
    ).toString();
    localStorage.setItem('token', encrypt);
    this.router.navigate(['/']);
  }

  checkAuth() {
    try {
      const dec = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem('token'),
          ENCRYPT_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
      );
      this.data.userData = dec;
      return true;
    } catch (e) {
      clearInterval(this.data.interval);
      return false;
    }
  }

  register() {
    this.router.navigate(['/']);
  }

  logout() {
    localStorage.removeItem('token');
    clearInterval(this.data.interval);
    this.router.navigate(['/login']);
  }
}
