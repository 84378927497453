<div class="login-box">

  <!-- /.login-logo -->
  <div class="card">
    <div class="card-header login-logo">      
        <a [routerLink]="['/']">
          <img
            src="assets/img/logo.png"
            alt="Logo"
            class="brand-image img-circle elevation-3"
            style="opacity: 1;background-color: bisque;
            width: 45px;"
          />
          SIMATAI MONGOLIA
        </a>
    </div>

      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="card-body login-card-body">
          <div class="input-group mb-3">
            <input
              formControlName="email"
              type="email"
              class="form-control"
              placeholder="Имэйл"
              [(ngModel)]="email"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              formControlName="password"
              type="password"
              class="form-control"
              placeholder="Нууц үг"
              [(ngModel)]="password"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
       </div>
       <div class="card-footer white">
        <div class="row">
          <div class="col-12">

            <button mat-flat-button color="accent" type="submit"   class="loginbutton">Нэвтрэх</button>

          </div>
          <!-- /.col -->
        </div>
       </div>
      </form>
    <!-- /.login-card-body -->
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>
