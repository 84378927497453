import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppService } from '../../utils/services/app.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public isAuthLoading = false;
  public email;
  public password;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.loginForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.spinner.show();
      this.rest.login(this.email, this.password).subscribe(
        (res) => {
          this.spinner.hide();
          if(res == "NG") {
            this.toastr.error('Алдаа!', 'Имэйл болон нууц үгээ зөв оруулна уу!');
          } else {
            const userdata = {
              id: res.user.id,
              lname: res.user.lastname,
              fname: res.user.firstname,
              permission: res.user.permission,
              email: res.user.email,
              phone: res.user.phone_number,
              job_date: res.user.job_date,
              image: res.user.image,
              name: res.user.name,
              password: res.user.password,
              position_type:res.user.position_type,
              create_date:res.user.create_date,
              notifications: {
                inquiry: res.inquiry,
                shipping_order: res.shipping_order,
                operation: res.operation,
                custom: res.custom,
                site: res.site,
                invoices: res.invoices
              },
            };
            this.appService.login(userdata);
          }          
        }, (err) => {
          console.log(err);
          this.spinner.hide();
        }
      );
    } else {
      this.toastr.error('Алдаа!', 'Имэйл болон нууц үгээ оруулна уу!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
