<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2" >
      <div class="col-sm-6 col-xs-6 col-6">
        <h1>Inquiry</h1>
      </div>
      <div class="col-sm-6 col-xs-6 col-6" *ngIf="data.userData.permission == 4" >
        <ol class="breadcrumb float-right" >
          <button
            type="button"
            class="btn btn-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Add inquiry"
            (click)="showInquiry(true, 0)"
          >
            <i class="fa fa-plus-circle"></i>
          </button>
          <button
              type="button"
              class="btn btn-tool"
              data-widget="collapse"
              data-toggle="tooltip"
              title="Excel Export"
              (click)="exportExcel()"
            >
              <i class="fas fa-file-excel"></i>
            </button>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      
      <div class="card-body">
        <!-- <form name="form"> -->
          <div class="mb-3 row">
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="text">Name</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="nameFormControl"
                  [(ngModel)]="searchTerm"
                  (ngModelChange)="search()"
                  name="searchName"
                />
              </mat-form-field>
              <!-- <input
                id="table-complete-search"
                type="text"
                class="form-control"
                placeholder="Name"
                name="searchTerm"
                [(ngModel)]="searchTerm"
              /> -->
            </div>
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="text">Consignee</mat-label>
                <input type="text"
                      matInput
                      class="cons-input"
                      [formControl]="Consignee"
                      [matAutocomplete]="auto1"
                      (ngModelChange)="_filterCons()"
                      id="consignee"
                      >
                      
                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="onConsigneeSelected($event.option)" >
                  <mat-option value=""></mat-option>
                  <mat-option *ngFor="let option of consignees" [value]="option.name">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>POL mode</mat-label>
                <mat-select  [(ngModel)]="trans_mode_id" (selectionChange) = "search()">
                  <mat-option value=""></mat-option>
                  <mat-option
                    *ngFor="let modes of Transportation_mode"
                    [value]="modes.id"
                  >
                    {{ modes.name }}
                  </mat-option>
                </mat-select>  
              </mat-form-field>
            </div>
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Start</mat-label>
                <input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="startDate" (dateInput)="search()" >
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>End</mat-label>
                <input matInput [matDatepicker]="picker1"  (click)="picker1.open()"  [(ngModel)]="endDate" (dateInput)="search()">
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>

            <span class="col col-form-label" *ngIf="loading">Loading...</span>
            <button
              type="button"
              class="btn btn-search-tool"
              data-widget="collapse"
              data-toggle="tooltip"
              title="Шинэчлэх"
              (click)="clear()"
            >
              <i class="fa fa-times"></i>
            </button>
            <button
              type="button"
              class="btn btn-search-tool"
              data-widget="collapse"
              data-toggle="tooltip"
              title="Хайх"
              (click)="search()"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        
          <div class="table_wrapper">
            <table class="table table-striped table-hovered">
              <thead>
                <tr>
                  <th scope="col">Actions</th>
                  <th scope="col">Төлөв</th>
                  <th scope="col">Name</th>
                  <th scope="col">Consignee</th>
                  <th scope="col">POL Country</th>
                  <th scope="col">POD Destination</th>
                  <th scope="col">MODE</th>
                  <th scope="col">CBM</th>
                  <th scope="col">Quotation Date</th>
                  <th scope="col">Sales</th>
                  <th scope="col">Tariff</th>                  
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let inquiry of inquirys;let i = index">                
                  <th scope="row">
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Засах"
                      (click)="showInquiry(false, inquiry.id)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Устгах"
                      (click)="deleteInquiry(inquiry.id)"
                      *ngIf="data.userData.permission == 4"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Duplicate хийх"
                      (click)="dupInquiry(inquiry.id)"
                      *ngIf="data.userData.permission == 4"
                    >
                      <i class="fas fa-copy"></i>
                    </button>
                  </th>
                  <th scope="row" *ngIf="data.userData.permission == 4 && 
                  inquiry.tariff_type == '' && inquiry.update_user != null && inquiry.update_user != data.userData.id" style="color: red;">Tariff-с ирсэн</th>
                  <th scope="row" *ngIf="data.userData.permission == 4 && 
                  inquiry.update_user == null" style="color: green;">Tariff-руу илгээсэн</th>
                  <th scope="row" *ngIf="data.userData.permission == 4 && 
                  inquiry.update_user == data.userData.id && inquiry.to_tariff == 0 && inquiry.price != null" style="color: green;">SO болгоход бэлэн</th>
                  <th scope="row" *ngIf="data.userData.permission == 4 && 
                  inquiry.update_user == data.userData.id && inquiry.to_tariff == 0 && inquiry.price == null" style="color: green;">Tariff-руу илгээсэн</th>
                  <th scope="row" *ngIf="data.userData.permission == 4 && 
                  inquiry.update_user == data.userData.id && inquiry.to_tariff == 1" style="color: green;">Tariff-руу үнэ буцаасан</th>

                  <th scope="row" *ngIf="data.userData.permission == 4 && 
                  inquiry.tariff_type != '' && inquiry.update_user != null && inquiry.update_user != data.userData.id" style="color: red;">Тээврийн үнэ өөрчлөгдсөн байна<br/>Tariff-с ирсэн</th>                  
                  
                  <th scope="row" *ngIf="data.userData.permission == 5 && 
                  inquiry.update_user != null && inquiry.update_user != data.userData.id && inquiry.to_tariff == 1" style="color: red;">Sales-с үнэ буцаасан</th>
                  <th scope="row" *ngIf="data.userData.permission == 5 && 
                  inquiry.update_user == null" style="color: red;">Шинэ Inquiry</th>
                  <th scope="row" *ngIf="data.userData.permission == 5 && 
                  inquiry.update_user == data.userData.id && inquiry.to_tariff == 0" style="color: green;">Sales-руу илгээсэн</th>
                  <th scope="row" *ngIf="data.userData.permission == 5 && inquiry.update_user != null &&
                  inquiry.update_user != data.userData.id && inquiry.to_tariff == 0 && inquiry.price != null" style="color: green;">Sales-дээр байгаа</th>
                  <th scope="row" *ngIf="data.userData.permission == 5 && inquiry.update_user != null &&
                  inquiry.update_user != data.userData.id && inquiry.to_tariff == 0 && inquiry.price == null" style="color: red;">Шинэ Inquiry</th>

                  <th scope="row" *ngIf="(data.userData.permission == 1 || data.userData.permission == 2 || data.userData.permission == 6) && 
                  inquiry.tariff_type == '' && inquiry.update_user != null && inquiry.update_user != data.userData.id && inquiry.to_tariff == 1" style="color: red;">Sales-с үнэ буцаасан</th>
                  <th scope="row" *ngIf="(data.userData.permission == 1 || data.userData.permission == 2 || data.userData.permission == 6) && 
                  inquiry.tariff_type != '' && inquiry.update_user != null && inquiry.update_user != data.userData.id && inquiry.to_tariff == 0" style="color: red;">Тээврийн үнэ өөрчлөгдсөн байна<br/>Tariff-с ирсэн</th>
                  <th scope="row" *ngIf="(data.userData.permission == 1 || data.userData.permission == 2 || data.userData.permission == 6) && 
                  inquiry.update_user == null" style="color: red;">Шинэ Inquiry</th>
                  <th scope="row" *ngIf="(data.userData.permission == 1 || data.userData.permission == 2 || data.userData.permission == 6) && 
                  inquiry.tariff_type == '' && inquiry.update_user != null && inquiry.to_tariff == 0 && inquiry.price != null" style="color: red;">SO болгохыг хүлээж байгаа</th>
                  <th scope="row" *ngIf="(data.userData.permission == 1 || data.userData.permission == 2 || data.userData.permission == 6) && 
                  inquiry.tariff_type == '' && inquiry.update_user != null && inquiry.to_tariff == 0 && inquiry.price == null" style="color: red;">Шинэ Inquiry</th>

                  <th scope="row" (click)="open(inquiryModal, i)" style="cursor: pointer;">{{ inquiry.name }}</th>
                  <td>
                    {{inquiry.consignee_name}}
                  </td>
                  <td>{{inquiry.pol_country_name}}<br/><small style="color: #f0b622 !important;">{{inquiry.pol_city_name}}</small></td>
                  <td>{{inquiry.final_country_name}}<br/><small style="color: #f0b622 !important;">{{inquiry.final_city_name}}</small></td>
                  <td>{{inquiry.trans_mode_name}}</td>
                  <td>{{inquiry.cbm}}</td>
                  <td>{{inquiry.quotation_date}}</td>
                  <td>{{inquiry.sales_name}}</td>
                  <td>{{inquiry.tariff_name}}</td>                  
                </tr>
              </tbody>
            </table>
          </div>
        <!-- </form> -->
      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
          <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
        </div>
        <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
          <label class="pages">No Inquiry</label>
        </div>
        <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;
          </span>
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          >{{page}}</span>
          <span
            [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
            (click)="nextPage()"
            class="next"
            >&raquo;
          </span>
        </div>
      </div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
  <ng-template #inquiryModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Тухайн асуулгын дэлгэрэнгүй мэдээлэл</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="inquiry_desc">Name:<span style="float: right;">{{inquirys[inquiry_id].name}}</span></p>
      <p class="inquiry_desc">Consignee:<span style="float: right;">{{inquirys[inquiry_id].consignee_name}}</span></p>
      <p class="inquiry_desc">Phone:<span style="float: right;">{{inquirys[inquiry_id].consignee_phone}}</span></p>
      <p class="inquiry_desc">Email:<span style="float: right;">{{inquirys[inquiry_id].consginee_email}}</span></p>
      <p class="inquiry_desc">Validation Date:<span style="float: right;">{{inquirys[inquiry_id].validaty_date}}</span></p>
      <p class="inquiry_desc">Quotation Date:<span style="float: right;">{{inquirys[inquiry_id].quotation_date}}</span></p>
      <p class="inquiry_desc">Sales:<span style="float: right;">{{inquirys[inquiry_id].sales_name}}</span></p>
      <p class="inquiry_desc">Tariff:<span style="float: right;">{{inquirys[inquiry_id].tariff_name}}</span></p>
      <p class="inquiry_desc">CBM:<span style="float: right;">{{inquirys[inquiry_id].cbm}}</span></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Ok</button>
    </div>
  </ng-template>
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>