<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6 col-xs-6 col-6">
        <h1>Operation</h1>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
          <div class="mb-3 row">
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="text">SM</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="nameFormControl"
                  [(ngModel)]="searchTerm"
                  (ngModelChange)="search()"
                  name="searchName"
                />
              </mat-form-field>
            </div>
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="text">Consignee</mat-label>
                <input type="text"
                      matInput
                      class="cons-input"
                      [formControl]="Consignee"
                      [matAutocomplete]="auto1"
                      (ngModelChange)="_filterCons()"
                      id="consignee"
                      >
                      
                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="onConsigneeSelected($event.option)" >
                  <mat-option value=""></mat-option>
                  <mat-option *ngFor="let option of consignees" [value]="option.name">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <!-- <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Start</mat-label>
                <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="startDate" >
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div> -->
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="text">Sales</mat-label>
                <input
                  type="text"
                  matInput
                  [(ngModel)]="sales_id"
                  (ngModelChange)="search()"
                  name="sales"
                />
              </mat-form-field>
            </div>
            <div class="col-xs-3 col-sm-auto fsize">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="text">POL</mat-label>
                <input
                  type="text"
                  matInput
                  [(ngModel)]="polname"
                  (ngModelChange)="search()"
                  name="pol"
                />
              </mat-form-field>
            </div>
  
            <span class="col col-form-label" *ngIf="loading">Loading...</span>
            <button
              type="button"
              class="btn btn-search-tool"
              data-widget="collapse"
              data-toggle="tooltip"
              title="Шинэчлэх"
              (click)="clear()"
            >
              <i class="fa fa-times"></i>
            </button>
            <button
              type="button"
              class="btn btn-search-tool"
              data-widget="collapse"
              data-toggle="tooltip"
              title="Хайх"
              (click)="search()"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        <div class="table_wrapper">
          <table class="table table-striped table-hovered custom-table">
            <thead>
              <tr>
                <th scope="col">Actions</th>
                <th scope="col">Төлөв</th>
                <th scope="col">SO/No</th>
                <th scope="col">Cntr/AWB/Truck/Wagon</th>
                <th scope="col">Consignee</th>
                <th scope="col">Commodity</th>
                <th scope="col">Incoterm</th>
                <th scope="col">Operation manager</th>
                <th scope="col">Origin</th>
                <th scope="col">Cargo weight</th>
                <th scope="col">Terminal</th>
                <th scope="col">TMode</th>
                <th scope="col">Consignee phone</th>
                <th scope="col">Consignee email</th>
                <th scope="col">Comment</th>
                <th scope="col">Released</th>
                <th scope="col">Shipping order at</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let operation of operations">                
                <th scope="row">
                  <button
                    type="button"
                    class="btn btn-tool table-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Shipping order Print"
                    (click)="download(operation.id)"
                  >
                  <i class="fas fa-print"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-tool table-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Тээврийн мэдээ оруулах"
                    (click)="operationEdit(operation.shipping_order_info_id)"
                    *ngIf="data.userData.permission == 5"
                  >
                  <i class="fas fa-plus-circle"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-tool table-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Inquiry харах"
                    (click)="inquiryBack(operation.inquiry_id)"
                  >
                  <i class="nav-icon fas fa-notes-medical"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-tool table-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Тээврийн мэдээ"
                    (click)="report(operation.shipping_order_info_id)"
                  >
                    <i class="fas fa-file"></i>
                  </button>
                </th>
                <th scope="row" *ngIf="operation.tariff_type == '' && operation.cntr_name == ''" style="color: red;">Тээврийн мэдээ дутуу байна</th>
                <th scope="row" *ngIf="operation.tariff_type == '' && operation.cntr_name != '' && operation.files.length == 0" style="color: red;">Тээврийн файл ороогүй байна</th>
                <th scope="row" *ngIf="operation.tariff_type == '' && operation.cntr_name != '' && operation.files.length > 0 && operation.eta_border == null" style="color: red;">
                  Хилийн мэдээ ороогүй байна</th>
                <th scope="row" *ngIf="operation.tariff_type != '' && operation.cntr_name == ''" style="color: red;">Тээврийн үнэ өөрчлөгдсөн байна<br/> Тээврийн мэдээ дутуу байна</th>
                <th scope="row" *ngIf="operation.tariff_type != '' && operation.cntr_name != '' && operation.files.length == 0" style="color: red;">Тээврийн үнэ өөрчлөгдсөн байна<br/> Тээврийн файл ороогүй байна</th>
                <th scope="row" *ngIf="operation.tariff_type != '' && operation.cntr_name != '' && operation.files.length > 0 && operation.eta_border == null" style="color: red;">
                  Тээврийн үнэ өөрчлөгдсөн байна<br/> Хилийн мэдээ ороогүй байна</th>
                <th scope="row"
                title="Edit shipping order"
                (click)="shippingOrderBack(operation.id)"
                class="edit">
                #{{ operation.shipping_order_name }}</th>
                <td>{{operation.cntr_name}}</td>
                <td>{{operation.consignee_name}}</td>
                <td>{{operation.commodity}}</td>
                <td>{{operation.incoterm_name}}</td>
                <td>{{operation.tariff_name}}</td>
                <td>{{operation.pol_country_name}}</td>
                <td>{{operation.box_weight}}</td>
                <td>{{operation.terminal_name}}</td>
                <td>{{operation.trans_mode_name}}</td>
                <td>{{operation.consignee_phone}}</td>
                <td>{{operation.consignee_email}}</td>
                <td>{{operation.comments}}</td>
                <td>{{operation.released_at}}</td>
                <td>{{operation.so_start_date}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
          <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
        </div>
        <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
          <label class="pages">No Operations</label>
        </div>
        <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;
          </span>
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          >{{page}}</span>
          <span
            [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
            (click)="nextPage()"
            class="next"
            >&raquo;
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>