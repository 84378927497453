import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { InquiryListComponent } from './views/inquiry-list/inquiry-list.component';
import { InquiryComponent } from './views/inquiry/inquiry.component';
import { InquiryDownloadComponent } from './views/inquiry-download/inquiry-download.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './views/profile/profile.component';
// import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { ShippingOrderComponent } from './views/shipping-order/shipping-order.component';
import { ShippingOrderEditComponent } from './views/shipping-order-edit/shipping-order-edit.component';
import { OperationComponent } from './views/operation/operation.component';
import { OperationEditComponent } from './views/operation-edit/operation-edit.component';
import { ClosedInquiryComponent } from './views/closed-inquiry/closed-inquiry.component';
import { SOCSimataiComponent } from './views/soc/soc.component';
import { CustomComponent } from './views/custom/custom.component';
import { FinishedShipmentsComponent } from './views/finished-shipments/finished-shipments.component';
import { SideManagerComponent } from './views/side-manager/side-manager.component';
import { FinanceComponent } from './views/finance/finance.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { OperationReportComponent } from './views/operation-report/operation-report.component';
import { ShippingOrderPrintComponent } from './views/shipping-order-print/shipping-order-print.component';
import { UsersComponent } from './views/users/users.component';
import { UserComponent } from './views/user/user.component';
import { InvoicePrintComponent } from './views/invoice-print/invoice-print.component';
import { CurrencyListComponent } from './views/currency-list/currency-list.component';
import { CurrencyComponent } from './views/currency/currency.component';
import { ReceivableComponent } from './views/receivable/receivable.component';
import { PayableComponent } from './views/payable/payable.component';
import { ReportsComponent } from './views/reports/reports.component';
import { AgentsComponent } from './views/agents/agents.component';
import { AgentComponent } from './views/agent/agent.component';
import { ConsigneesComponent } from './views/consignees/consignees.component';
import { ConsigneeComponent } from './views/consignee/consignee.component';
import { SuppliersComponent } from './views/suppliers/suppliers.component';
import { SupplierComponent } from './views/supplier/supplier.component';
import { ReportUserComponent } from './views/report-user/report-user.component';
import { GaaliActComponent } from './views/gaali-act/gaali-act.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'inquiry',
        component: InquiryListComponent,
      },
      {
        path: 'inquiry/add',
        component: InquiryComponent,
      },
      {
        path: 'inquiry/edit',
        component: InquiryComponent,
      },
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'shipping-order',
        component: ShippingOrderComponent,
      },
      {
        path: 'shipping-order/edit',
        component: ShippingOrderEditComponent,
      },
      {
        path: 'operation',
        component: OperationComponent,
      },
      {
        path: 'operation/edit',
        component: OperationEditComponent,
      },
      {
        path: 'closed-inquiry',
        component: ClosedInquiryComponent,
      },
      {
        path: 'soc',
        component: SOCSimataiComponent,
      },
      {
        path: 'custom',
        component: CustomComponent,
      },
      {
        path: 'side-manager',
        component: SideManagerComponent,
      },
      {
        path: 'finished',
        component: FinishedShipmentsComponent,
      },
      {
        path: 'finance',
        component: FinanceComponent,
      },
      {
        path: 'invoice',
        component: InvoiceComponent,
      },
      {
        path: 'payable',
        component: PayableComponent,
      },
      {
        path: 'receivable',
        component: ReceivableComponent,
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'report/user',
        component: ReportUserComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'user/add',
        component: UserComponent,
      },
      {
        path: 'user/edit',
        component: UserComponent,
      },
      {
        path: 'currency-list',
        component: CurrencyListComponent,
      },
      {
        path: 'currency/add',
        component: CurrencyComponent,
      },
      {
        path: 'currency/edit',
        component: CurrencyComponent,
      },
      {
        path: 'settings-agents',
        component: AgentsComponent,
      },
      {
        path: 'agent/add',
        component: AgentComponent,
      },
      {
        path: 'agent/edit',
        component: AgentComponent,
      },
      {
        path: 'settings-consignees',
        component: ConsigneesComponent,
      },
      {
        path: 'consignee/add',
        component: ConsigneeComponent,
      },
      {
        path: 'consignee/edit',
        component: ConsigneeComponent,
      },
      {
        path: 'settings-suppliers',
        component: SuppliersComponent,
      },
      {
        path: 'supplier/add',
        component: SupplierComponent,
      },
      {
        path: 'supplier/edit',
        component: SupplierComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'inquiry/download',
    component: InquiryDownloadComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'operation/report',
    component: OperationReportComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'shipping-order/print',
    component: ShippingOrderPrintComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'invoice/print',
    component: InvoicePrintComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'custom/act',
    component: GaaliActComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'uploads/files/*',
    redirectTo: 'uploads/files/*'
  },
  {
    path: 'uploads/images/*',
    redirectTo: 'uploads/images/*'
  },
  {
    path: 'assets/files/*',
    redirectTo: 'assets/files/*'
  },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  //   canActivate: [NonAuthGuard],
  // },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
