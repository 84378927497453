<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6 col-xs-6 col-6">
        <h1>Shipping Order</h1>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-3 row">
          <div class="col-xs-3 col-sm-auto fsize">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label class="text">SM</mat-label>
              <input
                type="text"
                matInput
                [formControl]="nameFormControl"
                [(ngModel)]="searchTerm"
                name="searchName"
              />
            </mat-form-field>
            <!-- <input
              id="table-complete-search"
              type="text"
              class="form-control"
              placeholder="Name"
              name="searchTerm"
              [(ngModel)]="searchTerm"
            /> -->
          </div>
          <div class="col-xs-3 col-sm-auto fsize">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label class="text">Consignee</mat-label>
              <input type="text"
                    matInput
                    class="cons-input"
                    [formControl]="Consignee"
                    [matAutocomplete]="auto1"
                    (ngModelChange)="_filterCons()"
                    id="consignee"
                    >
                    
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="onConsigneeSelected($event.option)" >
                <mat-option *ngFor="let option of consignees" [value]="option.name">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-xs-3 col-sm-auto fsize">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Start</mat-label>
              <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="startDate" >
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <span class="col col-form-label" *ngIf="loading">Loading...</span>
          <button
            type="button"
            class="btn btn-search-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Шинэчлэх"
            (click)="clear()"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            type="button"
            class="btn btn-search-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Хайх"
            (click)="search()"
          >
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="table_wrapper">
          <table class="table table-striped table-hovered custom-table">
            <thead>
              <tr>
                <th scope="col">Actions</th>
                <th scope="col">Төлөв</th>
                <th scope="col">SO/No</th>
                <th scope="col">Consignee</th>
                <th scope="col">Shipper</th>
                <th scope="col">Payer</th>
                <th scope="col">CNTR/WGN number</th>
                <th scope="col">Trasnport type</th>
                <th scope="col">Commodity</th>
                <th scope="col">Payment type</th>
                <!-- <th scope="col">Currency</th>
                <th scope="col">Rate</th> -->
                <!-- <th scope="col">Origin</th>
                <th scope="col">Destination</th> -->
                <th scope="col">Contact</th>
                <th scope="col">Email</th>
                <!-- <th scope="col">Agents</th> -->
                <th scope="col">Comment</th>
                <th scope="col">Released</th>
                <th scope="col">S.Order At</th>
                <th scope="col">Sales</th>
                <th scope="col">Tariff</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let so of shippingOrders">                
                <th scope="row">
                  <button
                    type="button"
                    class="btn btn-tool table-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    (click)="print(so.id)"
                    title="Shipping order хэвлэх"
                  >
                  <i class="fas fa-print"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-tool table-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Inquiry харах"
                    (click)="inquiryBack(so.inquiry_id)"
                  >
                  <i class="nav-icon fas fa-notes-medical"></i>
                  </button>
                </th>
                <th scope="row" *ngIf="so.tariff_type == '' && so.contract_id != '' && so.type == 'preoperation'" style="color: red;">
                  Гэрээ байгуулагдсан урьдчилгаа хүлээгдэж байгаа
                </th>
                <th scope="row" *ngIf="so.tariff_type == '' && so.contract_id == null && so.payment_type_id != 0" style="color: red;">
                  Гэрээ оруулаагүй байна
                </th>
                <th scope="row" *ngIf="so.tariff_type == '' && so.contract_id == null && so.payment_type_id == 0" style="color: red;">
                  Шинэ Shipping Order
                </th>
                <th scope="row" *ngIf="so.tariff_type != '' && so.contract_id != '' && so.type == 'preoperation'" style="color: red;">
                  Тээврийн үнэ өөрчлөгдсөн байна<br/> урьдчилгаа хүлээгдэж байгаа
                </th>
                <th scope="row" *ngIf="so.tariff_type != '' && so.contract_id == null && so.payment_type_id != 0" style="color: red;">
                  Тээврийн үнэ өөрчлөгдсөн байна<br/> Гэрээ оруулаагүй байна
                </th>
                <th scope="row" *ngIf="so.tariff_type != '' && so.contract_id == null && so.payment_type_id == 0" style="color: red;">
                  Тээврийн үнэ өөрчлөгдсөн байна<br/> Шинэ Shipping Order
                </th>
                <th scope="row"
                title="Edit shipping order"
                (click)="shippingOrder(so.id)"
                class="edit"
                >
                  #{{so.shipping_order_name}}
                </th>
                <td>
                  {{so.consignee_name}}
                </td>
                <td>{{so.shipper_name}}</td>
                <td>{{so.payer_name}}</td>
                <td>{{so.cntr_name}}</td>
                <td>{{so.trans_type_name}}</td>
                <td>{{so.commodity}}</td>
                <td>{{so.payment_type_name}}</td>
                <!-- <td>{{so.currency_name}}</td>
                <td>{{so.rate}}</td> -->
                <!-- <td>{{so.origin}}</td>
                <td>{{so.destination}}</td> -->
                <td>{{so.consignee_phone}}</td>
                <td>{{so.consignee_email}}</td>
                <!-- <td>{{so.agents}}</td> -->
                <td>{{so.comments}}</td>
                <td>{{so.released_at}}</td>
                <td>{{so.so_start_date}}</td>
                <td>{{so.sales_name}}</td>
                <td>{{so.tariff_name}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
          <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
        </div>
        <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
          <label class="pages">No Shipping Orders</label>
        </div>
        <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;
          </span>
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          >{{page}}</span>
          <span
            [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
            (click)="nextPage()"
            class="next"
            >&raquo;
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>