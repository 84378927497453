<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 *ngIf="isNew">Шинэ хэрэглэгч</h1>
        <h1 *ngIf="!isNew">Хэрэглэгч засах</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <button
            type="button"
            class="btn btn-tool back-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Буцах"
            (click)="backList()"
          >
            <i class="fa fa-arrow-circle-left"></i>
          </button>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Мэдээлэл</h3>

        <div class="card-tools">
          
        </div>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="lastname">Lastname</label>
          <input type="text" class="form-control" name="lastname" placeholder="Овог" [(ngModel)]="lastname">
        </div>
        <div class="form-group">
          <label for="firstname">Firstname</label>
          <input type="text" class="form-control" name="firstname" placeholder="Нэр" [(ngModel)]="firstname">
        </div>
        <div class="form-group">
          <label for="phone">Phone</label>
          <input type="tel" class="form-control" name="phone" placeholder="Утасны дугаар" [(ngModel)]="phone">
        </div>
        <div class="form-group">
          <label for="email">Email address</label>
          <input type="email" class="form-control" name="email" placeholder="Имэйл хаяг" [(ngModel)]="email">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="text" class="form-control" name="password" placeholder="Нууц үг" [(ngModel)]="password">
        </div>
        <div class="form-group">
          <label for="perm">Type</label>
          <select class="form-control" [(ngModel)]="permission_id" name="perm">
            <option *ngFor="let permission of permissions" [value]="permission.id">{{permission.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="pos">Job type</label>
          <select class="form-control" [(ngModel)]="position_type_id" name="pos">
            <option *ngFor="let position_type of position_types" [value]="position_type.id">{{position_type.name}}</option>
          </select>
        </div>
        <mat-form-field color="accent" class="pr-3">
          <mat-label>Job Date</mat-label>
          <input matInput [matDatepicker]="picker2" (click)="picker2.open()" [(ngModel)]="job_date" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>
      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <button type="button" class="btn btn-primary" *ngIf="!isNew" (click)="edit()">Шинэчлэх</button>
        <button type="button" class="btn btn-primary" *ngIf="isNew" (click)="save()">Хадгалах</button>
      </div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>