<div class="main">
<div class="pageA4">
   <div class="printArea">
      <!-- 1st row -->
      <div class="firstRow">
        <div> <img src="assets/img/logo_print_white.png" width="70" /> </div>
        <div>Shipping Order</div>
      </div>
      <!-- 2nd row -->
      <div class="secondRow">
        <table border="1" width="100%">
          <tr>
            <td rowspan="7" width="150">Харилцагчийн нэр</td>
            <td rowspan="7" width="150">Эрдэнэбилэг Дөлгөөн</td>
          </tr>
          <tr>
            <td>Захиалгын дугаар</td>
            <td>{{shipping_order_name}}</td>
          </tr>
          <tr>
            <td>Захиалга авсан огноо</td>
            <td>{{so_start_date}}</td>
          </tr>            
          <tr>
            <td>Утасны дугаар</td>
            <td>{{consignee_phone}}</td>
          </tr>
          <tr>
            <td>И-мэйл хаяг:</td>
            <td>{{consignee_email}}</td>
          </tr>
        </table>
      </div>
      <!-- 3rd row -->
      <div class="row-full">
        <table border="1" width="100%">
          <tr>
            <td>Ачаа нийлүүлэгч</td>
            <td colspan="3">{{supplier_name}}</td>
          </tr>
          <tr>
            <td>Тээврийн замнал</td>
            <td>
              <div>{{pol_country_name}}</div>
              <div>{{pol_city_name}}</div>
            </td>
            <td>
              <div>                
                                    
               </div>
              <div>{{border_name}}</div>
            </td>
            <td>
              <div>{{final_country_name}}</div>
              <div>{{final_city_name}}</div>
            </td>
          </tr>
          <tr>
            <td>Ачааны нэхэмжлэлийн дугаар</td>
            <td colspan="3">
              
            </td>
          </tr>
          <tr>
            <td>Гаалийн мэдүүлгийн дугаар</td>
            <td colspan="3">
              
            </td>
          </tr>
          <tr>
            <td>Ачааны нэр:</td>
            <td colspan="3">{{commodity}}</td>
          </tr>
          <tr>
            <td>Ачааны тоо ширхэг:</td>
            <td colspan="3">{{quantity}} ширхэг</td>
          </tr>
        </table>
      </div>

      <!-- 4th row -->
      <div class="row-full">
        <table width="100%">
          <tr>
            <td><strong>Weight /kg/</strong></td>
            <td><strong>Length /cm/</strong></td>
            <td><strong>Width /cm/</strong></td>
            <td><strong>Height /cm/</strong></td>
          </tr>
          <tr>
              <td>{{box_weight}}</td>
              <td>{{box_length}}</td>
              <td>{{box_weight}}</td>
              <td>{{box_height}}</td>
            </tr>
        </table>
      </div>

      <!-- 5th row -->
      <div class="row-full">
        <table width="100%" class="centered">
          <tr>
            <td><strong>Ажил үйлчилгээний нэр</strong></td>
            <td><strong>Валют</strong></td>              
            <td><strong>Ханш</strong></td>
            <td><strong>Үнэ</strong></td>
            <td><strong>Дүн ₮</strong></td>
            <td><strong>Төлбөр төлөх тал</strong></td>
          </tr>
          
          <tr *ngFor ="let data  of  inquiry_tariff_prices">
                <td>{{data.name}}</td>
                <td *ngIf = "data.currency_name == 'MNT'">
                   ₮
                </td>
                <td *ngIf = "data.currency_name == 'USD-MGB'">
                  $
               </td>
               <td *ngIf = "data.currency_name == 'EURO'">
                €
              </td>
              <td *ngIf = "data.currency_name == 'RMB-MGB'">
                ₽
              </td>
                <td>{{data.currency_rate | currency : ' '}}</td>
                <td>{{data.price | currency : ' '}}</td>
                <td>{{data.amount | currency : ' '}}₮</td>
                <td></td>
              </tr>
          
              <tr>
              <td><strong>Ашиг</strong></td>
              <td>
                <strong>
                  {{currency_name}}
                 </strong>
              </td>              
              <td><strong>{{rate | currency : ' '}}</strong></td>
              <td><strong>{{profit/rate | currency : ' '}}</strong></td>
              <td><strong>{{profit | currency : ' '}}₮</strong></td>
              <td><strong></strong></td>
            </tr>
                        
             <tr>
              <td><strong>Нийт захиалгын орлого</strong></td>
              <td>
                <strong>
                   {{currency_name}}
                </strong>
              </td>              
              <td><strong>{{rate | currency : ' '}}</strong></td>
              <td><strong>{{price | currency : ' '}}</strong></td>
              <td><strong>{{price*rate | currency : ' '}}₮</strong></td>
              <td><strong></strong></td>
            </tr>
                        
        </table>
      </div>

      <!-- 6th row -->
      <div class="row-full">
        <div class="firstRow textOptimize">ГААЛИЙН БҮРДҮҮЛЭЛТИЙН ҮЙЛЧИЛГЭЭНИЙ МЭДЭЭЛЭЛ БӨГЛӨХ </div>
        <table width="100%" class="centered">
          <tr>
            <td width="300">Овог нэр:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Регистрийн дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Утасны дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Дансны дугаар:</td>
            <td>
                            </td>              
          </tr>
        </table>
      </div>

      <!-- 7th row -->
      <div class="row-full">
        <div class="firstRow textOptimize">ДОТООД ТЭЭВЭРТ МОНГОЛ МАШИН АШИГЛАСАН ТОХИОЛДОЛД БҮРТГЭЛ ХӨТӨЛНӨ </div>
        <table width="100%" class="centered">
          <tr>
            <td width="300">Жолоочийн овог нэр:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Машины улсын дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Регистрийн дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Жолоочийн утасны дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Дансны дугаар:</td>
            <td>
                            </td>              
          </tr>
        </table>
      </div>

      <!-- 8th row -->
      <div class="row-full">
        <div class="firstRow textOptimize">ДОТООД ХҮРГЭЛТ ХИЙСЭН ТОХИОЛДОЛД БҮРТГЭЛ ХӨТӨЛНӨ </div>
        <table width="100%" class="centered">
          <tr>
            <td width="300">Жолоочийн овог нэр:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Машины улсын дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Регистрийн дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Жолоочийн утасны дугаар:</td>
            <td>
                            </td>              
          </tr>
          <tr>
            <td width="300">Дансны дугаар:</td>
            <td>
                            </td>              
          </tr>
        </table>
      </div>

      <!-- 9th row -->
      <div class="row-full">
        <table class="centered" width="100%">
          <tr>
            <td width="300" class="tddncolor"> CHANGE THE WORLD...<br>CONGRATULATIONS!!! </td>
            <td width="200">Тамга</td>
            <td>
              <div class="tddncolor"> Захиалгын менежер </div>
              <div style="text-align: right"> гарын үсэг</div>
              <div class="tddncolor"> Хянасан тарифф менежер </div>
              <div style="text-align: right"> гарын үсэг</div>
              <div class="tddncolor"> Батласан</div>
              <div style="text-align: right"> гарын үсэг</div>
            </td>
          </tr>
        </table>
      </div>

    </div>
</div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>