<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <h1>Single Person Report / {{sdate}} - {{edate}} / {{rtype}}</h1>
      </div>
      <!-- <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">User Profile</li>
        </ol>
      </div> -->
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <!-- Profile Image -->
        <div class="card card-primary card-outline">
          <div class="card-body box-profile">
            <img
                class="profile-user-img img-fluid img-circle"
                src="assets/img/no-image.png"
                alt="User profile picture"
                *ngIf="user_report != null && user_report.image == null"
              />
              <img
                class="profile-user-img img-fluid img-circle"
                src="{{imageFileUrl}}/images/{{user_report.image}}"
                alt="User profile picture"
                *ngIf="user_report != null && user_report.image != null"
              />
              <p style="display: inline-block;margin-left: 10px;font-size: 20px;font-weight: bold;" *ngIf="user_report != null">{{user_report.name}} <br/> 
                <span style="color: green;">{{type}}</span></p>
              <br/><br/>
            <div class="input-group">
              <label>Нэхэмжилсэн үнийн дүн</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.invoice_amount != null">{{user_report.invoice_amount | currency : ' '}} ₮</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.invoice_amount == null">0.00 ₮</label>
            </div>
            <div class="input-group">
              <label>Цэвэр ашиг</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.profit != null">{{user_report.profit | currency : ' '}} ₮</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.profit == null">0.00 ₮</label>
            </div>
            <div class="input-group">
              <label>Төлөгдөөгүй нэхэмжлэхийн дүн</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.invoice_unamount != null">{{user_report.invoice_unamount | currency : ' '}} ₮</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.invoice_unamount == null">0.00 ₮</label>
            </div>
            <div class="input-group">
              <label>Төлөгдөөгүй агентын төлбөр</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.agent_amount != null">{{user_report.agent_amount | currency : ' '}} ₮</label>
              <label style="float: right;" *ngIf="user_report != null && user_report.agent_amount == null">0.00 ₮</label>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </div>
      <!-- /.col -->
      <div class="col-md-6">
        <div class="card card-primary">
          <div class="card-header">
            <h3 class="card-title">Shipment мэдээлэл</h3>
          </div>          
          <form>
            <div class="card-body" *ngIf="user_report">
              <div class="form-group" *ngFor="let tr of user_report.trans;">
                <label>{{tr.trans_name}}</label>
                <label style="float: right;">{{tr.quantity}}</label>
              </div>
              <div class="form-group">
                <label>Total</label>
                <label style="float: right;">{{count}}</label>
              </div>
            </div> 
          </form>
          </div>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>