import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})

export class ReportsComponent implements OnInit {
  reports = [];
  reports_old = [];
  loading = false;
  page = 1;
  filteredItems = [];
  pages : number = 6;
  pageSize : number = 20;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  modalService: any;
  public startDate;
  public endDate;
  sname = 'Payable';
  type = 'Payable';

  constructor(public appService: AppService, public data: DataProvider, public router: Router, public rest: CallapiService,
    private spinner: NgxSpinnerService,private toastr: ToastrService) {
    data.menuPageTitle = 'reports';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.toastr.success('', 'Та огноогоо оруулаад хайна уу!');
    // this.spinner.show();
    // this.getList();
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.reports = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  clear() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.startDate = '';
    this.endDate = '';
    this.filteredItems = this.reports_old;
    this.refreshItems();
  }

  search() {
    if(this.startDate == null || this.startDate == '') {
      this.toastr.error('Алдаа!', 'Та эхлэх огноогоо оруулна уу!');
      return;
    }
    if(this.endDate == null || this.endDate == '') {
      this.toastr.error('Алдаа!', 'Та дуусах огноогоо оруулна уу!');
      return;
    }
    this.getList();
  }

  changeName() {
    this.sname = this.type;
    this.search();
  }
  
  getList() {
    this.spinner.show();
    var m = new Date(new Date(this.startDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
    var dateString =
        m.getFullYear() + "-" +
        ("0" + (m.getMonth()+1)).slice(-2) + "-" +
        ("0" + m.getDate()).slice(-2) + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) + ":" +
        ("0" + m.getSeconds()).slice(-2);

    var m2 = new Date(new Date(this.endDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
    var dateString2 =
        m2.getFullYear() + "-" +
        ("0" + (m2.getMonth()+1)).slice(-2) + "-" +
        ("0" + m2.getDate()).slice(-2) + " " +
        ("0" + m2.getHours()).slice(-2) + ":" +
        ("0" + m2.getMinutes()).slice(-2) + ":" +
        ("0" + m2.getSeconds()).slice(-2);
    this.rest.reportList(this.type, dateString.substring(0,10), dateString2.substring(0,10)).subscribe((res) =>{
      this.spinner.hide();
      this.reports = res;
      this.filteredItems = this.reports;
      this.reports_old = this.reports;
      this.currentIndex = 1;
      this.pageStart = 1;
      this.pages = 6;
      this.pageNumber = parseInt(
        '' + this.filteredItems.length / this.pageSize
      );
      if (this.filteredItems.length % this.pageSize != 0) {
        this.pageNumber++;
      }
      if (this.pageNumber < this.pages) {
        this.pages = this.pageNumber;
      }
      this.refreshItems();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  exportExcel() { 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Report');
    if(this.type == 'Payable' || this.type == 'Receivable') {
      worksheet.columns = [
        { header: 'SO/NO', key: 'so', width: 15 },
        { header: 'Байгууллагын нэр', key: 'name', width: 15 },
        { header: 'Нийт дүн /MNT/', key: 'amount', width: 32 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            so: this.reports_old[i].shipping_order_name, 
            name: this.reports_old[i].agent_name, 
            amount: this.reports_old[i].amount,
          }, 'n'
        );
      }
    } else if(this.type == 'TransMode') {
      worksheet.columns = [
        { header: 'Transportation Mode', key: 'name', width: 15 },
        { header: 'Shipment Quantity', key: 'quantity', width: 32 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].trans_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'TransMode FCL') {
      worksheet.columns = [
        { header: 'FCL Type Name', key: 'name', width: 15 },
        { header: 'Quantity', key: 'quantity', width: 32 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].trans_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'Sales Profit') {
      worksheet.columns = [
        { header: 'Sales Name', key: 'name', width: 15 },
        { header: 'Profit /MNT/', key: 'profit', width: 32 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].sales_name, 
            profit: this.reports_old[i].profit,
          }, 'n'
        );
      }
    } else if(this.type == 'Tariff Profit') {
      worksheet.columns = [
        { header: 'Tariff Name', key: 'name', width: 15 },
        { header: 'Profit /MNT/', key: 'profit', width: 32 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].tariff_name, 
            profit: this.reports_old[i].profit,
          }, 'n'
        );
      }
    } else if(this.type == 'Sales Shipment') {
      worksheet.columns = [
        { header: 'Sales Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].sales_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'Tariff Shipment') {
      worksheet.columns = [
        { header: 'Tariff Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].tariff_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'Consignee') {
      worksheet.columns = [
        { header: 'Consignee Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].consignee_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'Average Profit') {
      worksheet.columns = [
        { header: 'Transportation Mode', key: 'name', width: 32 },
        { header: 'Average Profit/MNT/', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].trans_name, 
            quantity: this.reports_old[i].profit,
          }, 'n'
        );
      }
    } else if(this.type == 'Container Status') {
      worksheet.columns = [
        { header: 'Container Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].container_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'Terminal') {
      worksheet.columns = [
        { header: 'Terminal Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].terminal_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'Via') {
      worksheet.columns = [
        { header: 'Via Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].via_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'SO Sales') {
      worksheet.columns = [
        { header: 'Sales Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
        { header: 'Total Profit/MNT/', key: 'profit', width: 32 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].sales_name, 
            quantity: this.reports_old[i].quantity,
            profit: this.reports_old[i].profit,
          }, 'n'
        );
      }
    } else if(this.type == 'SO Tariff') {
      worksheet.columns = [
        { header: 'Tariff Name', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
        { header: 'Total Profit/MNT/', key: 'profit', width: 32 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].tariff_name, 
            quantity: this.reports_old[i].quantity,
            profit: this.reports_old[i].profit,
          }, 'n'
        );
      }
    } else if(this.type == 'Inquiry') {
      worksheet.columns = [
        { header: 'Төрөл', key: 'name', width: 32 },
        { header: 'Total Quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].inquiry_name, 
            quantity: this.reports_old[i].quantity,
          }, 'n'
        );
      }
    } else if(this.type == 'Inquiry Cost' || this.type == 'SO Cost' || this.type == 'Finished Cost') {
      worksheet.columns = [
        { header: 'Орлого', key: 'amount', width: 32 },
        { header: 'Зарлага', key: 'amount2', width: 32 },
        { header: 'Ашиг', key: 'profit', width: 32 },
        { header: 'Нэр', key: 'name', width: 32 },
        { header: 'Төрөл', key: 'type', width: 15 },
        { header: 'Total quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        if(this.reports_old[i].type == 'sales') {
          worksheet.addRow(
            {
              name: this.reports_old[i].sales_name, 
              quantity: this.reports_old[i].quantity,
              amount: this.reports_old[i].amount,
              amount2: 0,
              profit: this.reports_old[i].profit,
              type: this.reports_old[i].type,
            }, 'n'
          );
        } else {
          worksheet.addRow(
            {
              name: this.reports_old[i].tariff_name, 
              quantity: this.reports_old[i].quantity,
              amount: 0,
              amount2: this.reports_old[i].amount,
              profit: this.reports_old[i].profit,
              type: this.reports_old[i].type,
            }, 'n'
          );
        }
      }
    } else if(this.type == 'Inquiry History') {
      worksheet.columns = [
        { header: 'Name', key: 'name', width: 32 },
        { header: 'User', key: 'user', width: 32 },
        { header: 'Төрөл', key: 'type', width: 15 },
        { header: 'Тайлбар', key: 'desc', width: 32 },
        { header: 'Огноо', key: 'date', width: 20 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].name, 
            user: this.reports_old[i].report.sales_lastname + '-н ' + this.reports_old[i].report.sales_firstname,
            type: this.reports_old[i].sales_type,
            desc: this.reports_old[i].descr,
            date: this.reports_old[i].history_date,
          }, 'n'
        );
      }
    } else if(this.type == 'SO History') {
      worksheet.columns = [
        { header: 'Name', key: 'name', width: 32 },
        { header: 'User', key: 'user', width: 32 },
        { header: 'Төрөл', key: 'type', width: 15 },
        { header: 'Тайлбар', key: 'desc', width: 32 },
        { header: 'Огноо', key: 'date', width: 20 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].shipping_order_name, 
            user: this.reports_old[i].report.sales_lastname + '-н ' + this.reports_old[i].report.sales_firstname,
            type: this.reports_old[i].sales_type,
            desc: this.reports_old[i].descr,
            date: this.reports_old[i].history_date,
          }, 'n'
        );
      }
    } else if(this.type == 'Operation History') {
      worksheet.columns = [
        { header: 'Name', key: 'name', width: 32 },
        { header: 'User', key: 'user', width: 32 },
        { header: 'Төрөл', key: 'type', width: 15 },
        { header: 'Тайлбар', key: 'desc', width: 32 },
        { header: 'Огноо', key: 'date', width: 20 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].shipping_order_name, 
            user: this.reports_old[i].report.sales_lastname + '-н ' + this.reports_old[i].report.sales_firstname,
            type: this.reports_old[i].sales_type,
            desc: this.reports_old[i].descr,
            date: this.reports_old[i].history_date,
          }, 'n'
        );
      }
    } else if(this.type == 'Finance History') {
      worksheet.columns = [
        { header: 'SO Name', key: 'name', width: 32 },
        { header: 'Tariff Price Name', key: 'price', width: 32 },
        { header: 'User', key: 'user', width: 32 },
        { header: 'Төрөл', key: 'type', width: 15 },
        { header: 'Тайлбар', key: 'desc', width: 32 },
        { header: 'Огноо', key: 'date', width: 20 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        worksheet.addRow(
          {
            name: this.reports_old[i].shipping_order_name, 
            price: this.reports_old[i].name, 
            user: this.reports_old[i].report.sales_lastname + '-н ' + this.reports_old[i].report.sales_firstname,
            type: this.reports_old[i].sales_type,
            desc: this.reports_old[i].descr,
            date: this.reports_old[i].history_date,
          }, 'n'
        );
      }
    } else if(this.type == 'Цалин') {
      worksheet.columns = [
        { header: 'Цалин', key: 'amount', width: 32 },
        { header: 'Ашиг', key: 'profit', width: 32 },
        { header: 'Нэр', key: 'name', width: 32 },
        { header: 'Төрөл', key: 'type', width: 15 },
        { header: 'Total quantity', key: 'quantity', width: 15 },
      ];

      for(let i = 0; i < this.reports_old.length; i++) {
        if(this.reports_old[i].type == 'sales') {
          worksheet.addRow(
            {
              name: this.reports_old[i].sales_name, 
              quantity: this.reports_old[i].quantity,
              amount: this.reports_old[i].amount,
              profit: this.reports_old[i].profit,
              type: this.reports_old[i].type,
            }, 'n'
          );
        } else {
          worksheet.addRow(
            {
              name: this.reports_old[i].tariff_name, 
              quantity: this.reports_old[i].quantity,
              amount: this.reports_old[i].amount,
              profit: this.reports_old[i].profit,
              type: this.reports_old[i].type,
            }, 'n'
          );
        }
      }
    }
 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Reports(' + this.type + ').xlsx');
    })
  }

  reportUser(id: any, type: any) {
    var date = new Date(new Date(this.startDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
    var dateString =
        date.getFullYear() + "-" +
        ("0" + (date.getMonth()+1)).slice(-2) + "-" +
        ("0" + date.getDate()).slice(-2) + " " +
        ("0" + date.getHours()).slice(-2) + ":" +
        ("0" + date.getMinutes()).slice(-2) + ":" +
        ("0" + date.getSeconds()).slice(-2);

    var date2 = new Date(new Date(this.endDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
    var dateString2 =
        date2.getFullYear() + "-" +
        ("0" + (date2.getMonth()+1)).slice(-2) + "-" +
        ("0" + date2.getDate()).slice(-2) + " " +
        ("0" + date2.getHours()).slice(-2) + ":" +
        ("0" + date2.getMinutes()).slice(-2) + ":" +
        ("0" + date2.getSeconds()).slice(-2);
    window.open('/report/user;id=' + id + ';start_date=' + dateString.substring(0,10) + ';end_date=' + dateString2.substring(0,10) + ';type=' + type + ';rtype=' + this.type, '_blank');
  }

}
