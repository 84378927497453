<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="containeriu">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h5 class="title-text">Shipping Order</h5>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <button
            type="button"
            class="btn btn-tool back-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Буцах"
            (click)="backList()"
          >
            <i class="fa fa-arrow-circle-left"></i>
          </button>
        </ol>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Payment Information</h3>

        <div class="card-tools">
          <!-- <button
            type="button"
            class="btn btn-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Add"
          >
            <i class="fa fa-plus-circle"></i>
          </button> -->
        </div>
      </div>
      <div class="card-body">
        <div class="row mt-3">
          <div class="col-md-6 col-12">
            <form class="example-form">
              <mat-form-field class="example-full-width">
                <mat-label class="text">Payer</mat-label>
                <input type="text"
                       matInput
                       [formControl]="Payer"
                       [matAutocomplete]="payer"
                       (ngModelChange)="_filterCons()"
                       id="consignee"
                       >
                <mat-autocomplete autoActiveFirstOption #payer="matAutocomplete" (optionSelected)="onConsigneeSelected($event.option)">
                  <mat-option *ngFor="let data of consignees" [value]="data.name">
                    {{data.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
          <div class="col-md-6 col-12">
            <form class="example-form">
              <mat-form-field class="example-full-width">
                <mat-label class="text">Shipper</mat-label>
                <input type="text"
                       matInput
                       [formControl]="Shipper"
                       [matAutocomplete]="shipp"
                       (ngModelChange)="_filterSupp()"
                       id="supplier">
                <mat-autocomplete autoActiveFirstOption #shipp="matAutocomplete" (optionSelected)="onSupplierSelected($event.option)">
                  <mat-option *ngFor="let data of suppliers" [value]="data.name">
                    {{data.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6 col-12">
            <mat-form-field class="example-full-width">
              <mat-label class="text">Payment type</mat-label>
              <mat-select  [(ngModel)]="payment_type_id" >
                <mat-option
                  *ngFor="let data of paymentType"
                  [value]="data.id"
                >
                  {{ data.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-12">
            <mat-form-field class="example-full-width">
              <mat-label class="text">Container status</mat-label>
              <mat-select  [(ngModel)]="container_id" >
                <mat-option
                  *ngFor="let condiotion of containerStatus"
                  [value]="condiotion.id"
                >
                  {{ condiotion.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6 col-12">
            <mat-form-field class="example-full-width">
              <mat-label class="text">Payment Term</mat-label>
              <mat-select [(ngModel)]="payment_term_id">
                <mat-option
                  *ngFor="let data of paymentTerm"
                  [value]="data.id"
                >
                  {{ data.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="col-md-6 col-12">
            <mat-form-field class="example-full-width">
              <mat-label class="text">Route</mat-label>
              <mat-select [(ngModel)]="route_id">
                <mat-option
                  *ngFor="let data of routes"
                  [value]="data.id"
                >
                  {{ data.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div class="col-md-6 col-12">
            <mat-form-field class="example-full-width">
              <mat-label class="text">THC/Terminal</mat-label>
              <mat-select [(ngModel)]="terminal_id">
                <mat-option
                  *ngFor="let division of terminal"
                  [value]="division.id"
                >
                  {{ division.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- <div class="row mt-3">
          
          <div class="col-md-6 col-12">
          </div>
        </div> -->
        <div class="row mt-3">
          <div class="col-12">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label class="text">Additional information</mat-label>
              <textarea matInput class="textHeight" [(ngModel)]="comments"> </textarea>
            </mat-form-field>
            <div class="d-flex justify-content-between">
              <button mat-flat-button color="primary" (click) = "update()" *ngIf="data.userData.permission == 4">Шинэчлэх</button>
              <button mat-flat-button color="accent" (click) = "print()">Хэвлэх</button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Гэрээ</h3>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between">
            <mat-form-field class="example-full-width">
              <mat-label class="text">Гэрээний дугаар</mat-label>
              <input matInput value="" [(ngModel)]="contract_id" >
            </mat-form-field>
            <div class="file">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="contract_type" (change) = "typeChange()">
                <mat-radio-button value="oneTime" class="pr-3 text" selected >Нэг удаагийн гэрээ</mat-radio-button>
                <mat-radio-button value="general" class="text">Ерөнхий гэрээ</mat-radio-button>
              </mat-radio-group>

              <div class="input-group pt-3">
                <div class="custom-file" *ngIf = "chk">
                  <input type="file" (change)="setFileName(fileInput)" #fileInput class="custom-file-input" id="file" name="file" accept="application/pdf">
                  <label class="custom-file-label" for="file" id="img-label">Файл оруулах</label>
                </div>
                <div class="custom-file" *ngIf = "!chk">
                  <a href="{{imageFileUrl}}/files/{{box_file}}" target="_blank">{{box_file}}</a>
                </div>
              </div>
            </div>
            <div>
              <p>Урьдчилгаа төлбөр</p>
              <div class="progress-wrapper">
                <input type="range"  class="control" min="0" max="100" step="1" [(ngModel)]="pre_payment" />
                <p class="progress-text">{{pre_payment}}%</p>
              </div>
            </div>
        </div>
      </div> 
      <div class="card-footer white d-flex justify-content-end">
        <button mat-flat-button color="primary" (click) = "save()" *ngIf="data.userData.permission == 4">Хадгалах</button>
      </div>
    </div>
  </div>
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>