import { Injectable } from '@angular/core';
import { DataProvider } from '../data/data';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from 'src/environments/environment';
// import { API_URL, BEA_POINT_API_URL, POINT_NOTIFICATION_API_URL, PT_API_URL } from '../../environments/environment';

@Injectable()
export class CallapiService {
  constructor(private data: DataProvider, public http: HttpClient) { }

  public login(email: string,password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      email: email,
      password: password,
    };
    return this.http.post(API_URL + '/login', body, {
      responseType: 'json',
      headers,
    });
  }

  public getMain(id: number): Observable<any> {
    return this.http.get(API_URL + '/get_main/' + id);
  }

  public getSchedule(year, month): Observable<any> {
    return this.http.get(API_URL + '/get_schedule/' + year + '/' + month);
  }

  public check(id: number): Observable<any> {
    return this.http.get(API_URL + '/check/' + id);
  }

  public uploadFile(pdf: any, name: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });    
    const imageBlob = this.dataURItoBlobPdf(pdf);
    const imageFile = new File([imageBlob], 'file.pdf', { type: 'application/pdf' });
    let formData = new FormData();
    formData.append('pdf', imageFile, name);
    return this.http.post(API_URL + '/upload_file', formData, {
      responseType: 'json',
      // headers,
    });
  }

  public getCity(country_id: number): Observable<any> {
    return this.http.get(API_URL + '/citys/' + country_id);
  }

  public getTransportTypes(mode_id: number): Observable<any> {
    return this.http.get(API_URL + '/transport_types/' + mode_id);
  }

  public getTransportModes(): Observable<any> {
    return this.http.get(API_URL + '/transport_modes');
  }

  dataURItoBlobPdf(dataURI: any) {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  public uploadImage(image: any, name: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });    
    const imageBlob = this.dataURItoBlob(image);
    const imageFile = new File([imageBlob], 'photo.png', { type: 'image/png' });
    let formData = new FormData();
    formData.append('image', imageFile, name);
    return this.http.post(API_URL + '/upload', formData, {
      responseType: 'json',
      // headers,
    });
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  public changeProfile(id: any, image: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      id: id,
      image: image
    };
    return this.http.post(API_URL + '/change_profile', body, {
      responseType: 'json',
      headers,
    });
  }

  public changePass(id: any, phone: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      id: id,
      phone: phone,
      password: password,
    };
    return this.http.post(API_URL + '/change_pass', body, {
      responseType: 'json',
      headers,
    });
  }

  public inquiryHelpers(): Observable<any> {
    return this.http.get(API_URL + '/inquiry_helpers');
  }

  public shippingOrderList(): Observable<any> {
    return this.http.get(API_URL + '/shipping_orders_list');
  }

  public inquiryList(user_id: any): Observable<any> {
    return this.http.get(API_URL + '/inquiry_list/' + user_id);
  }

  public consignees(): Observable<any> {
    return this.http.get(API_URL + '/consignees');
  }

  public shippingOrderHelpers(): Observable<any> {
    return this.http.get(API_URL + '/shipping_orders_helpers');
  }

  public shippingOrderDetail(id: any): Observable<any> {
    return this.http.get(API_URL + '/shipping_order_detail/' + id);
  }

  public getSoFile(id: any): Observable<any> {
    return this.http.get(API_URL + '/get_so_file/' + id);
  }

  public shippingOrderEdit(
    id: any,
    payer_id: number,
    shipper_id: number,
    payment_type: any,
    payment_term: string,
    route_id: number,
    terminal_id: number,
    comments: string,
    container_id: number,
    inquiry_id: number,
    
    ): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      id: id,
      payer_id: payer_id,
      shipper_id: shipper_id,
      payment_type_id: payment_type,
      payment_term_id: payment_term,
      route_id: route_id,
      terminal_id: terminal_id,
      comments: comments,
      container_id: container_id,
      user_id: this.data.userData.id,
      inquiry_id: inquiry_id
    };
    return this.http.put(API_URL + '/edit_shipping_order', body, {
      responseType: 'json',
      headers,
    } );
  }
  public shippingOrderEditFile(
    id: any,
    contract_id: number,
    contract_type: string,
    contract_file: any,
    pre_payment: number,
    ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      id: id,
      contract_id: contract_id,
      contract_type: contract_type,
      contract_file: contract_file,
      pre_payment: pre_payment,
      user_id: this.data.userData.id
    };
    return this.http.put(API_URL + '/edit_shipping_order_file', body, {
      responseType: 'json',
      headers,
    } );
  }
  
  public inquiryAdd(
    name: string,
    consignee_id: any,
    commodity: string,
    division_id: number,
    condition_id: number,
    trans_mode_id: number, 
    trans_type_id: number,
    incoterm_id: number,
    incoterm_address: string,
    inquiry_type_id: number,
    supplier_id: any,
    is_vat: number,
    pol_country_id: number,
    pol_city_id: number,
    pol_address: string,
    border_id: number,
    final_country_id: number,
    final_city_id: number,
    final_address: string,
    via_id: number,
    remark_comment: string,
    quotation_date: string,
    validaty_date: string,
    box_dup: number,
    gross_weight: number,
    gross_cbm: number,
    hemjees: string,
    // so_start_date: string,
    sales_id: number,
    tariff_id: number,
    weights: any,
    ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // authorization: localStorage.getItem('token'),
    });
    const body = {
      inquiry: {
        name: name,
        consignee_id: consignee_id,
        commodity: commodity,
        division_id: division_id,
        condition_id: condition_id,
        trans_mode_id: trans_mode_id,
        trans_type_id: trans_type_id,
        incoterm_id: incoterm_id,
        incoterm_address: incoterm_address,
        inquiry_type_id: inquiry_type_id,
        supplier_id: supplier_id,
        is_vat: is_vat,
        pol_country_id: pol_country_id,
        pol_city_id: pol_city_id,
        pol_address: pol_address,
        border_id: border_id,
        final_country_id: final_country_id,
        final_city_id: final_city_id,
        final_address: final_address,
        via_id: via_id,
        remark_comment: remark_comment,
        quotation_date: quotation_date,
        validaty_date: validaty_date,
        box_dup: box_dup,
        gross_weight: gross_weight,
        gross_cbm: gross_cbm,
        hemjees: hemjees,
        // so_start_date: so_start_date,
        sales_id: sales_id,
        tariff_id: tariff_id,
        update_user: this.data.userData.id,
      },
      weights: weights,
    };
    return this.http.post(API_URL + '/add_inquiry', body, {
      responseType: 'json',
      headers,
    });
  }

  public closedInquiry(user_id: any): Observable<any> {
    return this.http.get(API_URL + '/inquiry_list_closed/' + user_id);
  }

  public inquiryDetail(inquiry_id: any): Observable<any> {
    return this.http.get(API_URL + '/inquiry_detail/' + inquiry_id);
  }

  public deleteInquiry(inquiry_id: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      inquiry_id: inquiry_id,
      update_user: this.data.userData.id
    };
    return this.http.post(API_URL + '/delete_inquiry', body, {
      responseType: 'json',
      headers,
    });
  }

  public closeInquiry(inquiry_id: any, comment: string, reason_id): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      inquiry_id: inquiry_id,
      comment: comment,
      reason_id: reason_id,
      update_user: this.data.userData.id
    };
    return this.http.post(API_URL + '/close_inquiry', body, {
      responseType: 'json',
      headers,
    });
  }

  public uncloseInquiry(inquiry_id: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      inquiry_id: inquiry_id,
      update_user: this.data.userData.id
    };
    return this.http.post(API_URL + '/unclose_inquiry', body, {
      responseType: 'json',
      headers,
    });
  }

  public moveInquiry(inquiry_id: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = {
      inquiry_id: inquiry_id,
      update_user: this.data.userData.id
    };
    return this.http.post(API_URL + '/move_inquiry', body, {
      responseType: 'json',
      headers,
    });
  }

  public editInquiry(
    inquiry_id: number,
    name: string,
    consignee_id: any,
    commodity: string,
    division_id: number,
    condition_id: number,
    trans_mode_id: number, 
    trans_type_id: number,
    incoterm_id: number,
    incoterm_address: string,
    inquiry_type_id: number,
    supplier_id: any,
    is_vat: number,
    pol_country_id: number,
    pol_city_id: number,
    pol_address: string,
    border_id: number,
    final_country_id: number,
    final_city_id: number,
    final_address: string,
    via_id: number,
    remark_comment: string,
    tariff_comment: string,
    quotation_date: string,
    validaty_date: string,
    box_dup: number,
    gross_weight: number,
    gross_cbm: number,
    hemjees: string,
    transit_time: string,
    sales_id: number,
    tariff_id: number,
    back_to_tariff: number,
    weights: any,
    quotation: any,
    expenses: any,
    tariff_prices: any,
    ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let body = null;
    if(this.data.userData.permission == 4) {
      body = {
        inquiry: {
          id: inquiry_id,
          name: name,
          consignee_id: consignee_id,
          commodity: commodity,
          division_id: division_id,
          condition_id: condition_id,
          trans_mode_id: trans_mode_id,
          trans_type_id: trans_type_id,
          incoterm_id: incoterm_id,
          incoterm_address: incoterm_address,
          inquiry_type_id: inquiry_type_id,
          supplier_id: supplier_id,
          is_vat: is_vat,
          pol_country_id: pol_country_id,
          pol_city_id: pol_city_id,
          pol_address: pol_address,
          border_id: border_id,
          final_country_id: final_country_id,
          final_city_id: final_city_id,
          final_address: final_address,
          via_id: via_id,
          remark_comment: remark_comment,
          quotation_date: quotation_date,
          validaty_date: validaty_date,
          box_dup: box_dup,
          gross_weight: gross_weight,
          gross_cbm: gross_cbm,
          hemjees: hemjees,
          transit_time: transit_time,
          sales_id: sales_id,
          tariff_id: tariff_id,
          update_user: this.data.userData.id,
          back_to_tariff: back_to_tariff
        },
        weights: weights,
        quotation: quotation,
        expenses: expenses,
      };
    } else {
      body = {
        inquiry: {
          id: inquiry_id,
          border_id: border_id,
          via_id: via_id,
          tariff_comment: tariff_comment,
          tariff_id: this.data.userData.id,
          transit_time: transit_time,
          update_user: this.data.userData.id
        },
        tariff_prices: tariff_prices,
      };
    }
    
    return this.http.put(API_URL + '/edit_inquiry', body, {
      responseType: 'json',
      headers,
    });
  }

  public usersList(): Observable<any> {
    return this.http.get(API_URL + '/users');
  }

  public usersHelpers(): Observable<any> {
    return this.http.get(API_URL + '/users_helpers');
  }

  public userDetail(id): Observable<any> {
    return this.http.get(API_URL + '/user_detail/' + id);
  }

  public addUser(user: any): Observable<any> {
    return this.http.post(API_URL + '/user/add', user);
  }

  public editUser(user: any): Observable<any> {
    return this.http.post(API_URL + '/user/add', user);
  }

  public currencyList(): Observable<any> {
    return this.http.get(API_URL + '/currency');
  }

  public currencyAllList(): Observable<any> {
    return this.http.get(API_URL + '/currency_all');
  }

  public currencyDetail(id): Observable<any> {
    return this.http.get(API_URL + '/currency_detail/' + id);
  }

  public editCurrency(currency: any): Observable<any> {
    return this.http.post(API_URL + '/currency/edit', currency);
  }
  
  public operationDetail(id): Observable<any> {
    return this.http.get(API_URL + '/operation_detail/' + id);
  }

  public operationList(): Observable<any> {
    return this.http.get(API_URL + '/operation_list');
  }

  public operationHelpers(): Observable<any> {
    return this.http.get(API_URL + '/operation_helpers');
  }

  public editOperation(operation: any): Observable<any> {
    return this.http.put(API_URL + '/operation_edit', operation);
  }

  public operationTraceList(id): Observable<any> {
    return this.http.get(API_URL + '/operation_traces/' + id);
  }

  public addOperationTrace(operation: any): Observable<any> {
    return this.http.post(API_URL + '/operation_trace_add', operation);
  }

  public deleteOperationTrace(operation: any): Observable<any> {
    return this.http.post(API_URL + '/operation_trace_delete', operation);
  }

  public fileOperation(operation: any): Observable<any> {
    return this.http.put(API_URL + '/operation_file', operation);
  }

  public finishedList(type: string): Observable<any> {
    return this.http.get(API_URL + '/finish/' + type);
  }

  public customsList(): Observable<any> {
    return this.http.get(API_URL + '/customs_list');
  }

  public editCustom(custom: any): Observable<any> {
    return this.http.post(API_URL + '/edit_custom', custom);
  }

  public sitesList(): Observable<any> {
    return this.http.get(API_URL + '/sites_list');
  }

  public editSite(site: any): Observable<any> {
    return this.http.post(API_URL + '/edit_site', site);
  }

  public financeList(): Observable<any> {
    return this.http.get(API_URL + '/finance_list');
  }

  public invoiceList(): Observable<any> {
    return this.http.get(API_URL + '/invoices');
  }

  public readyOP(id: any, sid: number, type: string): Observable<any> {
    return this.http.post(API_URL + '/invoice/ready', {id: id, sid: sid, type: type, user_id: this.data.userData.id});
  }

  public invoiceAdd(id: any, pre_payment: number): Observable<any> {
    return this.http.post(API_URL + '/invoice/add', {id: id, pre_payment: pre_payment});
  }

  public invoiceFinish(id: any, type: string): Observable<any> {
    return this.http.post(API_URL + '/invoice_finish', {id: id, type: type, user_id: this.data.userData.id});
  }

  public invoiceDetail(id): Observable<any> {
    return this.http.get(API_URL + '/invoice_detail/' + id);
  }

  public payableList(): Observable<any> {
    return this.http.get(API_URL + '/payable_list');
  }

  public payableFinish(id): Observable<any> {
    return this.http.post(API_URL + '/payable_finish/', {id: id, user_id: this.data.userData.id});
  }

  public reportList(type: string, sdate: any, edate: any): Observable<any> {
    return this.http.get(API_URL + '/reports/' + type + '/' + sdate + '/' + edate);
  }

  public reportUserList(id: any, type: string, sdate: any, edate: any): Observable<any> {
    return this.http.get(API_URL + '/report/user/' + id + '/' + type + '/' + sdate + '/' + edate);
  }

  public agentsList(): Observable<any> {
    return this.http.get(API_URL + '/agents');
  }

  public addAgent(user: any): Observable<any> {
    return this.http.post(API_URL + '/agent/add', user);
  }

  public consigneesList(): Observable<any> {
    return this.http.get(API_URL + '/consignees-list');
  }

  public addConsignee(user: any): Observable<any> {
    return this.http.post(API_URL + '/consignee/add', user);
  }

  public suppliersList(): Observable<any> {
    return this.http.get(API_URL + '/suppliers');
  }

  public addSupplier(user: any): Observable<any> {
    return this.http.post(API_URL + '/supplier/add', user);
  }

  public socList(): Observable<any> {
    return this.http.get(API_URL + '/soc_list');
  }
}
