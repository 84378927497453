// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

// export const API_URL = 'http://192.168.50.161:3000/api';
export const API_URL = 'https://simataiapi.unisub-tech.com/api';
export const ENCRYPT_SECRET_KEY = 'simatai-babeka';
export const VERSION = '1.0.0';
// export const IMAGE_FILE_URL = '/assets';
export const IMAGE_FILE_URL = 'https://simataiapi.unisub-tech.com/uploads';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
