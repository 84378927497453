<div class="main">
<div class="pageA4">
  <div class="printArea">
    <!-- Header -->

    <div class="row">
      <table width="100%">
        <tr>
          <td><div> <img src="assets/img/logo.png" width="125" /> </div></td>
          <td width="65%">
             <h1 style="text-align: center">
              <span class="colorred" style="font-size: 30px">Ачаа хүлээлцсэн баримт</span> <br>
              <small style="font-size: 18px; color: rgba(0,0,0,0.5)">Delivery Receipt</small>
             </h1>
          </td>
        </tr>
      </table>
    </div>

    <div class="row">
     <table width="100%" style="vertical-align: top">
       <tr>
         <td>
            <div class="redback"><strong>Гадаад тээвэр / International</strong></div>
            <div class="redback"><strong>Дотоод тээвэр / Domestic Freight</strong></div>
         </td>
         <td width="50%" style="text-align: right">
            <table width="100%" style="border-spacing:0">
              <tr>
                <td style="padding-right: 5px;">
                  <strong>Захиалагч компани</strong><br>
                  <span style="color: rgba(0,0,0,0.5)">Consignee:</span>
                </td>
                <td style="border: 1px solid rgba(0,0,0,0.3); background: rgba(0,0,0,0.05); text-align:center">
                                          {{consignee_name}}
                                      </td>
              </tr>
              <tr>
                <td style="padding-right: 5px;">
                  <strong>Утасны дугаар</strong><br>
                  <span style="color: rgba(0,0,0,0.5)">Warehouse officer:</span>
                </td>
                <td style="border: 1px solid rgba(0,0,0,0.3); background: rgba(0,0,0,0.05); text-align:center; border-top: none">
                  -
                </td>
              </tr>
            </table>
         </td>
       </tr>
     </table>
   </div>

   <div class="row">
     <table width="100%">
       <tr>
         <td style="text-align: center" width="25%">
            <strong>Захиалгын дугаар</strong> <br>
            <span style="color: rgba(0,0,0,0.5)"> Quotation number </span> 
         </td>
         <td width="25%" style="border: 1px solid rgba(0,0,0,0.3); text-align:center">
          <div><strong>{{shipping_order_name}}</strong></div>   
        </td>
        <td>
        </td>
        <td>
          &nbsp;
        </td>

       </tr>
     </table>
   </div>

   <div class="row">
    <table width="100%" class="table">
      <tr style="background: #012060; color: #fff">
        <td class="td">Invoice No</td>
        <td class="td" width="50%"><strong>Ачаа / Commodity</strong></td>  
        <td class="td">Тоо ширхэг<br>Unit</td>
        <td class="td">Хүлээн авсан тоо<br>Received QTY</td>
        <td class="td">Тэмдэглэл<br>Remark</td>         
      </tr>

      <tr style="background: rgba(0,0,0,0.1)">
        <td class="td">
          {{invoice_number}}
        </td>
        <td class="td">
                        {{commodity}}
                      </td>  
        <td class="td">
                                            {{quantity}} ширхэг 
                                      </td>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
      </tr>

      <tr>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>  
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
      </tr>

      <tr style="background: rgba(0,0,0,0.1)">
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>  
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
      </tr>

      <tr>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>  
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
      </tr>

      <tr style="background: rgba(0,0,0,0.1)">
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>  
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
      </tr>

      <tr>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>  
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
        <td class="td">&nbsp;</td>
      </tr>          
    </table>
  </div>

  <div class="row" style="text-align: center; background: rgba(0,0,0,0.1); padding: 10px; border: 1px solid rgba(0,0,0,0.5)">
    <strong>АЧАА ХҮЛЭЭЛЦЭХ ҮЕИЙН, ТАЙЛБАР:</strong><br>
    <span style="color: rgba(0,0,0,0.5)">Note on OSD and Damage</span>
  </div>
  <div style="border-top: 1px solid rgba(0,0,0,1); min-height: 100px; margin-top:-1px; border-left: 1px solid rgba(0,0,0,0.5); border-right: 1px solid rgba(0,0,0,0.5); border-bottom: 1px solid rgba(0,0,0,0.5)">
  </div>

  <table width="100%" style="border: 1px solid rgba(0,0,0,0.5); margin-top: -1px; background: rgba(0,0,0,0.1);">
    <tr>
      <td width="25%" style="text-align:center; padding: 10px; border-right: 1px solid rgba(0,0,0,0.5);">
        <strong>Баталгаажуулсан</strong><br>
        <span style="color: rgba(0,0,0,0.5)">Signed for</span>
      </td>
      <td width="25%" style="text-align:center; padding: 10px; border-right: 1px solid rgba(0,0,0,0.5);">
        <strong>Нэр, албан тушаал</strong><br>
        <span style="color: rgba(0,0,0,0.5)">Name and position</span>
      </td>
      <td width="25%" style="text-align:center; padding: 10px; border-right: 1px solid rgba(0,0,0,0.5);">
        <strong>Гарын үсэг</strong><br>
        <span style="color: rgba(0,0,0,0.5)">Signature</span>
      </td>
      <td width="25%" style="text-align:center; padding: 10px;">
        <strong>Огноо</strong>
        <span style="color: rgba(0,0,0,0.5)">Date</span>
      </td>
    </tr>
  </table>

  <table class="table" style="width: 100%; margin-top: -1px; background: rgba(0,0,0,0.1);">
    <tr>
      <td class="td">
        <strong>Симатай Монголиа ХХК</strong> 
      </td>
    </tr>
  </table>

  <table class="table" style="width: 100%; margin-top: -1px;">
    <tr>
      <td class="td" width="25%" style="text-align:center; padding: 10px;">
        Хүлээлгэн өгсөн <br>
        <span style="color: rgba(0,0,0,0.5)">Delivered by</span>
      </td>
      <td class="td" width="25%" style="text-align:center; padding: 10px;">
        Төслийн тээврийн мэргэжилтэн
      </td>
      <td class="td" width="25%" style="text-align:center; padding: 10px; position: relative" >
        
      </td>
      <td class="td" width="25%" style="text-align:center; padding: 10px;">
        &nbsp;
      </td>
    </tr>
  </table>

  <table class="table" style="width: 100%; margin-top: -1px; background: rgba(0,0,0,0.1);">
    <tr>
      <td class="td">
        &nbsp
      </td>
    </tr>
  </table>

  <table class="table" style="width: 100%; margin-top: -1px;">
    <tr>
      <td class="td" width="25%" style="text-align:center; padding: 10px;">
        Хүлээн авсан <br>
        <span style="color: rgba(0,0,0,0.5)">Received by</span>
      </td>
      <td class="td" width="25%" style="text-align:center; padding: 10px;">
        &nbsp;
      </td>
      <td class="td" width="25%" style="text-align:center; padding: 10px;" >
        &nbsp;
      </td>
      <td class="td" width="25%" style="text-align:center; padding: 10px;">
        &nbsp;
      </td>
    </tr>
  </table>

  <div>
    <strong>Feedback:</strong>
  </div>


  <div class="flexrow" style="padding: 10px; margin-top: 10px; text-align: center; justify-content: space-around; border-top: 1px solid rgba(0,0,0,0.5);">

    <div class="flexcol" style="text-align: center">
      <span>185-2, Ajilchid street, 3rd khoroo, Khan-Uul District, Ulaanbaatar, Mongolia</span>
      <span>Утас: +976-7600-0099, E-mail: Info@simataimongolia.com, Вэбсайт: www.simataimongolia.com</span>
    </div>        

  </div>


  </div>
</div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>