import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-gaali-act',
  templateUrl: './gaali-act.component.html',
  styleUrls: ['./gaali-act.component.scss'],
})
export class GaaliActComponent implements OnInit {
  public id;
  consignee_name;
  shipping_order_name;
  so_start_date;
  consignee_phone;
  consignee_email;
  pol_country_name;
  pol_city_name;
  final_country_name;
  final_city_name;
  border_name;
  commodity;
  quantity;
  box_weight;
  box_length;
  box_width;
  box_height;
  inquiry_tariff_prices = [];
  rate;
  currency_name;
  profit;
  price;
  invoice_number;

  constructor(
    private data: DataProvider,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Delivery Receipt");
  }

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.spinner.show();
    this.rest.shippingOrderDetail(this.id).subscribe((res) => {
      this.spinner.hide();
      this.consignee_name = res.detail.consignee_name;
      this.shipping_order_name = res.detail.shipping_order_name;
      this.invoice_number = this.shipping_order_name.substring(2);
      this.so_start_date = res.detail.so_start_date;
      this.consignee_phone = res.detail.consignee_phone;
      this.consignee_email = res.detail.consignee_email;
      this.pol_country_name = res.detail.pol_country_name;
      this.pol_city_name = res.detail.pol_city_name;
      this.final_country_name = res.detail.final_country_name;
      this.final_city_name = res.detail.final_city_name;
      this.border_name = res.detail.border_name;
      this.commodity = res.detail.commodity;
      this.quantity = res.detail.quantity;
      this.box_weight = res.detail.box_weight;
      this.box_length = res.detail.box_length;
      this.box_width = res.detail.box_width;
      this.box_height = res.detail.box_height;
      this.inquiry_tariff_prices = res.inquiry_tariff_prices;
      this.rate = res.inquiry_quotation.rate;
      if(res.inquiry_quotation.currency_name == 'MNT'){
        this.currency_name = "₮";
      } else if (res.inquiry_quotation.currency_name == 'USD-MGB'){
        this.currency_name = "$";
      } else if (res.inquiry_quotation.currency_name == 'EURO'){
        this.currency_name = "€";
      } else if (res.inquiry_quotation.currency_name == 'RMB-MGB'){
        this.currency_name = "¥";
      }

      this.profit = res.inquiry_quotation.profit;
      this.price = res.inquiry_quotation.price;
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
}
