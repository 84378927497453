import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.component.html',
  styleUrls: ['./currency-list.component.scss'],
})

export class CurrencyListComponent implements OnInit {
  toastr: any;
  filteredOptions: Observable<string[]>;
  nameFormControl = new FormControl();
  currencys = [];
  currencys_old = [];
  loading = false;
  searchTerm = '';
  consignee: any;
  page = 1;
  filteredItems = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  modalService: any;
  closeResult: string;
  public startDate;
  public endDate;
  constructor(public appService: AppService, public data: DataProvider, public router: Router, public rest: CallapiService,
    private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'currency';
  }
  
  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    this.spinner.show();
    this.rest.currencyAllList().subscribe((res) =>{
      this.spinner.hide();
      this.currencys = res;
      this.filteredItems = this.currencys;
      this.currencys_old = this.currencys;
      this.currentIndex = 1;
      this.pageStart = 1;
      this.pages = 6;
      this.pageNumber = parseInt(
        '' + this.filteredItems.length / this.pageSize
      );
      if (this.filteredItems.length % this.pageSize != 0) {
        this.pageNumber++;
      }
      if (this.pageNumber < this.pages) {
        this.pages = this.pageNumber;
      }
      this.refreshItems();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.currencys = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  clear() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.searchTerm = '';
    this.filteredItems = this.currencys_old;
    this.refreshItems();
  }

  search() {
    let ch = 0;
    if(this.searchTerm) {
      this.filteredItems = this.filteredItems.filter(option => option.name.toLowerCase().includes(this.searchTerm));
    } else {
      ch++;
    }
    if(ch == 1) {
      this.filteredItems = this.currencys_old;
    }
    this.refreshItems();
  }
  
  showCurrency(bool, id: any) {
    if(bool) {
      this.router.navigate(['/currency/add']);
    } else {
      this.router.navigate(['/currency/edit', {id: id}]);
    }
  }

  exportExcel() { 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Currency');
    worksheet.columns = [
      { header: 'Year', key: 'year', width: 15 },
      { header: 'Month', key: 'month', width: 15 },
      { header: 'Name', key: 'name', width: 32 },
      { header: 'Rate', key: 'rate', width: 15 },
      { header: 'Active', key: 'active', width: 15 },
    ];

    for(let i = 0; i < this.currencys_old.length; i++) {
      worksheet.addRow(
        {
          year: this.currencys_old[i].year, 
          month: this.currencys_old[i].month, 
          name: this.currencys_old[i].name,
          rate: this.currencys_old[i].rate,
          active: this.currencys_old[i].vis == 'y' ? 'YES' : 'NO',
        }, 'n'
      );
    }
 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Currency-List.xlsx');
    })
  }
}
