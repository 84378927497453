import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import * as CryptoJS from 'crypto-js';
import { ENCRYPT_SECRET_KEY } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IMAGE_FILE_URL } from '../../../environments/environment';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss'],
})

export class CustomComponent implements OnInit {
  customs = [];
  commodity;
  trans_type_name;
  shipping_order_name;
  cntr_name;
  pol_country_name;
  pol_city_name;
  final_country_name;
  final_city_name;
  box_weight;
  box_length;
  box_width;
  box_height;
  terminal_name;
  ata_border;
  eta_border;
  atd_border;
  ata_destination;
  site_id;
  site_users = [];
  comments;
  cc_started;
  cc_finished;
  id;
  shipping_order_id;
  closeResult = '';
  shipping_order_files = [];
  inquiry_id;
  imageFileUrl = IMAGE_FILE_URL;
  type;

  constructor(public appService: AppService, public data: DataProvider, public router: Router,
    private rest: CallapiService, private toastr: ToastrService,private spinner: NgxSpinnerService,
    private modalService: NgbModal,) {
    data.menuPageTitle = 'custom';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    this.spinner.show();
    this.rest.customsList().subscribe((res) =>{
      this.customs = res.lists;
      this.site_users = res.sites;
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  loadCustom(id) {
    this.commodity = this.customs[id].commodity;
    this.trans_type_name = this.customs[id].trans_type_name;
    this.shipping_order_name = this.customs[id].shipping_order_name;
    this.cntr_name = this.customs[id].cntr_name;
    this.pol_country_name = this.customs[id].pol_country_name;
    this.pol_city_name = this.customs[id].pol_city_name;
    this.final_country_name = this.customs[id].final_country_name;
    this.final_city_name = this.customs[id].final_city_name;
    this.box_weight = this.customs[id].box_weight;
    this.box_length = this.customs[id].box_length;
    this.box_width = this.customs[id].box_width;
    this.box_height = this.customs[id].box_height;
    this.terminal_name = this.customs[id].terminal_name;
    this.ata_border = this.customs[id].ata_border;
    this.eta_border = this.customs[id].eta_border;
    this.atd_border = this.customs[id].atd_border;
    this.ata_destination = this.customs[id].ata_destination;
    this.shipping_order_id = this.customs[id].shipping_order_id;
    this.shipping_order_files = this.customs[id].shipping_order_files;
    this.inquiry_id = this.customs[id].inquiry_id;
    this.type = this.customs[id].type;
    this.id = this.customs[id].id;
    document.getElementById('right-side').style.display = 'block';
    let items = document.getElementsByClassName('item-row');
    for(let i = 0; i < items.length; i++) {
      items[i].classList.remove('active');
    }
    items[id].classList.add('active');
  }

  editCustom() {
    if(this.cc_started == null || this.cc_started == '') {
      this.toastr.error('Алдаа!', 'CC Start огноогоо сонгоно уу!');
      return;
    }
    if(this.cc_finished == null || this.cc_finished == '') {
      this.toastr.error('Алдаа!', 'CC Finished огноогоо сонгоно уу!');
      return;
    }
    if(this.site_id == null || this.site_id == '') {
      this.toastr.error('Алдаа!', 'Та Site Manager ээ сонгоно уу!');
      return;
    }
    if(this.comments == null) {
      this.comments = '';
    }
    this.spinner.show();
    this.rest.editCustom({id: this.id, cc_started: this.cc_started, cc_finished: this.cc_finished, comments: this.comments
    , site_id: this.site_id}).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай шилжүүллээ.');
        if(this.data.userData.permission == 8) {
          const userdata = {
            id: this.data.userData.id,
            lname: this.data.userData.lname,
            fname: this.data.userData.fname,
            permission: this.data.userData.permission,
            email: this.data.userData.email,
            phone: this.data.userData.phone,
            job_date: this.data.userData.job_date,
            image: this.data.userData.image,
            name: this.data.userData.name,
            password: this.data.userData.password,
            position_type: this.data.userData.position_type,
            create_date: this.data.userData.create_date,
            notifications: {
              inquiry: this.data.userData.notifications.inquiry,
              shipping_order: this.data.userData.notifications.shipping_order,
              operation: this.data.userData.notifications.operation,
              custom: this.data.userData.notifications.custom - 1,
              site: this.data.userData.notifications.site,
              invoices: this.data.userData.notifications.invoices
            },
          };
          const encrypt = CryptoJS.AES.encrypt(
            JSON.stringify(userdata),
            ENCRYPT_SECRET_KEY
          ).toString();
          localStorage.setItem('token', encrypt);
        }
        window.location.reload();
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  openAct(id: any) {
    window.open('/custom/act;id=' + id, '_blank');
  }

  inquiryBack(id: any) {
    this.router.navigate(['/inquiry/edit', {id: id}]);
  }

  openImage(content, id) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
