import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { isBuffer } from 'util';
import { CalendarCreator } from "../calendar/calendarCreator.service";
import { Day } from "../calendar/day.model";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public sales_lists = [];
  public tariff_lists = [];
  public custom_lists = [];
  public site_lists = [];
  public invoice_lists = [];
  prev_year_profit;
  prev_month_profit;
  month_profit;
  so_schedule = [];

  public monthDays: Day[];
  public monthNumber: number;
  public monthNumber2: number;
  public year: number;
  public weekDaysName = [];
  now_day = new Date().getDate();
  now_month2 = new Date().getMonth() + 1;
  now_month = '';
  schedule_date;
  closeResult: string;
  schedules = [];

  constructor(public data: DataProvider, private router: Router, public appService: AppService, private rest: CallapiService,
    private spinner: NgxSpinnerService,public calendarCreator: CalendarCreator,private modalService: NgbModal,) {
    data.menuPageTitle = 'Home';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    this.setMonthDays(this.calendarCreator.getCurrentMonth());
    this.weekDaysName.push("Дав");
    this.weekDaysName.push("Мяг");
    this.weekDaysName.push("Лха");
    this.weekDaysName.push("Пүр");
    this.weekDaysName.push("Баа");
    this.weekDaysName.push("Бям");
    this.weekDaysName.push("Ням");
    this.now_month = this.calendarCreator.getMonthName(this.now_month2);

    this.spinner.show();
    this.rest.getMain(this.data.userData.permission).subscribe((res) => {
      this.spinner.hide();
      this.sales_lists = res.sales_list;
      this.tariff_lists = res.tariff_list;
      this.custom_lists = res.custom;
      this.site_lists = res.site;
      this.invoice_lists = res.invoices;
      if(this.data.userData.permission == 1 || this.data.userData.permission == 2 || this.data.userData.permission == 6) {
        this.prev_year_profit = res.prev_year_profit;
        this.prev_month_profit = res.prev_month_profit;
        this.month_profit = res.month_profit;
        this.so_schedule = res.so_schedule;
        for(let j = 0; j < this.monthDays.length; j++) {
          let cc = 0;
          for(let i = 0; i < this.so_schedule.length; i++) {
            if(this.monthDays[j].year == parseInt(this.so_schedule[i].so_start_date.substring(0,4)) &&
            this.monthDays[j].month == this.calendarCreator.getMonthName(parseInt(this.so_schedule[i].so_start_date.substring(5,7))) &&
            this.monthDays[j].number == parseInt(this.so_schedule[i].so_start_date.substring(8,10))) {
              cc = cc + this.so_schedule[i].quantity;
            }
          }
          this.monthDays[j].quantity = cc;
        }
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  activaTab(tab) {
    let navs = document.getElementsByClassName('scarytab');
    for(let i = 0; i < navs.length; i++) {
      navs[i].classList.remove('active');
      navs[i].setAttribute('aria-selected', 'false');
    }
    navs[tab].classList.add('active');
    navs[tab].setAttribute('aria-selected', 'true');
    let tabs = document.getElementsByClassName('tab-pane');
    for(let j = 0; j < tabs.length; j++) {
      tabs[j].classList.remove('show');
      tabs[j].classList.remove('active');
    }
    tabs[tab].classList.add('show');
    tabs[tab].classList.add('active');
  }

  onNextMonth(): void {
    this.monthNumber++;
    if (this.monthNumber == 12) {
      this.monthNumber = 0;
      this.year++;
    }
    this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year));
    this.getSchedule(this.year, this.monthNumber + 1);
  }

  onPreviousMonth() : void{
    this.monthNumber--;
    if (this.monthNumber < 0) {
      this.monthNumber = 11;
      this.year--;
    }
    this.setMonthDays(this.calendarCreator.getMonth(this.monthNumber, this.year));
    this.getSchedule(this.year, this.monthNumber + 1);
  }

  setMonthDays(days: Day[]): void {
    this.monthDays = days;
    this.monthNumber = this.monthDays[0].monthIndex;
    this.monthNumber2 = this.monthDays[0].monthIndex;
    if(this.monthNumber2 == 12) {
      this.monthNumber2 = 1;
    } else {
      this.monthNumber2 = this.monthNumber2 + 1;
    }    
    this.year = this.monthDays[0].year;
  }

  getSchedule(year, month) {
    this.spinner.show();
    this.rest.getSchedule(year, month).subscribe((res) => {
      this.spinner.hide();
      this.so_schedule = res.so_schedule;
      for(let j = 0; j < this.monthDays.length; j++) {
        let cc = 0;
        for(let i = 0; i < this.so_schedule.length; i++) {
          if(this.monthDays[j].year == parseInt(this.so_schedule[i].so_start_date.substring(0,4)) &&
          this.monthDays[j].month == this.calendarCreator.getMonthName(parseInt(this.so_schedule[i].so_start_date.substring(5,7))) &&
          this.monthDays[j].number == parseInt(this.so_schedule[i].so_start_date.substring(8,10))) {
            cc = cc + this.so_schedule[i].quantity;
          }
        }
        this.monthDays[j].quantity = cc;
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  openUsers(content, year, month, number) {
    this.schedules = [];
    for(let i = 0; i < this.so_schedule.length; i++) {
      if(year == parseInt(this.so_schedule[i].so_start_date.substring(0,4)) &&
      month == this.calendarCreator.getMonthName(parseInt(this.so_schedule[i].so_start_date.substring(5,7))) &&
      number == parseInt(this.so_schedule[i].so_start_date.substring(8,10))) {
        this.schedule_date = year + '-' + ("0" + (parseInt(this.so_schedule[i].so_start_date.substring(5,7)))).slice(-2) + '-' + 
        ("0" + (number)).slice(-2);
        this.schedules.push({name: this.so_schedule[i].sales_name, quantity: this.so_schedule[i].quantity});
      }
    }
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });
  }

  close() {
    this.modalService.dismissAll();
  }
}
