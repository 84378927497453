<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 *ngIf="isNew">Шинэ agent</h1>
        <h1 *ngIf="!isNew">Agent засах</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <button
            type="button"
            class="btn btn-tool back-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Буцах"
            (click)="backList()"
          >
            <i class="fa fa-arrow-circle-left"></i>
          </button>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Мэдээлэл</h3>

        <div class="card-tools">
          
        </div>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" name="name" placeholder="Нэр" [(ngModel)]="name">
        </div>
        <div class="form-group">
          <label for="credit">Credit</label>
          <input type="number" class="form-control" name="credit" placeholder="Credit" [(ngModel)]="credit">
        </div>
      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <button type="button" class="btn btn-primary" *ngIf="!isNew" (click)="edit()">Шинэчлэх</button>
        <button type="button" class="btn btn-primary" *ngIf="isNew" (click)="save()">Хадгалах</button>
      </div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>