import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from './views/blank/blank.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { InquiryListComponent } from './views/inquiry-list/inquiry-list.component';
import { InquiryComponent } from './views/inquiry/inquiry.component';
import { ShippingOrderComponent  } from './views/shipping-order/shipping-order.component';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserDropdownMenuComponent } from './pages/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DataProvider } from '../providers/data/data';
import { CallapiService } from '../providers/rest/callapi.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ShippingOrderEditComponent } from './views/shipping-order-edit/shipping-order-edit.component';
import { OperationComponent } from './views/operation/operation.component';
import { OperationEditComponent } from './views/operation-edit/operation-edit.component';
import { InquiryDownloadComponent } from './views/inquiry-download/inquiry-download.component';
import { ClosedInquiryComponent } from './views/closed-inquiry/closed-inquiry.component';
import { SOCSimataiComponent } from './views/soc/soc.component';
import { CustomComponent } from './views/custom/custom.component';
import { FinishedShipmentsComponent } from './views/finished-shipments/finished-shipments.component';
import { SideManagerComponent } from './views/side-manager/side-manager.component';
import { FinanceComponent } from './views/finance/finance.component';
import { InvoiceComponent } from './views/invoice/invoice.component';
import { HttpClientModule } from '@angular/common/http';
import { OperationReportComponent } from './views/operation-report/operation-report.component';
import { ShippingOrderPrintComponent } from './views/shipping-order-print/shipping-order-print.component';
import { UsersComponent } from './views/users/users.component';
import { UserComponent } from './views/user/user.component';
import { InvoicePrintComponent } from './views/invoice-print/invoice-print.component';
import { CurrencyListComponent } from './views/currency-list/currency-list.component';
import { CurrencyComponent } from './views/currency/currency.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReceivableComponent } from './views/receivable/receivable.component';
import { PayableComponent } from './views/payable/payable.component';
import { ReportsComponent } from './views/reports/reports.component';
import { AgentComponent } from './views/agent/agent.component';
import { AgentsComponent } from './views/agents/agents.component';
import { ConsigneesComponent } from './views/consignees/consignees.component';
import { ConsigneeComponent } from './views/consignee/consignee.component';
import { SuppliersComponent } from './views/suppliers/suppliers.component';
import { SupplierComponent } from './views/supplier/supplier.component';

import { CalendarCreator } from './views/calendar/calendarCreator.service';
import { UserIdleModule } from 'angular-user-idle';
import { ReportUserComponent } from './views/report-user/report-user.component';
import { GaaliActComponent } from './views/gaali-act/gaali-act.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    AppButtonComponent,
    UserDropdownMenuComponent,
    InquiryListComponent,
    InquiryComponent,
    ShippingOrderComponent,
    ShippingOrderEditComponent,
    InquiryDownloadComponent,
    OperationComponent,
    OperationEditComponent,
    ClosedInquiryComponent,
    SOCSimataiComponent,
    CustomComponent,
    SideManagerComponent,
    FinishedShipmentsComponent,
    FinanceComponent,
    InvoiceComponent,
    OperationReportComponent,
    ShippingOrderPrintComponent,
    UsersComponent,
    UserComponent,
    InvoicePrintComponent,
    CurrencyListComponent,
    CurrencyComponent,
    ReceivableComponent,
    PayableComponent,
    ReportsComponent,
    ReportUserComponent,
    AgentComponent,
    AgentsComponent,
    ConsigneesComponent,
    ConsigneeComponent,
    SuppliersComponent,
    SupplierComponent,
    GaaliActComponent
  ],
  imports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbModule,
    CommonModule,
    FormsModule,
    NgbCarouselModule,
    NgxSpinnerModule,
    UserIdleModule.forRoot({idle: 60*1*60, timeout: 60, ping: 120}),
  ],
  providers: [DataProvider, CallapiService, CalendarCreator],
  bootstrap: [AppComponent],
})
export class AppModule {}
