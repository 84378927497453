import { Component, OnInit } from '@angular/core';
import { VERSION } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public appVersion = VERSION;
  public date = new Date().getFullYear();

  constructor() {}

  ngOnInit() {}
}
