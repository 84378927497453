<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="containeriu">
    <div class="row mb-2">
      <div class="col-sm-3">
        <h5 class="title-text" *ngIf="isNew">New inquiry</h5>
        <h5 class="title-text" *ngIf="!isNew">Edit inquiry</h5>
      </div>
      <div class="col-sm-9">
        <ul class="nav nav-tabs" id="myTab" role="tablist" style="width: calc(100% - 50px);display: inline-flex;">
          <li class="nav-item">
            <a class="nav-link scarytab active" id="main-tab" data-toggle="tab" href="{{current_url}}#main" (click)="activaTab('main')" role="tab" aria-controls="main" aria-selected="true">Main</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scarytab" id="dimension-tab" data-toggle="tab" href="{{current_url}}#dimension" (click)="activaTab('dimension')" role="tab" aria-controls="dimension" aria-selected="false">Dimension</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scarytab" id="dest-tab" data-toggle="tab" href="{{current_url}}#dest" (click)="activaTab('dest')" role="tab" aria-controls="dest" aria-selected="false">Destination</a>
          </li>
          <li class="nav-item" *ngIf="!isNew">
            <a class="nav-link scarytab" id="price-tab" data-toggle="tab" href="{{current_url}}#price" (click)="activaTab('price')" role="tab" aria-controls="price" aria-selected="false">Тээврийн үнэ</a>
          </li>
          <li class="nav-item" *ngIf="!isNew && (data.userData.permission == 4 || data.userData.permission == 1 || data.userData.permission == 2 
          || data.userData.permission == 6) && inquiry_tariff_prices_first.length > 0">
            <a class="nav-link scarytab" id="quo-tab" data-toggle="tab" href="{{current_url}}#quo" (click)="activaTab('quo')" role="tab" aria-controls="quo" aria-selected="false">Quotation/Expence</a>
          </li>
          <li class="nav-item">
            <a class="nav-link scarytab" id="rest-tab" data-toggle="tab" href="{{current_url}}#rest" (click)="activaTab('rest')" role="tab" aria-controls="rest" aria-selected="false">Rest</a>
          </li>
        </ul>
        <ol class="breadcrumb float-right" style="display: inline-flex;">
          <button
            type="button"
            class="btn btn-tool back-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Буцах"
            (click)="backList()"
          >
            <i class="fa fa-arrow-circle-left"></i>
          </button>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="tab-content" id="myTabContent">
    <div class="container-fluid tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
      <mat-accordion class="card">
        <mat-expansion-panel  [expanded]="true" >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="card-title">Main Information</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="card-body">
            <!-- <div class="form-group">
                <input type="text" class="form-control" id="inquiryName" placeholder="Inquiry name">
              </div> -->
            <mat-form-field class="example-full-width text">
              <mat-label>Inquiry name</mat-label>
              <input
                id="name"
                type="text"
                matInput
                [formControl]="nameFormControl"
                [errorStateMatcher]="matcher"
                [(ngModel)]="name" 
                [disabled]="!isNew && data.userData.permission == 5"
                (change)="onChange('name', $event)"
              />
              <mat-error *ngIf="nameFormControl.hasError('required')">
                Гүйцэд бөглөнө үү
              </mat-error>
            </mat-form-field>
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label >Consignee</mat-label>
                  <input type="text"
                        matInput
                        [formControl]="Assignee"
                        [matAutocomplete]="auto1"
                        (change)="_filterCons()"
                        id="consignee"
                          >
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="onConsigneeSelected($event.option)">
                    <mat-option *ngFor="let option of consignees" [value]="option.name">
                      ({{option.credit}}) {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Commodity</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="Commodity"
                    [errorStateMatcher]="matcher"
                    [(ngModel)]="commodity"
                    (change)="onChange('commodity', $event)"
                  />
                  <mat-error *ngIf="Commodity.hasError('required')">
                    Гүйцэд бөглөнө үү
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
    
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width  text">
                  <mat-label>Division</mat-label>
                  <mat-select [(ngModel)]="division_id"  [formControl]="divisionCon" required (selectionChange)="onChange('division_id', $event)">
                    <mat-option
                      *ngFor="let division of Division"
                      [value]="division.id"
                    >
                      {{ division.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Condiotion</mat-label>
                  <mat-select  [(ngModel)]="condition_id" [formControl]="condiotionCon" required (selectionChange)="onChange('condition_id', $event)">
                    <mat-option
                      *ngFor="let condiotion of Condiotion"
                      [value]="condiotion.id"
                    >
                      {{ condiotion.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
    
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Transportation mode</mat-label>
                  <mat-select  [(ngModel)]="trans_mode_id" (selectionChange) = "transportChange()" [formControl]="transportMode" required
                  (selectionChange)="onChange('trans_mode_id', $event)">
                    <mat-option
                      *ngFor="let data of Transportation_mode"
                      [value]="data.id"
                    >
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
    
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Transportation type</mat-label>
                  <mat-select [(ngModel)]="trans_type_id"  [formControl]="transportType" required (selectionChange)="onChange('trans_type_id', $event)">
                    <mat-option
                      *ngFor="let data of Transportation_type"
                      [value]="data.id"
                    >
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
    
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Incoterms</mat-label>
                  <mat-select  [(ngModel)]="incoterm_id"  [formControl]="incotermsCon" required (selectionChange)="onChange('incoterm_id', $event)">
                    <mat-option *ngFor="let data of incoterms" [value]="data.id">
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Incoterm address</mat-label>
                  <input matInput value="" type="text"  [(ngModel)]="incoterm_address" (change)="onChange('incoterm_address', $event)" />
                </mat-form-field>
              </div>
            </div>
    
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Inquiry Type</mat-label>
                  <mat-select  [(ngModel)]="inquiry_type_id"   [formControl]="inquiryType" required (selectionChange)="onChange('inquiry_type_id', $event)">
                    <mat-option
                      *ngFor="let data of inquiry_type"
                      [value]="data.id"
                    >
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text" style="display: none;">
                  <mat-label>Supplier</mat-label>
                  <input type="text"
                        matInput
                        [formControl]="Supplier"
                        [matAutocomplete]="auto2"
                        (change)="_filterSupp()"
                        id="supplier"
                        >
  
                  <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="onSupplierSelected($event.option)">
                    <mat-option *ngFor="let option of suppliers" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-3" style="display: none;" id="gross_section">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Gross weight</mat-label>
                  <input matInput value="" type="number"  [(ngModel)]="gross_weight" (change)="onChange('gross_weight', $event)" />
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>CBM</mat-label>
                  <input matInput value="" type="number"  [(ngModel)]="gross_cbm" (change)="onChange('gross_cbm', $event)" />
                </mat-form-field>
              </div>
            </div>
    
            <!-- <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Гаалийн мэдүүлгийн дугаар</mat-label>
                  <input matInput value="" type="text" />
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Гэрээний дугаар</mat-label>
                  <input matInput value="" type="text" />
                </mat-form-field>
              </div>
            </div> -->   
            
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="container-fluid tab-pane fade" id="dimension" role="tabpanel" aria-labelledby="dimension-tab">
      <div class="card d-flex p-3">
        <div class="card-header">
          <h3 class="card-title">Ачааны төрөл</h3>
        </div>
        <div class="card-body">
          <mat-radio-group  [(ngModel)]="type" (change) = "typeChange()">
            <mat-radio-button value="box" class="pr-3 text">Box</mat-radio-button>
            <mat-radio-button value="fullPlane" class="pr-3 text">Бүтэн онгоц</mat-radio-button>
            <mat-radio-button value="fullWagon" class="pr-3 text"> Бүтэн вагон</mat-radio-button>
            <mat-radio-button value="fullTruck" class="pr-3 text"> Бүтэн машин</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="card" id="box">
        <!-- <form class="example-form" [formGroup]="userTable"> -->
            <div class="card-header">
              <h3 class="card-title">Weight & Dimension</h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Нэмэх"
                  (click)="onAddRow()"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <mat-radio-group  [(ngModel)]="box_dup" (change) = "boxDupChange()">
                <mat-radio-button value="1" checked class="pr-3 text" *ngIf="box_dup == 1">Давхарлаж болно</mat-radio-button>
                <mat-radio-button value="1" class="pr-3 text" *ngIf="box_dup != 1">Давхарлаж болно</mat-radio-button>
                <mat-radio-button value="0" checked class="pr-3 text" *ngIf="box_dup == 0">Давхарлаж болохгүй</mat-radio-button>
                <mat-radio-button value="0" class="pr-3 text" *ngIf="box_dup != 0">Давхарлаж болохгүй</mat-radio-button>
              </mat-radio-group>
              <mat-form-field class="example-full-width text">
                <mat-label>Хэмжээс</mat-label>
                <mat-select [(ngModel)]="hemjees" (selectionChange)="onChange('hemjees', $event)">
                  <mat-option value="mm">MM</mat-option>
                  <mat-option value="cm">CM</mat-option>
                  <mat-option value="m">M</mat-option>
                  <mat-option value="inch">INCH</mat-option>
                </mat-select>
              </mat-form-field>
              <table>
                <tbody>
                  <tr *ngFor="let row of addForm.get('rows')?.controls;let index = index;">
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label>Lenght/{{hemjees}}/</mat-label>
                        <input matInput [formControl]="row.get('box_length')" value="" type="number" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                    <mat-form-field class="text">
                      <mat-label>Width/{{hemjees}}/</mat-label>
                      <input matInput value="" type="number" [formControl]="row.get('box_width')" />
                    </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label>Height/{{hemjees}}/</mat-label>
                        <input  matInput value="" type="number" class="fullwid"  [formControl]="row.get('box_height')" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label>Weight/kg/</mat-label>
                        <input  matInput value="" type="number"  [formControl]="row.get('box_weight')" (change)="totalWeight(index)" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label>Total Weight/kg/</mat-label>
                        <input matInput value="" type="number" [formControl]="row.get('box_total_weight')" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label>Volume(AIR)</mat-label>
                        <input  matInput value="" type="number" [formControl]="row.get('volume_air')" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label>Total Volume(AIR)</mat-label>
                        <input matInput value="" type="number" [formControl]="row.get('total_volume_air')" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label> CBM</mat-label>
                        <input  matInput value="" type="number" [formControl]="row.get('cbm')" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label>Total CBM</mat-label>
                        <input matInput value="" type="number" [formControl]="row.get('total_cbm')" />
                      </mat-form-field>
                    </td>

                    <td class="pr-3">
                      <mat-form-field class="text">
                        <mat-label> Quantity</mat-label>
                        <input  matInput value="" type="number" [formControl]="row.get('quantity')" (change)="totalWeight(index)" />
                      </mat-form-field>
                    </td>
                    <input matInput value="box" type="text" [formControl]="row.get('type')" style="display: none;"/>
                    <td>
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-widget="collapse"
                          data-toggle="tooltip"
                          title="Add"
                          (click)="onRemoveRow(index)"
                        >
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{total_weight}}</td>
                    <td>-</td>
                    <td>{{total_volume}}</td>
                    <td>-</td>
                    <td>{{total_cbm}}</td>
                    <td>{{total_quantity}}</td>
                    <td>-</td>
                  </tr>
                </tfoot>
              </table>
            </div>
        <!-- </form> -->
      </div>
      <div class="card" id="full" style="display: none;">
        <div class="card-header">
          <h3 class="card-title" id="fullname">Бүтэн вагон</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class=" col-12">
              <p>Ачааны сертификат</p>
              <div class="input-group">
                <div class="custom-file" *ngIf="isNew">
                  <input type="file" (change)="setFileName(fileInput)" #fileInput class="custom-file-input" id="file" name="file" accept="application/pdf">
                  <label class="custom-file-label" for="file" id="img-label">Файл оруулах</label>
                </div>
                <div class="custom-file" *ngIf="!isNew">
                  <a href="{{imageFileUrl}}/files/{{box_file}}" target="_blank">{{box_file}}</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- Default box -->
    <div class="container-fluid tab-pane fade" id="dest" role="tabpanel" aria-labelledby="dest-tab">

      <mat-accordion class="card">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="card-title">Route Information</h3>
            </mat-panel-title>

          </mat-expansion-panel-header>
          <div class="card-body">
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>POL country</mat-label>
                  <input type="text"
                        aria-label="Number"
                        matInput
                        [formControl]="PolCountry"
                        [matAutocomplete]="auto3"
                        (change) ="_filterCountry()"
                        id="country"
                        >
                  <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" (optionSelected)="onCountrySelected($event.option)">
                    <mat-option *ngFor="let option of polCountry" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex">
                  <mat-form-field class="example-full-width text">
                    <mat-label>POL city</mat-label>
                    <input type="text"
                          aria-label="Number"
                          matInput
                          [formControl]="PolCity"
                          [matAutocomplete]="auto5"
                          (change) ="_filterCity()"
                          id="city"
                          >      
                    <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete" (optionSelected)="onCitySelected($event.option)">
                      <mat-option *ngFor="let option of polCity" [value]="option.name">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!-- <button
                    type="button"
                    class="btn btn-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Add"
                  >
                    <i class="fa fa-plus-circle grey-add-button"></i>
                  </button> -->
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>POL address /optional/</mat-label>
                  <input matInput value="" type="text"  [(ngModel)]="pol_address" (change)="onChange('pol_address', $event)" />
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Border</mat-label>
                  <mat-select [(ngModel)]="border_id" [formControl]="borderReq" required (selectionChange)="onChange('border_id', $event)" >
                    <mat-option *ngFor="let data of Border" [value]="data.id">
                      {{ data.name }}
                    </mat-option>
                  </mat-select> 
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>Final country</mat-label>
                  <input type="text"
                        aria-label="Number"
                        matInput
                        [formControl]="FinalCountry"
                        [matAutocomplete]="auto4"
                        (change) ="_finalCountry()"
                        id="finalCountry"
                        >  
                  <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"  (optionSelected)="onFinalCountrySelected($event.option)">
                    <mat-option *ngFor="let option of finalCountry" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex">
                  <mat-form-field class="example-full-width text">
                    <mat-label>Final city</mat-label>
                    <input type="text"
                          aria-label="Number"
                          matInput
                          [formControl]="FinalCity"
                          [matAutocomplete]="final"
                          (change) ="_finalCity()"
                          id="finalCity"
                          >       
                    <mat-autocomplete autoActiveFirstOption #final="matAutocomplete" (optionSelected)="onFinalCitySelected($event.option)">
                      <mat-option *ngFor="let option of finalCity" [value]="option.name">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!-- <button
                    type="button"
                    class="btn btn-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Add"
                  >
                    <i class="fa fa-plus-circle grey-add-button"></i>
                  </button> -->
                </div>
              </div>
            </div>
    
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label class="text"
                    >Final destination address /optional/</mat-label
                  >
                  <input matInput value="" type="text"  [(ngModel)]="final_address" (change)="onChange('final_address', $event)" />
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field class="example-full-width text">
                  <mat-label>VIA</mat-label>
                  <mat-select  [(ngModel)]="via_id" (selectionChange)="onChange('via_id', $event)" >
                    <mat-option *ngFor="let data of VIA" [value]="data.id">
                      {{ data.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="container-fluid tab-pane fade" id="price" role="tabpanel" aria-labelledby="price-tab" *ngIf="!isNew">
      <!-- <form class="example-form" [formGroup]="price"> -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Тээврийн үнэ</h3>
            <div class="card-tools">
              <button
                type="button"
                class="btn btn-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Add"
                (click)="openPrice(priceModal)"
              >
                <i class="fa fa-plus-circle"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <label for="times">Transit Time</label>
            <input class="form-control" value="" type="text" [(ngModel)]="quotation_transit_time" (change)="onChange('quotation_transit_time', $event)" />
            <hr>
            <table class="example-full-width">
              <tbody>             
                  <tr *ngFor="let row of price.get('priceRows')?.controls;let i = index;" [ngStyle]="{'background-color': new_tariff_prices.includes(i + 1) ? '#d3d3d3' : null }">
                    <td class="pr-3">
                      <mat-form-field class="example-full-width text">
                        <mat-label>Name</mat-label>
                        <input matInput type="text" [formControl]="row.get('name')" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="example-full-width minWid">
                        <mat-label>Currency</mat-label>
                        <mat-select  [formControl]="row.get('currency')" (selectionChange)="changeTraceCurrency(i)" >
                          <mat-option
                            *ngFor="let cur of Currency"
                            [value]="cur.id"
                          >
                            {{ cur.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="example-full-width text">
                        <mat-label>Price</mat-label>
                        <input matInput value="" type="number" class="fullwid" [formControl]="row.get('price')" (change)="transPrice(i)" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3" style="width: 60px;">
                      <mat-form-field class="example-full-width text">
                        <mat-label>Rate</mat-label>
                        <input matInput value="" type="number" [formControl]="row.get('rate')" />
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="example-full-width text">
                        <mat-label>Amount</mat-label>
                        <input matInput value="" type="number" [formControl]="row.get('amount')"/>
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-form-field class="example-full-width minWid1 text">
                        <mat-label>Agent</mat-label>
                        <mat-select [formControl]="row.get('agent_id')">
                          <mat-option
                            *ngFor="let data of Agent"
                            [value]="data.id"
                          >
                          ({{data.credit}}) {{ data.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td class="pr-3">
                      <mat-checkbox class="example-full-width text" [formControl]="row.get('is_paid')"
                        >Төлөлт</mat-checkbox
                      >
                    </td>
                    <td class="pr-3">
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-widget="collapse"
                          data-toggle="tooltip"
                          title="Delete"
                          (click)="deletePrice(i)"
                          *ngIf="(row.controls.is_paid.value == null || row.controls.is_paid.value == '') && !new_tariff_prices.includes(i + 1)
                          && price.get('priceRows')?.controls.length - 1 > i"
                        >
                          <i class="far fa-trash-alt"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-widget="collapse"
                          data-toggle="tooltip"
                          title="Add"
                          (click)="addPriceOne(i)"
                          *ngIf="!new_tariff_prices_copy.includes(i) && price.get('priceRows')?.controls.length - 1 > i"
                        >
                          <i class="fa fa-plus-circle"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="text" style="color: red;font-weight: 700">Дүн</td>
                  <td class="text" style="color: red;font-weight: 700">{{trace_price_china | currency : " "}} RMB</td>
                  <td class="text" style="color: red;font-weight: 700">{{trace_price_usd | currency : " "}} USD</td>
                  <td class="text" style="color: red;font-weight: 700" colspan="2">{{trace_price_mnt | currency : " "}} MNT</td>
                  <!-- <td class="text"></td> -->
                  <td class="text" style="font-weight: 700 !important;
                  color: #26a65b !important;">{{trace_price_confirmed | currency : ' '}} MNT</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      <!-- </form> -->
    </div>
    <div class="tab-pane fade" id="quo" role="tabpanel" aria-labelledby="quo-tab" *ngIf="!isNew && (data.userData.permission == 4 || data.userData.permission == 1 || data.userData.permission == 2 
    || data.userData.permission == 6) && inquiry_tariff_prices_first.length > 0">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title" *ngIf="trace_price_mnt == 0">Quotation</h3>
            <h3 class="card-title" *ngIf="trace_price_mnt > 0">Quotation <span style="color: red;font-weight: bold;">({{trace_price_mnt | currency : ' '}} MNT, {{trace_price_usd | currency : ' '}} USD
              {{trace_price_china | currency : ' '}} RMB)</span></h3>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <mat-form-field class="example-full-width minWid1 pr-3 text">
                <mat-label>Currency</mat-label>
                <mat-select [(ngModel)]="quotation_currency_id" (selectionChange)="changeQuoCurrency()">
                  <mat-option *ngFor="let data of Currency" [value]="data.id">
                    {{ data.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="example-full-width pr-3 text">
                <mat-label>Price</mat-label>
                <input matInput value="" type="number" [(ngModel)]="quotation_price" (change)="changeQuoPrice()" />
                <span style="color: red;" *ngIf="tariff_type != ''" title="Өмнөх мөнгөн дүн">{{quotations[quotations.length - 1].price}}</span>
              </mat-form-field>

              <mat-form-field class="example-full-width pr-3 text">
                <mat-label>Rate</mat-label>
                <input matInput value="1" type="number" disabled="true" [(ngModel)]="quotation_rate" />
              </mat-form-field>

              <mat-form-field class="example-full-width pr-3 text">
                <mat-label>Profit</mat-label>
                <input matInput value="0.00" type="number" disabled="true" [(ngModel)]="quotation_profit" />
              </mat-form-field>

              <!-- <mat-form-field class="example-full-width pr-3 text">
                <mat-label>Transit Time</mat-label>
                <input matInput value="" type="text" [(ngModel)]="quotation_transit_time" disabled="true" />
              </mat-form-field> -->
            </div>
            <div class="d-flex">
              <div class="box">
                <p id="Price-offer" class="tit-text">{{ PriceOffer | currency : " " }}</p>
                <p>ҮНИЙН САНАЛ ТӨГРӨГӨӨР</p>
              </div>
              <div class="box">
                <p id="ProfitMNT" class="tit-text">{{ ProfitMNT | currency : " " }} ₮</p>
                <p>АШИГ MNT</p>
              </div>
              <div class="box">
                <p id="ProfitUSD" class="tit-text1">{{ ProfitUSD | currency : " " }} $</p>
                <p>АШИГ USD</p>
              </div>
              <div class="box">
                <p id="ProfitRMB" class="tit-text1">{{ ProfitRMB | currency : " " }} ¥</p>
                <p>АШИГ RMB</p>
              </div>
              <div class="box">
                <p id="ProfitEURO" class="tit-text1">{{ ProfitEURO | currency : " " }} €</p>
                <p>АШИГ EURO</p>
              </div>
              <div class="box">
                <p id="pure-profit" class="tit-text1">{{ PureProfit | currency : " " }} ₮</p>
                <p>ЦЭВЭР АШИГ MNT</p>
              </div>
              <div>
                <div>
                  <mat-slide-toggle [checked]="is_vat" [(ngModel)]="is_vat"
                      (change)="onChange('is_vat', $event)" *ngIf="isNew">VAT</mat-slide-toggle>
                  <mat-slide-toggle [checked]="is_vat" [(ngModel)]="is_vat" (change)="changeQuoPrice()"
                      *ngIf="!isNew">VAT</mat-slide-toggle>
                </div>
                <div>
                  <mat-slide-toggle [checked]="back_to_tariff" [(ngModel)]="back_to_tariff">TO TARIFF</mat-slide-toggle>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex" *ngFor="let quo of quotations; let i = index;">
              <div *ngIf="quotations.length - 1 > i">
                <p>{{quo.quoted_date}}</p>
                <p>{{quo.currency_name}}</p>
                <p>{{quo.rate}}</p>
                <p>{{quo.profit}}</p>
                <p>{{quo.transit_time}}</p>
              </div>
            </div> -->
            <div *ngFor="let quo of quotations; let i = index;" class="pt-3">
              <div *ngIf="quotations.length - 1 > i" class="d-flex qtext justify-content-between">
                <p class="pr-1">{{quo.quoted_date}}</p>
                <p class="pr-1">{{quo.currency_name}}</p>
                <p class="pr-1">{{quo.rate}}</p>
                <p class="pr-1">{{quo.profit}}</p>
                <p class="pr-1">{{quo.transit_time}}</p>
                <p>	→</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" *ngIf="!isNew && (data.userData.permission == 4 || data.userData.permission == 1 || data.userData.permission == 2 
      || data.userData.permission == 6) && inquiry_tariff_prices_first.length > 0">
      <!-- <form class="example-form" [formGroup]="expenses">       -->
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Expenses</h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Add"
                  (click)="addExpenses()"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="example-full-width">
                <tbody>
                    <tr  *ngFor="let row of expenses.get('expensesRow')?.controls;let index = index;">
                      <td class="pr-3">
                        <mat-form-field class="example-full-width text">
                          <mat-label>Name</mat-label>
                          <input matInput value="" type="text" [formControl]="row.get('name')"/>
                        </mat-form-field>
                      </td>
                      <td class="pr-3">
                        <mat-form-field class="example-full-width text">
                          <mat-label>English Name</mat-label>
                          <input matInput value="" type="text" [formControl]="row.get('engname')" />
                        </mat-form-field>
                      </td>
                      <td class="pr-3" style="width: 60px;">
                        <mat-form-field class="example-full-width text">
                          <mat-label>Quantity</mat-label>
                          <input matInput value="" type="number" [formControl]="row.get('quantity')" max="1" min="1" />
                        </mat-form-field>
                      </td>
                      <td class="pr-3" style="width: 70px;">
                        <mat-form-field class="example-full-width text">
                          <mat-label>Persentage</mat-label>
                          <input matInput value="" type="number" [formControl]="row.get('percentage')" (change)="expensePercent(index)" />
                        </mat-form-field>
                      </td>
                      <td class="pr-3" style="width: 90px;">
                        <mat-form-field class="example-full-width text">
                          <mat-label> {{expense_rate_name}}</mat-label>
                          <input matInput value="" type="number" [formControl]="row.get('rate')" />
                        </mat-form-field>
                      </td>
                      <td class="pr-3">
                        <mat-form-field class="example-full-width text">
                          <mat-label>Amount</mat-label>
                          <input matInput value="" type="number" [formControl]="row.get('price')" />
                        </mat-form-field>
                      </td>
                      <td class="pr-3">
                        <mat-form-field class="example-full-width text">
                          <mat-label>VAT</mat-label>
                          <input matInput value="" type="number" [formControl]="row.get('vat')" (change)="expenseVat(index)" />
                        </mat-form-field>
                      </td>
                      <td class="pr-3">
                        <mat-form-field class="example-full-width text">
                          <mat-label>Total Amount</mat-label>
                          <input matInput value="" type="number" [formControl]="row.get('total_amount')" />
                        </mat-form-field>
                      </td>
                      <td class="pr-3">
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-widget="collapse"
                            data-toggle="tooltip"
                            title="Delete"
                            (click)="deleteExpenses(index)"
                          >
                            <i class="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                </tbody>
                <tfoot>
                  <tr>                
                    <td class="text">{{expense_total_name}}</td>
                    <td class="text"></td>
                    <td class="text">{{expences_quantity}}</td>
                    <td class="text">-</td>
                    <td class="text">-</td>
                    <td class="text">{{expences_price_cur | currency : " "}}</td>
                    <td class="text">{{expences_vat_cur | currency : " "}}</td>
                    <td class="text">{{expences_total_cur | currency : " "}}</td>
                  </tr>
                  <tr>                
                    <td class="text">Нийт зардлын дүн /MNT/</td>
                    <td class="text"></td>
                    <td class="text">{{expences_quantity}}</td>
                    <td class="text">{{expences_percent}}</td>
                    <td class="text">{{quotation_rate}}</td>
                    <td class="text">{{expences_price | currency : " "}}</td>
                    <td class="text">{{expences_vat | currency : " "}}</td>
                    <td class="text">{{expences_total | currency : " "}}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>      
        <!-- </form> -->
      </div>
    </div>
    <div class="container-fluid tab-pane fade" id="rest" role="tabpanel" aria-labelledby="rest-tab">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Rest information</h3>
        </div>
        <div class="card-body">
          <mat-form-field class="example-full-width" *ngIf="isNew">
            <mat-label>Comment/Sales</mat-label>
            <textarea matInput class="textHeight" [(ngModel)]="remark_comment" (change)="onChange('remark_comment', $event)"> </textarea>
          </mat-form-field>
          <div class="row mt-3" *ngIf="!isNew">
            <div class="col-md-6 col-12">
              <mat-form-field class="example-full-width text">
                <mat-label>Comment/Sales</mat-label>
                <textarea matInput class="textHeight" [(ngModel)]="remark_comment"></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
              <mat-form-field class="example-full-width text">
                <mat-label>Comment/Tariff</mat-label>
                <textarea matInput class="textHeight" [(ngModel)]="comment_tariff" (change)="onChange('comment_tariff', $event)"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex">
            <mat-form-field color="accent" class="pr-3">
              <mat-label>Quotation Date</mat-label>
              <input matInput [matDatepicker]="picker1" (click)="picker1.open()" [(ngModel)]="quotation_date" (dateInput)="onChange('quotation_date', $event)" />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field color="accent" class="pr-3">
              <mat-label>Inquiry Date</mat-label>
              <input matInput [matDatepicker]="picker2" (click)="picker2.open()" [(ngModel)]="validaty_date" (dateInput)="onChange('validaty_date', $event)" />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary"></mat-datepicker>
            </mat-form-field>

            <!-- <mat-form-field color="accent" class="pr-3">
              <mat-label>SO Start</mat-label>
              <input matInput [matDatepicker]="picker3" (click)="picker3.open()"  [(ngModel)]="so_start_date" />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker3"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker3 color="primary"></mat-datepicker>
            </mat-form-field> -->
            <mat-form-field class="customWidth pr-3" *ngIf="data.userData.permission == 5 || data.userData.permission == 1 || data.userData.permission == 2
            || data.userData.permission == 6">
              <mat-label>Sales manager</mat-label>
              <mat-select [(ngModel)]="sales_id">
                <mat-option
                  *ngFor="let sales of sales_users"
                  [value]="sales.id"
                >
                {{ sales.lastname }} -н {{ sales.firstname }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="customWidth pr-3" *ngIf="data.userData.permission == 4 || data.userData.permission == 1 || data.userData.permission == 2
            || data.userData.permission == 6">
              <mat-label>Tariff manager</mat-label>
              <mat-select [(ngModel)]="tariff_id" (selectionChange)="onChange('tariff_id', $event)">
                <mat-option
                  *ngFor="let data of tariff_users"
                  [value]="data.id"
                >
                  {{ data.lastname }} -н {{ data.firstname }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button type="button" class="btn btn-primary" *ngIf="isNew" (click)="save(1)">SEND TO TARIFF & NEW</button>
    <button type="button" class="btn btn-primary" *ngIf="isNew" (click)="save(2)">SEND TO TARIFF</button>
    <button type="button" class="btn btn-primary" *ngIf="!isNew && data.userData.permission == 4
     && (op_type == null || op_type != 'finish')" (click)="edit()">SAVE</button>
     <button type="button" class="btn btn-primary" *ngIf="!isNew && data.userData.permission == 5
     && (op_type == null || op_type != 'finish')" (click)="edit()">SEND TO SALES</button>
    <button type="button" class="btn btn-primary btn-black float-right" *ngIf="!isNew && data.userData.permission == 4
    && shipping_order_type == ''" (click)="open(closeModal)">CLOSE</button>
    <button type="button" class="btn btn-primary float-right" *ngIf="!isNew && data.userData.permission != 5" (click)="download()">DOWNLOAD PDF</button>
    <button type="button" class="btn btn-primary btn-orange float-right" *ngIf="!isNew && data.userData.permission == 4 && quotation_price != null
    && shipping_order_type == '' && quotations.length > 0" (click)="moveSO()">MOVE TO SO</button>
  </div>
  <ng-template #closeModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Та тухайн асуулгыг хаах шалтгаанаа сонгоно уу!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <select class="form-control" [(ngModel)]="reason_id">
        <option value="0">Шалтгаан сонгоно уу...</option>
        <option *ngFor="let reason of reasons" [value]="reason.id">{{reason.name}}</option>
      </select><br/>
      <div class="col-md-12 col-12">
        <mat-form-field class="example-full-width text">
          <mat-label>Closing inquiry comment</mat-label>
          <textarea matInput class="textHeight" [(ngModel)]="closed_comment"> </textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="close()">Ok</button>
    </div>
  </ng-template>

  <ng-template #priceModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Та тээврийн үнийн сонголтоос сонгоно уу!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <select class="form-control" [(ngModel)]="price_arr_id">
        <option *ngFor="let price of priceArrOrig" [value]="price.id">{{price.name}}</option>
      </select><br/>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="addPrice()">Ok</button>
    </div>
  </ng-template>
  <!-- /.card -->
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>