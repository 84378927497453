import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-operation-report',
  templateUrl: './operation-report.component.html',
  styleUrls: ['./operation-report.component.scss'],
})
export class OperationReportComponent implements OnInit {
  public id;
  shipping_order_name;
  trans_type_name;
  cntr_name;
  pol_country_name;
  pol_city_name;
  border_name;
  final_country_name;
  final_city_name;
  eta_border;
  ata_border;
  atd_border;
  ata_destination;
  cc_started;
  cc_finished;
  released;

  constructor(
    private data: DataProvider,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Transportation Report");
  }

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.spinner.show();
    this.rest.operationDetail(this.id).subscribe((res) =>{
      this.spinner.hide();
      this.shipping_order_name = res.detail.shipping_order_name;
      this.trans_type_name = res.detail.trans_type_name;
      this.cntr_name = res.detail.cntr_name;
      this.pol_country_name = res.detail.pol_country_name;
      this.pol_city_name = res.detail.pol_city_name;
      this.border_name = res.detail.border_name
      this.final_country_name = res.detail.final_country_name;
      this.final_city_name = res.detail.final_city_name;
      this.eta_border = res.detail.eta_border;
      this.ata_border = res.detail.ata_border;
      this.atd_border = res.detail.atd_border;
      this.ata_destination = res.detail.ata_destination;
      this.cc_started = res.detail.cc_started.substring(0, 10);
      this.cc_finished = res.detail.cc_finished.substring(0, 10);
      this.released = res.detail.released.substring(0, 10);
    },(err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
}
