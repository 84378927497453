import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';
import { AppService } from 'src/app/utils/services/app.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-inquiry-list',
  templateUrl: './inquiry-list.component.html',
  styleUrls: ['./inquiry-list.component.scss'],
})
export class InquiryListComponent implements OnInit {
  Consignee = new FormControl();
  consignees = [];
  consigneesOrig = [];
  consignee_id;
  filteredOptions: Observable<string[]>;
  nameFormControl = new FormControl();
  inquirys = [];
  inquirys_old = [];
  loading = false;
  searchTerm = '';
  consignee: any;
  page = 1;
  user_id;
  filteredItems = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  closeResult: string;
  public startDate;
  public endDate;
  inquiry_id;
  trans_mode_id;
  Transportation_mode = [];

    constructor(public data: DataProvider, private router: Router, private appService: AppService, public rest: CallapiService,private toastr: ToastrService,
      private spinner: NgxSpinnerService,private modalService: NgbModal,) {
    data.menuPageTitle = 'Inquiry';
  }

  public _filterCons() {
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    if(filterValue == '') {
      this.search();
    }
    this.consignees = this.consigneesOrig.filter(option => option.name.toLowerCase().includes(filterValue));
    if(this.consignees.length == 0) {
      this.consignee_id = input.value;
    }
  }

  onConsigneeSelected(option: any) {
    for(let i = 0; i < this.consignees.length; i++) {
      if(this.consignees[i].name == option.value) {
        this.consignee_id = this.consignees[i].id;
        this.search();
        break;
      }
    }
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    this.user_id = this.data.userData.id;

    this.spinner.show();
    this.rest.inquiryList(this.data.userData.id).subscribe(
      (res) => {
        for(let i = 0; i< res.length; i++){
          res[i].quotation_date = res[i].quotation_date.substring(0, 10);
          res[i].validaty_date = res[i].validaty_date.substring(0, 10);
        }
        this.inquirys = res;
        this.rest.consignees().subscribe(
          (res) => {
            this.spinner.hide();
            this.consignees = res.consignees;
            this.consigneesOrig = res.consignees;
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          }
        );
        this.rest.getTransportModes().subscribe(
          (res) => {
            this.spinner.hide();
            this.Transportation_mode = res.transport_modes;
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          }
        );
    
        this.filteredItems = this.inquirys;
        this.inquirys_old = this.inquirys;
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;
        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.inquirys = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  clear() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.searchTerm = '';
    this.consignee_id = '';
    this.trans_mode_id = '';
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    input.value = '';
    this.startDate = '';
    this.endDate = '';
    this.filteredItems = this.inquirys_old;
    this.refreshItems();
  }

  search() {
    let ch = 0;
    if(this.searchTerm) {
      this.filteredItems = this.filteredItems.filter(option => option.name.toLowerCase().includes(this.searchTerm));
    } else {
      ch++;
    }
    if(this.consignee_id) {
      this.filteredItems = this.filteredItems.filter(option => option.consignee_id == this.consignee_id);
    } else {
      ch++;
    }
    if(this.trans_mode_id) {
      this.filteredItems = this.filteredItems.filter(option => option.trans_mode_id == this.trans_mode_id);
    } else {
      ch++;
    }
    if(this.startDate) {
      var m = new Date(new Date(this.startDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
      var dateString =
          m.getFullYear() + "-" +
          ("0" + (m.getMonth()+1)).slice(-2) + "-" +
          ("0" + m.getDate()).slice(-2) + " " +
          ("0" + m.getHours()).slice(-2) + ":" +
          ("0" + m.getMinutes()).slice(-2) + ":" +
          ("0" + m.getSeconds()).slice(-2);
      this.filteredItems = this.filteredItems.filter(option => option.quotation_date >= dateString.substring(0,10));
    } else {
      ch++;
    }
    if(this.endDate) {
      var m = new Date(new Date(this.endDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
      var dateString =
          m.getFullYear() + "-" +
          ("0" + (m.getMonth()+1)).slice(-2) + "-" +
          ("0" + m.getDate()).slice(-2) + " " +
          ("0" + m.getHours()).slice(-2) + ":" +
          ("0" + m.getMinutes()).slice(-2) + ":" +
          ("0" + m.getSeconds()).slice(-2);
      this.filteredItems = this.filteredItems.filter(option => option.validaty_date <= dateString.substring(0,10));
    } else {
      ch++;
    }
    if(ch == 5) {
      this.filteredItems = this.inquirys_old;
    }
    this.refreshItems();
  }

  showInquiry(bool: any, id: any) {
    if(bool) {
      this.router.navigate(['/inquiry/add']);
    } else {
      this.router.navigate(['/inquiry/edit', {id: id}]);
    }
  }

  dupInquiry(id: any) {
    this.router.navigate(['/inquiry/add', {dup_id: id}]);
  }

  deleteInquiry(id: any) {
    if (confirm('Та устгахдаа итгэлтэй байна уу?')) {
      this.spinner.show();
      this.rest.deleteInquiry(id).subscribe((res) => {
        this.spinner.hide();
        if(res.message == 'OK') {
          this.toastr.success('Success', 'Амжилттай устгалаа.');
          window.location.reload();
        } else {
          this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
        }
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
    }
  }

  open(content, id: any) {
    this.inquiry_id = id;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  exportExcel() { 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Payable');
    worksheet.columns = [
      { header: 'Name', key: 'Name', width: 15 },
      { header: 'Consignee', key: 'Consignee', width: 32 },
      { header: 'POL Country', key: 'POL', width: 10 },
      { header: 'POD Destination', key: 'POD', width: 10 },
      { header: 'MODE', key: 'MODE', width: 10 },
      { header: 'CBM', key: 'CBM', width: 10 },
      { header: 'Quotation Date', key: 'Date', width: 10 },
      { header: 'Sales', key: 'Sales', width: 10 },
      { header: 'Tariff', key: 'Tariff', width: 10 },
    ];

    for(let i = 0; i < this.inquirys_old.length; i++) {
      worksheet.addRow(
        {
          Name: this.inquirys_old[i].name, 
          Consignee: this.inquirys_old[i].consignee_name, 
          POL: this.inquirys_old[i].pol_country_name + '/' + this.inquirys_old[i].pol_city_name, 
          POD: this.inquirys_old[i].final_country_name + '/' + this.inquirys_old[i].final_city_name,
          MODE: this.inquirys_old[i].trans_mode_name,
          CBM: this.inquirys_old[i].cbm,
          Date: this.inquirys_old[i].quotation_date,
          Sales: this.inquirys_old[i].sales_name,
          Tariff: this.inquirys_old[i].tariff_name
        }, 'n'
      );
    }
 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Inquirys.xlsx');
    })
  }
}
