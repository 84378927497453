<div class="main">
<div class="pageA4">
   <div class="printArea">
      <!-- Header -->
      <div class="headerRow">
        <div>
          <div> <img src="assets/img/logo.png" width="100" /> </div>
        </div>
        <div>
          <p>
            <strong>Симатай Монголиа ХХК</strong> <br />
            Монгол улс, Улаанбаатар хот, Хан-Уул дүүрэг, <br />
            15-р хороо Galaxy Tower 10F 1005 <br />
            Утас: +976 7600-0099 <br />
            http://simataimongolia.com
          </p>
        </div>
      </div>


              <!-- 1st row -->
      <div class="firstRow">
        <div><strong>Тээврийн мэдээ / Tracing info </strong></div>
        <div>

          
        </div>
      </div>

      <div style="padding-bottom:10px;">
        <table width="100%">
          <tr>
            <td>
              <div>{{shipping_order_name}}</div>
              <small><strong>Tracking Code (SM дугаар )</strong></small>
            </td>
            <td>
              <div>{{trans_type_name}}</div>
              <small><strong>Transportation Type ( Тээвэрлэлтийн төрөл )</strong></small>
            </td>
            <td>                
              <div>{{cntr_name}}</div>
              <div></div>
              <small><strong>Cntr/AWB/Truck #</strong></small>
            </td>
                         
          </tr>
          <tr>
            <td>
              <div>{{pol_country_name}}-{{pol_city_name}}</div>
              <small><strong>POL ( Ачигдах газар )</strong></small>
            </td>
            <td>
              <div>{{border_name}}</div>
              <small><strong>Border ( Хилийн боомт )</strong></small>
            </td>
            <td>
              <div>{{final_country_name}}-{{final_city_name}}</div>
              <small><strong>POD ( Хүрэх газар )</strong></small>
            </td> 
            
            
          </tr> 
        </table>
        <table width="100%" style="margin-top: 10px">
          <tr>
            <td colspan="3"><b>Гадаад тээврийн мэдээ / International tracing info</b></td>
          </tr>
          <tr style="background: #FFE699">
            <th colspan="2" width="30%">Location (Байршил)</th>
            <th width="30%">Date (Огноо)</th>
            <th>Status (Төлөв)</th>
          </tr>
                    </table>

        <table width="100%" style="margin-top: 10px">
          <tr>
            <td colspan="3"><b>Дотоод тээврийн мэдээ / Domestic tracing info</b></td>
          </tr>
          <tr style="background: #FFE699">
            <th width="30%">Status (төлөв)</th>
            <th width="30%">Date (Огноо)</th>
            <th>Тайлбар (remarks)</th>
          </tr>
          <tr>
            <td>ETA Border </td>
            <td>{{eta_border}}</td>
            <td>Хил дээр ирэх урьдчилсан огноо</td>
          </tr>
          <tr>
            <td>ATA Border </td>
            <td>{{ata_border}}</td>
            <td>Хил дээр ирсэн бодит огноо</td>
          </tr>
          <tr>
            <td>ATD Border </td>
            <td>{{atd_border}}</td>
            <td>Хилээс хөдөлсөн бодит огноо</td>
          </tr>
          <tr>
            <td>ATA destination </td>
            <td>{{ata_destination}}</td>
            <td>Хүрэх газарт ирсэн бодит огноо</td>
          </tr>
        </table>

        <table width="100%" style="margin-top: 10px">
          <tr>
            <td colspan="3"><b>Гаалийн мэдээ / Customs tracing info</b></td>
          </tr>
          <tr style="background: #FFE699">
            <th width="30%">Status (төлөв)</th>
            <th width="30%">Date (Огноо)</th>
            <th>Тайлбар (remarks)</th>
          </tr>
          <tr>
            <td>CC started </td>
            <td>{{cc_started}}</td>
            <td>Гаалийн бүрдүүлэлт эхэлсэн огноо</td>
          </tr>
          <tr>
            <td>CC finished</td>
            <td>{{cc_finished}}</td>
            <td>Гаалийн бүрдүүлэлт дууссан огноо</td>
          </tr>
          <tr>
            <td>Released</td>
            <td>{{released}}</td>
            <td>Ачаа олголт хийсэн огноо</td>
          </tr>
        </table>
      </div>

    </div>
</div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>