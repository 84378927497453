<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 *ngIf="isNew">Шинэ ханш</h1>
        <h1 *ngIf="!isNew">Ханш засах</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <button
            type="button"
            class="btn btn-tool back-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Буцах"
            (click)="backList()"
          >
            <i class="fa fa-arrow-circle-left"></i>
          </button>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Мэдээлэл</h3>

        <div class="card-tools">
          
        </div>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="year">Year</label>
          <input type="tel" maxlength="4" class="form-control" name="year" placeholder="Огноо" [(ngModel)]="year">
        </div>
        <div class="form-group">
          <label for="month">Month</label>
          <!-- <input type="number" maxlength="2" class="form-control" name="month" placeholder="Сар" [(ngModel)]="month"> -->
          <select class="form-control" [(ngModel)]="month" name="month">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
        <div class="form-group">
          <label for="name">Name</label>
          <!-- <input type="text" class="form-control" name="name" placeholder="Нэр" [(ngModel)]="name"> -->
          <select class="form-control" [(ngModel)]="name">
            <option value="">Нэр сонгоно уу...</option>
            <option value="USD-MGB">USD-MGB</option>
            <option value="USD-GB">USD-GB</option>
            <option value="RMB-MGB">RMB-MGB</option>
            <option value="RMB-GB">RMB-GB</option>
            <option value="MNT">MNT</option>
            <option value="EURO">EURO</option>
          </select>
        </div>
        <div class="form-group">
          <label for="rate">Rate</label>
          <input type="number" class="form-control" name="rate" placeholder="Ханш" [(ngModel)]="rate">
        </div>
        <div class="form-group" *ngIf="!isNew">
          <mat-slide-toggle [checked]="vis" [(ngModel)]="vis" (change)="changeVis()">Active</mat-slide-toggle>
        </div>
      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <button type="button" class="btn btn-primary" *ngIf="!isNew" (click)="edit()">Шинэчлэх</button>
        <button type="button" class="btn btn-primary" *ngIf="isNew" (click)="edit()">Хадгалах</button>
      </div>
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>