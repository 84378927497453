import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import * as CryptoJS from 'crypto-js';
import { ENCRYPT_SECRET_KEY } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMAGE_FILE_URL } from '../../../environments/environment';

@Component({
  selector: 'app-shipping-order-edit',
  templateUrl: './shipping-order-edit.component.html',
  styleUrls: ['./shipping-order-edit.component.scss'],
})
export class ShippingOrderEditComponent implements OnInit {
  fileName = '';
  progress = 0;
  Payer = new FormControl();
  Shipper = new FormControl();
  paymentType = [];
  paymentTerm = [];
  containerStatus = [];
  routes = [];
  terminal = [];
  consignees = [];
  consigneesOrig = [];
  suppliers = [];
  suppliersOrig = [];
  shipper_id;
  payer_id;
  id;
  payment_type_id;
  payment_term_id;
  route_id = 0;
  terminal_id;
  comments;
  container_id;
  box_file;
  imageFile;
  contract_id;
  contract_type;
  contract_file;
  pre_payment = 0;
  chk = true;
  imageFileUrl = IMAGE_FILE_URL;
  inquiry_id;
  perm;

  constructor(
    public appService: AppService,
    public data: DataProvider,
    public router: Router,
    public rest: CallapiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    ) {
    data.menuPageTitle = 'shipping-order';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.spinner.show();
    this.rest.shippingOrderHelpers().subscribe((res) => {
      this.terminal = res.terminals;
      this.containerStatus = res.container_statuses;
      this.paymentType = res.payment_types;
      this.paymentTerm = res.payment_terms;
      this.routes = res.routes;
      this.consignees = res.consignees;
      this.consigneesOrig = res.consignees;
      this.suppliers = res.suppliers;
      this.suppliersOrig = res.suppliers;
        this.rest.shippingOrderDetail(this.id).subscribe((res1) => {
          this.spinner.hide();
          if(res1.detail.payment_type_id != 0) {
            const input = document.getElementById(
              'consignee',
            ) as HTMLInputElement | null;    
            input.value = res1.detail.consignee_name;
            this.payer_id = res1.detail.payer_id;
            const input1 = document.getElementById(
              'supplier',
            ) as HTMLInputElement | null;    
            input1.value = res1.detail.supplier_name;
            this.shipper_id = res1.detail.shipper_id;
            this.payment_type_id = res1.detail.payment_type_id;
            this.terminal_id = res1.detail.terminal_id;
            this.container_id = res1.detail.container_id;
            this.route_id = res1.detail.route_id;
            this.payment_term_id = res1.detail.payment_term_id;
            this.inquiry_id = res1.detail.inquiry_id;
            this.comments = res1.detail.comments;
            this.perm = res1.detail.perm;
          }
          if(res1.file != null){
            this.contract_file = res1.file.contract_file;
            this.chk = false;
          }
          if(res1.detail.pre_payment != null ){
            this.pre_payment = res1.detail.pre_payment;
          }
          if(res1.detail.contract_id != null){
            this.container_id = res1.detail.contract_id;
          }
          if(res1.detail.contract_type != null){
            this.contract_type = res1.detail.contract_type;
          }

        },(err) => {
          console.log(err);
          this.spinner.hide();
        });

    },(err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  typeChange() {
    if(this.contract_type == "general"){
      this.spinner.show();
      this.rest.getSoFile(this.id).subscribe((res) => {
        if(res.file != null){
          this.chk = false;
        } else {
          this.chk = true;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      })
    }
  }

  setFileName(fileInput) {
    let files: File[] = fileInput.files;
    if (files.length < 1) {
      document.getElementById('img-label').innerHTML = 'Файл оруулах';
      return;
    }
    let file = files[0];
    const reader = new FileReader();
    let self = this;
    reader.onload = function(e) { 
      self.imageFile = e.target.result;
    };
    reader.readAsDataURL(file);
    document.getElementById('img-label').innerHTML = file.name;
  }

  onConsigneeSelected(option: any) {
    for(let i = 0; i < this.consignees.length; i++) {
      if(this.consignees[i].name == option.value) {
        this.payer_id = this.consignees[i].id;
        break;
      }
    }
  }

  public _filterCons() {
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.payer_id = input.value;
    this.consignees = this.consigneesOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onSupplierSelected(option: any) {
    for(let i = 0; i < this.suppliers.length; i++) {
      if(this.suppliers[i].name == option.value) {
        this.shipper_id = this.suppliers[i].id;
        break;
      }
    }
  }

  public _filterSupp() {
    const input = document.getElementById(
      'supplier',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.shipper_id = input.value;
    this.suppliers = this.suppliersOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  backList() {
    this.router.navigate(['/shipping-order']);
  }

  print() {
    window.open('/shipping-order/print;id=' + this.id, '_blank');
  }

  save() {
    if(this.perm == null || this.perm != 'OK') {
      this.toastr.error('Алдаа!', ' Та удирдлагаас зөвшөөрөл авна уу!');
      return;
    }
    if(this.contract_id == null || this.contract_id == '') {
      this.toastr.error('Алдаа!', ' Та гэрээний  дугаараа оруулна уу!');
      return;
    }

    if(this.contract_type == null || this.contract_type == '') {
      this.toastr.error('Алдаа!', ' Та гэрээний  төрөлөө оруулна уу!');
      return;
    }

    if(this.imageFile == null || this.imageFile == '') {
      this.toastr.error('Алдаа!', ' Та гэрээний  file - аа оруулна уу!');
      return;
    }

    if(this.payer_id == null || this.payer_id == '') {
      this.toastr.error('Алдаа!', ' Payer хэсгийг оруулна уу!');
      return;
    }

    if(this.shipper_id == null || this.shipper_id == '') {
      this.toastr.error('Алдаа!', ' Shipper хэсгийг оруулна уу!');
      return;
    }

    if(this.payment_type_id == null || this.payment_type_id == '') {
      this.toastr.error('Алдаа!', ' Payment type хэсгийг оруулна уу!');
      return;
    }

    if(this.payment_term_id == null || this.payment_term_id == '') {
      this.toastr.error('Алдаа!', ' Payment term хэсгийг оруулна уу!');
      return;
    }

    if(this.route_id == null) {
      this.toastr.error('Алдаа!', ' Route хэсгийг оруулна уу!');
      return;
    }

    if(this.payment_term_id == 2 && (this.pre_payment == null || this.pre_payment == 0)) {
      this.toastr.error('Алдаа!', 'Урьдчилгаа хэсгийг оруулна уу!');
      return;
    }

    if(this.pre_payment == null) {
      this.pre_payment = 0;
    }

    if(this.contract_type == 'general') {
      this.contract_file = 'main-' + this.payer_id;
    } else {
      this.contract_file = 'S0-CONTRACT-FILE-' + new Date().getTime();
    }
    this.spinner.show();
    this.rest.uploadFile(this.imageFile, this.contract_file).subscribe((res) => {
      if(res.success) {
      this.rest.shippingOrderEditFile(this.id, this.contract_id, this.contract_type, this.contract_file, this.pre_payment).subscribe((res1) => {
        this.spinner.hide();
        if(res1.message == 'OK') {
          this.toastr.success('Success', 'Амжилттай хадгаллаа.');
          if(this.data.userData.permission == 4) {
            const userdata = {
              id: this.data.userData.id,
              lname: this.data.userData.lname,
              fname: this.data.userData.fname,
              permission: this.data.userData.permission,
              email: this.data.userData.email,
              phone: this.data.userData.phone,
              job_date: this.data.userData.job_date,
              image: this.data.userData.image,
              name: this.data.userData.name,
              password: this.data.userData.password,
              position_type: this.data.userData.position_type,
              create_date: this.data.userData.create_date,
              notifications: {
                inquiry: this.data.userData.notifications.inquiry,
                shipping_order: this.data.userData.notifications.shipping_order - 1,
                operation: this.data.userData.notifications.operation,
                custom: this.data.userData.notifications.custom,
                site: this.data.userData.notifications.site,
                invoices: this.data.userData.notifications.invoices
              },
            };
            const encrypt = CryptoJS.AES.encrypt(
              JSON.stringify(userdata),
              ENCRYPT_SECRET_KEY
            ).toString();
            localStorage.setItem('token', encrypt);
          }
          this.router.navigate(['/operation']);
        } else {
          this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
        }
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
         
      } else {
        this.spinner.hide();
        this.toastr.error('Алдаа!', 'Файл upload хийж чадсангүй та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  update() {
    if(this.perm == null || this.perm != 'OK') {
      this.toastr.error('Алдаа!', ' Та удирдлагаас зөвшөөрөл авна уу!');
      return;
    }
    if(this.payer_id == null || this.payer_id == '') {
      this.toastr.error('Алдаа!', ' Payer хэсгийг оруулна уу!');
      return;
    }

    if(this.shipper_id == null || this.shipper_id == '') {
      this.toastr.error('Алдаа!', ' Shipper хэсгийг оруулна уу!');
      return;
    }

    if(this.payment_type_id == null || this.payment_type_id == '') {
      this.toastr.error('Алдаа!', ' Payment type хэсгийг оруулна уу!');
      return;
    }

    if(this.payment_term_id == null || this.payment_term_id == '') {
      this.toastr.error('Алдаа!', ' Payment term хэсгийг оруулна уу!');
      return;
    }

    if(this.route_id == null) {
      this.toastr.error('Алдаа!', ' Route хэсгийг оруулна уу!');
      return;
    }

    if(this.comments == null) {
      this.comments = '';
    }
    if(this.container_id == null) {
      this.container_id = 0;
    }

    if(this.terminal_id == null) {
      this.terminal_id = 0;
    }
  this.spinner.show();
   this.rest.shippingOrderEdit(
    this.id,
    this.payer_id,
    this.shipper_id,
    this.payment_type_id,
    this.payment_term_id,
    this.route_id,
    this.terminal_id,
    this.comments,
    this.container_id,
    this.inquiry_id
   ).subscribe((res) => {
    this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай засварлалаа.');
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }

   }, (err) => {
      console.log(err);
      this.spinner.hide();
   });
  }

}
