import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
})
export class SupplierComponent implements OnInit {
  public isNew = true;
  id;
  name;

  constructor(private data: DataProvider, private appService: AppService,
    private route: ActivatedRoute,private rest: CallapiService,private toastr: ToastrService,
    private router: Router,private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'settings-suppliers';
  }

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id != null) {
      this.isNew = false;
      this.name = this.route.snapshot.paramMap.get('name');
    }
  }

  edit() {
    if(this.name == null || this.name == '') {
      this.toastr.error('Алдаа!', 'Та нэр оруулна уу!');
      return;
    }
    const data = {
      id: this.id,
      name: this.name,
      type: 'edit'
    }
    this.spinner.show();
    this.rest.addSupplier(data).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай засварлалаа.');
        this.router.navigate(['/settings-suppliers']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  save() {
    if(this.name == null || this.name == '') {
      this.toastr.error('Алдаа!', 'Та нэр оруулна уу!');
      return;
    }
    const data = {
      name: this.name,
      type: 'add'
    }
    this.spinner.show();
    this.rest.addSupplier(data).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай нэмлээ.');
        this.router.navigate(['/settings-suppliers']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  backList() {
    this.router.navigate(['/settings-suppliers']);
  }
}
