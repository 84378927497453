<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2" >
        <div class="col-sm-6 col-xs-6 col-6">
          <h1>Finished Shipments ({{sname}})</h1>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content">
    <!-- Default box -->
    <div class="container-fluid">
      <div class="card">
        
        <div class="card-body">
          <!-- <form name="form"> -->
            <div class="mb-3 row">
                <div class="col-xs-3 col-sm-auto fsize">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="type" (selectionChange)="getList()">
                            <mat-option value="FCL">FCL</mat-option>
                            <mat-option value="NOFCL">FCL not included</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label class="text">SM</mat-label>
                    <input
                      type="text"
                      matInput
                      [formControl]="nameFormControl"
                      [(ngModel)]="smname"
                      name="smname"
                    />
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">Name</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="nameFormControl"
                    [(ngModel)]="searchTerm"
                    name="searchName"
                  />
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">Consignee</mat-label>
                  <input type="text"
                        matInput
                        class="cons-input"
                        [formControl]="Consignee"
                        [matAutocomplete]="auto1"
                        (ngModelChange)="_filterCons()"
                        id="consignee"
                        >                        
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="onConsigneeSelected($event.option)" >
                    <mat-option *ngFor="let option of consignees" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width fsize" appearance="outline">
                    <mat-label>Start</mat-label>
                    <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="startDate" >
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width fsize" appearance="outline">
                    <mat-label>End</mat-label>
                    <input matInput [matDatepicker]="picker1"  (click)="picker1.open()"  [(ngModel)]="endDate">
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>  
              <span class="col col-form-label" *ngIf="loading">Loading...</span>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Шинэчлэх"
                (click)="clear()"
              >
                <i class="fa fa-times"></i>
              </button>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Хайх"
                (click)="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          
            <div class="table_wrapper">
              <table class="table table-striped table-hovered">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <!-- <th scope="col">#</th> -->
                    <th scope="col">SO/NO</th>
                    <th scope="col">Consignee</th>
                    <th scope="col">Shipper</th>
                    <th scope="col">Payer</th>
                    <th scope="col">CNTR/WGN Number</th>
                    <th scope="col">Transport Type</th>
                    <th scope="col">Commodity</th>
                    <th scope="col">Payment Type</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Origin</th>
                    <th scope="col">Via</th>
                    <th scope="col">Destination</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Email</th>
                    <th scope="col">Comment</th>
                    <th scope="col">ATA Destination</th>
                    <th scope="col">Released</th>
                    <th scope="col">Custom Comment</th>
                    <th scope="col">Site Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let finish of finished">                
                    <th scope="row">
                      <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Shipping Order Print"
                      (click)="print(finish.shipping_order_id)"
                    >
                    <i class="fas fa-print"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="inquiry харах"
                      (click)="inquiryBack(finish.inquiry_id)"
                    >
                    <i class="nav-icon fas fa-notes-medical"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Тээврийн мэдээ"
                      (click)="report(finish.id)"
                    >
                      <i class="fas fa-file"></i>
                    </button>
                    </th>
                    <th scope="row"title="Shipping order харах"
                    (click)="shippingOrder(finish.shipping_order_id)"
                    class="edit">#{{ finish.shipping_order_name }}</th>
                    <td>
                        {{ finish.consignee_name }}
                    </td>
                    <td>
                        {{finish.shipper_name}}
                    </td>
                    <td>
                        {{finish.payer_name}}
                    </td>
                    <td>{{finish.cntr_name}}</td>
                    <td>{{finish.trans_type_name}}</td>
                    <td>{{finish.commodity}}</td>
                    <td>{{finish.payment_name}}</td>
                    <td>{{finish.currency_name}}</td>
                    <td>{{finish.rate}}</td>
                    <td>{{finish.pol_country_name}}<br/><small style="color: #f0b622 !important;">{{finish.pol_city_name}}</small></td>
                    <td>{{finish.via_name}}</td>
                    <td>{{finish.final_country_name}}<br/><small style="color: #f0b622 !important;">{{finish.final_city_name}}</small></td>
                    <td>{{finish.consignee_phone}}</td>
                    <td>{{finish.consignee_email}}</td>
                    <td>{{finish.comment}}</td>
                    <td>{{finish.ata_destination}}</td>
                    <td>{{finish.released}}</td>
                    <td>{{finish.custom_comment}}</td>
                    <td>{{finish.site_comment}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          <!-- </form> -->
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
            <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
          </div>
          <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
            <label class="pages">No Finished List</label>
          </div>
          <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
            <span
              [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
              (click)="prevPage()"
              class="prev"
              >&laquo;
            </span>
            <span
              *ngFor="let page of pagesIndex"
              [ngClass]="{ tod: currentIndex == page }"
              class="dot"
              (click)="setPage(page)"
            >{{page}}</span>
            <span
              [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
              (click)="nextPage()"
              class="next"
              >&raquo;
            </span>
          </div>
        </div>
        <!-- /.card-footer-->
      </div>
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
    <p style="color: #bf2429 " > Уншиж байна... </p>
  </ngx-spinner>