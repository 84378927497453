import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import * as CryptoJS from 'crypto-js';
import { ENCRYPT_SECRET_KEY } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMAGE_FILE_URL } from '../../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public lastname;
  public firstname;
  public phone;
  public email;
  public oldpass;
  public password;
  check = true;
  imageFile;
  imageFileUrl = IMAGE_FILE_URL;

  constructor(public appService: AppService, public data: DataProvider, public router: Router,
    private toastr: ToastrService, private rest: CallapiService,private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'Profile';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.lastname = this.data.userData.lname;
    this.firstname = this.data.userData.fname;
    this.phone = this.data.userData.phone;
    this.email = this.data.userData.email;
    this.oldpass = this.data.userData.password;
  }

  setFileName(fileInput) {
    let files: File[] = fileInput.files;
    if (files.length < 1) {
      document.getElementById('img-label').innerHTML = 'Зураг оруулах';
      this.check = true;
      return;
    }
    let file = files[0];
    const reader = new FileReader();
    let self = this;
    reader.onload = function(e) { 
      self.imageFile = e.target.result;
    };
    reader.readAsDataURL(file);
    document.getElementById('img-label').innerHTML = file.name;
    this.check = false;
  }

  uploadImage(fileInput) {
    let files: File[] = fileInput.files;
    if (files.length < 1) {
      this.toastr.error('Алдаа!', 'Зургаа сонгоно уу!');
      return;
    }
    let file = files[0];
    const reader = new FileReader();
    let self = this;
    this.spinner.show();
    reader.onload = function(e) {
      self.rest.uploadImage(e.target.result, 'user-' + self.data.userData.id).subscribe((res) => {
        if(res.success) {
          self.rest.changeProfile(self.data.userData.id, 'user-' + self.data.userData.id + '.png').subscribe((res2) => {
            self.spinner.hide();
            if(res2 == "NG") {
              self.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
            } else {
              self.toastr.success('Success', 'Зургаа амжилттай солилоо.');
              const userdata = {
                id: self.data.userData.id,
                lname: self.data.userData.lname,
                fname: self.data.userData.fname,
                permission: self.data.userData.permission,
                email: self.data.userData.email,
                phone: self.data.userData.phone,
                job_date: self.data.userData.job_date,
                image: 'user-' + self.data.userData.id + '.png',
                name: self.data.userData.name,
                password: self.data.userData.password,
                position_type:self.data.userData.position_type,
                create_date:self.data.userData.create_date,
                notifications: {
                  inquiry: self.data.userData.notifications.inquiry,
                  shipping_order: self.data.userData.notifications.shipping_order,
                  operation: self.data.userData.notifications.operation,
                  custom: self.data.userData.notifications.custom,
                  site: self.data.userData.notifications.site,
                  invoices: self.data.userData.notifications.invoices
                },
              };
              const encrypt = CryptoJS.AES.encrypt(
                JSON.stringify(userdata),
                ENCRYPT_SECRET_KEY
              ).toString();
              localStorage.setItem('token', encrypt);
            }          
          }, (err) => {
            console.log(err);
            self.spinner.show();
          });
        } else {
          self.toastr.error('Алдаа!', 'Зураг хуулж чадсангүй дахин оруулна уу!');
        }
      }, (err) => {
        console.log(err);
        self.spinner.hide();
      });
    };
    reader.readAsDataURL(file);
  }

  changePass() {
    if(this.phone == null || this.phone == '') {
      this.toastr.error('Алдаа!', 'Утасны дугаараа оруулна уу!');
      return;
    }
    if(this.password == null || this.password == '') {
      this.toastr.error('Алдаа!', 'Шинэ нууц үгээ оруулна уу!');
      return;
    }
    if(this.oldpass == this.password) {
      this.toastr.error('Алдаа!', 'Шинэ нууц үг хуучинтай адил байна өөрийг оруулна уу!');
      return;
    }
    this.spinner.show();
    this.rest.changePass(this.data.userData.id, this.phone, this.password).subscribe(
      (res) => {
        this.spinner.hide();
        if(res == "NG") {
          this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
        } else {
          this.toastr.success('Success', 'Нууц үгээ амжилттай солилоо.');
          const userdata = {
            id: this.data.userData.id,
            lname: this.data.userData.lname,
            fname: this.data.userData.fname,
            permission: this.data.userData.permission,
            email: this.data.userData.email,
            phone: this.phone,
            job_date: this.data.userData.job_date,
            image: this.data.userData.image,
            name: this.data.userData.name,
            password: this.password,
            position_type:this.data.userData.position_type,
            create_date:this.data.userData.create_date,
            notifications: {
              inquiry: this.data.userData.notifications.inquiry,
              shipping_order: this.data.userData.notifications.shipping_order,
              operation: this.data.userData.notifications.operation,
              custom: this.data.userData.notifications.custom,
              site: this.data.userData.notifications.site,
              invoices: this.data.userData.notifications.invoices
            },
          };
          const encrypt = CryptoJS.AES.encrypt(
            JSON.stringify(userdata),
            ENCRYPT_SECRET_KEY
          ).toString();
          localStorage.setItem('token', encrypt);
          this.data.userData.password = this.password;
        }          
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }
}
