import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-closed-inquiry',
  templateUrl: './closed-inquiry.component.html',
  styleUrls: ['./closed-inquiry.component.scss'],
})

export class ClosedInquiryComponent implements OnInit {
  toastr: any;
  Consignee = new FormControl();
  consignees = [];
  consigneesOrig = [];
  consignee_id;
  filteredOptions: Observable<string[]>;
  nameFormControl = new FormControl();
  closedInquirys = [];
  inquirys_old = [];
  loading = false;
  searchTerm = '';
  consignee: any;
  page = 1;
  user_id;
  filteredItems = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  modalService: any;
  closeResult: string;
  public startDate;
  public endDate;
  constructor(public appService: AppService, public data: DataProvider, public router: Router, public rest: CallapiService,
    private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'closed-inquiry';
  }
  public _filterCons() {
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();    
    this.consignees = this.consigneesOrig.filter(option => option.name.toLowerCase().includes(filterValue));
    if(this.consignees.length == 0) {
      this.consignee_id = input.value;
    }
  }

  onConsigneeSelected(option: any) {
    for(let i = 0; i < this.consignees.length; i++) {
      if(this.consignees[i].name == option.value) {
        this.consignee_id = this.consignees[i].id;
        break;
      }
    }
  }
  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    this.user_id = this.data.userData.id;
    this.spinner.show();
    this.rest.closedInquiry(this.user_id).subscribe((res) =>{
      for(let i = 0; i< res.length; i++){
        res[i].quotation_date = res[i].quotation_date.substring(0, 10);
        res[i].validaty_date = res[i].validaty_date.substring(0, 10);
        res[i].closed_at = res[i].closed_at.substring(0, 10);
      }
      this.closedInquirys = res;
      this.rest.consignees().subscribe(
          (res) => {
            this.spinner.hide();
            this.consignees = res.consignees;
            this.consigneesOrig = res.consignees;
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          }
      );

        this.filteredItems = this.closedInquirys;
        this.inquirys_old = this.closedInquirys;
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;
        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.closedInquirys = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  clear() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.searchTerm = '';
    this.consignee_id = '';
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    input.value = '';
    this.startDate = '';
    this.endDate = '';
    this.filteredItems = this.inquirys_old;
    this.refreshItems();
  }

  search() {
    let ch = 0;
    if(this.searchTerm) {
      this.filteredItems = this.filteredItems.filter(option => option.name.toLowerCase().includes(this.searchTerm));
    } else {
      ch++;
    }
    if(this.consignee_id) {
      this.filteredItems = this.filteredItems.filter(option => option.consignee_id == this.consignee_id);
    } else {
      ch++;
    }
    if(this.startDate) {
      var m = new Date(new Date(this.startDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
      var dateString =
          m.getFullYear() + "-" +
          ("0" + (m.getMonth()+1)).slice(-2) + "-" +
          ("0" + m.getDate()).slice(-2) + " " +
          ("0" + m.getHours()).slice(-2) + ":" +
          ("0" + m.getMinutes()).slice(-2) + ":" +
          ("0" + m.getSeconds()).slice(-2);
      this.filteredItems = this.filteredItems.filter(option => option.quotation_date >= dateString.substring(0,10));
    } else {
      ch++;
    }
    if(this.endDate) {
      var m = new Date(new Date(this.endDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
      var dateString =
          m.getFullYear() + "-" +
          ("0" + (m.getMonth()+1)).slice(-2) + "-" +
          ("0" + m.getDate()).slice(-2) + " " +
          ("0" + m.getHours()).slice(-2) + ":" +
          ("0" + m.getMinutes()).slice(-2) + ":" +
          ("0" + m.getSeconds()).slice(-2);
      this.filteredItems = this.filteredItems.filter(option => option.validaty_date <= dateString.substring(0,10));
    } else {
      ch++;
    }
    if(ch == 4) {
      this.filteredItems = this.inquirys_old;
    }
    this.refreshItems();
  }
  
  restore(inquiry_id: any) {
    this.spinner.show();
    this.rest.uncloseInquiry(inquiry_id).subscribe(
      (res) => {
        this.spinner.hide();
        if(res.message == 'OK') {
          window.location.reload();
        } else {
          this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
        }
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
  );

  }
}
