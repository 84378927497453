import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMAGE_FILE_URL } from '../../../environments/environment';

@Component({
  selector: 'app-report-user',
  templateUrl: './report-user.component.html',
  styleUrls: ['./report-user.component.scss'],
})
export class ReportUserComponent implements OnInit {
  id;
  sdate;
  edate;
  type = '';
  rtype;
  user_report;
  count = 0;
  imageFileUrl = IMAGE_FILE_URL;

  constructor(public appService: AppService, public data: DataProvider, public router: Router,
    private toastr: ToastrService, private rest: CallapiService,private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {
    data.menuPageTitle = 'reports';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.sdate = this.route.snapshot.paramMap.get('start_date');
    this.edate = this.route.snapshot.paramMap.get('end_date');
    this.type = this.route.snapshot.paramMap.get('type');
    this.rtype = this.route.snapshot.paramMap.get('rtype');

    this.spinner.show();
    this.rest.reportUserList(this.id, this.rtype, this.sdate, this.edate).subscribe((res) =>{
      this.spinner.hide();
      this.user_report = res;
      for(let i = 0; i < this.user_report.trans.length; i++) {
        this.count = this.count + this.user_report.trans[i].quantity;
      }
      if(this.user_report.profit != null) {
        this.user_report.profit = this.user_report.profit - this.user_report.profit/10;
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
}
