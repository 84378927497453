import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-receivable',
  templateUrl: './receivable.component.html',
  styleUrls: ['./receivable.component.scss'],
})

export class ReceivableComponent implements OnInit {
  public id: any;
  countries = [];
  Consignee = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  nameFormControl = new FormControl();
  consignee: any;
	total = 13;
  loading = false;
  searchTerm = '';
  page = 1;
  filteredItems = [];
  receivables_old = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  consignees = [];
  consigneesOrig = [];
  consignee_id;
  startDate;
  receivables =[];
  allTotal = 0;

  constructor(
    public appService: AppService,
    public data: DataProvider,
    public router: Router,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    ) {
    data.menuPageTitle = 'receivable';
  }

  public _filterCons() {
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();    
    this.consignees = this.consigneesOrig.filter(option => option.name.toLowerCase().includes(filterValue));
    if(this.consignees.length == 0) {
      this.consignee_id = input.value;
    }
  }

  onConsigneeSelected(option: any) {
    for(let i = 0; i < this.consignees.length; i++) {
      if(this.consignees[i].name == option.value) {
        this.consignee_id = this.consignees[i].id;
        break;
      }
    }
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.spinner.show();
    this.rest.financeList().subscribe(
      (res) => {
        for(let i = 0; i< res.length; i++){
          res[i].released_at = res[i].released_at.substring(0, 10);
          res[i].so_start_date = res[i].so_start_date.substring(0, 10);
        }
        this.receivables = res;
        for(let i = 0; i < this.receivables.length; i++) {
          this.allTotal = this.allTotal + this.receivables[i].price*this.receivables[i].price_rate;
        }
        this.rest.consignees().subscribe(
          (res) => {
            this.consignees = res.consignees;
            this.consigneesOrig = res.consignees;
            this.spinner.hide();
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          }
        );
    
        this.filteredItems = this.receivables;
        this.receivables_old = this.receivables;
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;
        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
    this.filteredItems = this.receivables;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;
    this.pageNumber = parseInt(
      '' + this.filteredItems.length / this.pageSize
    );
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }
    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.receivables = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  clear() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.searchTerm = '';
    this.consignee_id = '';
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    input.value = '';
    this.startDate = '';
    this.filteredItems = this.receivables_old;
    this.refreshItems();
  }

  search() {
    let ch = 0;
    if(this.searchTerm) {
      this.filteredItems = this.filteredItems.filter(option => option.shipping_order_name.toLowerCase().includes(this.searchTerm));
    } else {
      ch++;
    }
    if(this.consignee_id) {
      this.filteredItems = this.filteredItems.filter(option => option.consignee_id == this.consignee_id);
    } else {
      ch++;
    }
    if(this.startDate) {
      var m = new Date(new Date(this.startDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
      var dateString =
          m.getFullYear() + "-" +
          ("0" + (m.getMonth()+1)).slice(-2) + "-" +
          ("0" + m.getDate()).slice(-2) + " " +
          ("0" + m.getHours()).slice(-2) + ":" +
          ("0" + m.getMinutes()).slice(-2) + ":" +
          ("0" + m.getSeconds()).slice(-2);
      this.filteredItems = this.filteredItems.filter(option => option.so_start_date >= dateString.substring(0,10));
    } else {
      ch++;
    }
    if(ch == 3) {
      this.filteredItems = this.receivables_old;
    }
    this.refreshItems();
  }

  shippingOrderBack(id: any) {
    this.router.navigate(['/shipping-order/edit', {id: id}]);
  }

  download(id: any, tr: any) {
    this.spinner.show();
    if(tr != null) {
      this.rest.payableFinish(id).subscribe((res) => {
        this.spinner.hide();
        if(res.message == 'OK') {
          this.toastr.success('Success', 'Амжилттай төлсөн.');
          window.location.reload();
        } else {
          this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
        }
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
    } else {
      this.rest.invoiceFinish(id, 'y').subscribe((res) => {
        this.spinner.hide();
        if(res.message == 'OK') {
          this.toastr.success('Success', 'Амжилттай дуусгах хүсэлт илгээлээ.');
        } else {
          this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
        }
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
    }
  }

  print(id: any, pre_payment: any) {
    
  }

  exportExcel() { 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Receivable');
    worksheet.columns = [
      { header: 'SO/No', key: 'No', width: 15 },
      { header: 'Payer', key: 'Payer', width: 32 },
      { header: 'Total', key: 'Total', width: 10 },
      { header: 'Currency', key: 'Currency', width: 10 },
      { header: 'Rate', key: 'Rate', width: 10 },
      { header: 'Total(MNT)', key: 'TotalMNT', width: 10 },
      { header: 'Income Amount(MNT)', key: 'Income', width: 10 },
      { header: 'Rest Amount(MNT)', key: 'Rest', width: 10 },
      { header: 'Confirmed At', key: 'Confirmed', width: 10 },
    ];

    for(let i = 0; i < this.receivables_old.length; i++) {
      worksheet.addRow(
        {
          No: this.receivables_old[i].shipping_order_name, 
          Payer: this.receivables_old[i].payer_name, 
          Total: this.receivables_old[i].price, 
          Currency: this.receivables_old[i].currency_name,
          Rate: this.receivables_old[i].price_rate,
          TotalMNT: this.receivables_old[i].price,
          Income: 0.00,
          Rest: this.receivables_old[i].price,
          Confirmed: this.receivables_old[i].confirmed
        }, 'n'
      );
    }
 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Receivable.xlsx');
    })
  }
}
