import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/utils/services/app.service';
import { console_log, DataProvider } from 'src/providers/data/data';
import { map, startWith } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { ENCRYPT_SECRET_KEY } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMAGE_FILE_URL } from '../../../environments/environment';

export interface User {
  name: string;
}


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss'],
})
export class InquiryComponent implements OnInit {
  Assignee = new FormControl('', [Validators.required]);
  Commodity = new FormControl('', [Validators.required]);
  Supplier = new FormControl('', [Validators.required]);
  PolCountry = new FormControl('', [Validators.required]);
  FinalCountry = new FormControl('', [Validators.required]);
  PolCity = new FormControl('', [Validators.required]);
  FinalCity = new FormControl('', [Validators.required]);
  divisionCon = new FormControl('', [Validators.required]);
  condiotionCon = new FormControl('', [Validators.required]);
  transportMode = new FormControl('', [Validators.required]);
  transportType = new FormControl('', [Validators.required]);
  incotermsCon = new FormControl('', [Validators.required]);
  inquiryType = new FormControl('', [Validators.required]);
  borderReq = new FormControl('', [Validators.required]);
  nameFormControl = new FormControl('', [Validators.required]);
  consignees = [];
  consigneesOrig = [];
  suppliers = [];
  suppliersOrig = [];
  polCountry = [];
  polCountryOrig = [];
  polCity = [];
  polCityOrig = [];
  finalCountry = [];
  finalCountryOrig = [];
  finalCity = [];
  finalCityOrig = [];
  userTable: FormGroup;
  control: FormArray;
  panelOpenState = false;
  isEnabled = false;
  public chk;
  public PriceOffer = 0.0;
  public ProfitMNT = -100000.0;
  public ProfitUSD = -28.49;
  public ProfitRMB = -181.82;
  public ProfitEURO = -26.18;
  public PureProfit = -100000.0;

  public name;
  public consignee_id = '';
  public  commodity;
  public division_id;
  public condition_id;
  public trans_mode_id;
  public trans_type_id;
  public incoterm_id;
  public incoterm_address;
  public inquiry_type_id;
  public supplier_id;
  public is_vat = 1;
  public pol_country_id;
  public pol_city_id;
  public pol_address;
  public border_id;
  public final_country_id;
  public final_city_id;
  public final_address;
  public via_id;
  public remark_comment;
  public comment_tariff;
  public closed_comment;
  public quotation_date;
  public validaty_date;
  public so_start_date;
  public sales_id;
  public tariff_id;
  public created_at;

  public type;
  public box_dup = 1;
  public commodity_file;
  public box_length;
  public box_width;
  public box_height;
  public box_weight;
  public volume_air;
  public cbm;
  public quantity;
  public boxLength = [];
  public tableDimension =[];
  // priceControl: FormArray;
  mode: boolean;
  touchedRows: any;
  public id: any;
  public dup_id: any;
  public agent_id;

  Division: [];
  Condiotion: [];
  Transportation_type:[];
  Transportation_mode:[];
  incoterms: [];
  SalesManager: [];
  TariffManager:[];
  Border:[];
  inquiry_type: [];
  VIA: [];
  Currency = [];
  Agent: [];
  priceArr: FormArray;
  priceArrOrig = [];
  users = [];
  sales_users = [];
  tariff_users = [];
  reasons = [];
  weights = [];
  public gross_weight;
  public gross_cbm;
  hemjees = 'cm';

  addForm: FormGroup;
  rows: FormArray;
  price: FormGroup;
  priceRows: FormArray;
  expenses: FormGroup;
  expensesRow: FormArray;
  imageFile;
  itemForm: FormGroup;

  matcher = new MyErrorStateMatcher();
  public isNew = true;
  closeResult: string;
  box_file;
  quotation_currency_id = 0;
  quotation_price;
  quotation_rate = 1;
  quotation_profit = 0;
  quotation_transit_time;
  quotations = [];
  reason_id = 0;
  update_user;

  total_weight = 0;
  total_volume = 0;
  total_cbm = 0;
  total_quantity = 0;

  trace_price_china = 0;
  trace_price_usd = 0;
  trace_price_mnt = 0;

  expences_quantity = 0;
  expences_percent = 0;
  expences_mnt = 0;
  expences_total = 0;
  expences_price = 0;
  expences_rate = 0;
  expences_vat = 0;
  expense_rate_name = 'RMB-MGB Rate';
  back_to_tariff = 0;
  expense_total_name = 'Нийт зардлын дүн /MNT/';
  expences_total_cur = 0;
  expences_price_cur = 0;
  expences_vat_cur = 0;
  trace_price_confirmed = 0;
  price_arr_id = 0;
  new_tariff_prices = [];
  new_tariff_prices_copy = [];
  shipping_order_type = '';
  tariff_type = '';
  op_type;
  inquiry_tariff_prices_first = [];

  current_url = '';
  transtimes = [];
  time_id = 0;
  imageFileUrl = IMAGE_FILE_URL;
  orig_inquiry_type;
  so_inquiry_type;

  constructor(
    public data: DataProvider,
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private fb: FormBuilder,
    private pr: FormBuilder,
    private ex: FormBuilder,
    private modalService: NgbModal,
    public rest: CallapiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    data.menuPageTitle = 'Inquiry';

    this.addForm = this.fb.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required]
    });

    this.price = this.pr.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required]
    });

    this.expenses = this.ex.group({
      items: [null, Validators.required],
      items_value: ['no', Validators.required]
    });

    this.rows = this.fb.array([]);
    this.priceRows = this.pr.array([]);
    this.expensesRow = this.ex.array([]);
  }

  setFileName(fileInput) {
    let files: File[] = fileInput.files;
    if (files.length < 1) {
      document.getElementById('img-label').innerHTML = 'Файл оруулах';
      return;
    }
    let file = files[0];
    const reader = new FileReader();
    let self = this;
    reader.onload = function(e) { 
      self.imageFile = e.target.result;
    };
    reader.readAsDataURL(file);
    document.getElementById('img-label').innerHTML = file.name;
  }

  public boxDupChange() {
    // console.log(this.box_dup);
  }

  public typeChange(){
    if(this.isNew) {
      localStorage.setItem('type', this.type);
    }
    if(this.type == "box") {
      this.onAddRow();
      document.getElementById('box').style.display = 'block';
      document.getElementById('full').style.display = 'none';
    } else if (this.type == "fullPlane") {
      for(let i = 0; i< this.rows.value.length; ){
        this.onRemoveRow(i);
      }
      document.getElementById('box').style.display = 'none';
      document.getElementById('full').style.display = 'block';
      document.getElementById('fullname').innerHTML = 'Бүтэн онгоц';
      this.box_dup = 0;
    } else if (this.type == "fullWagon") {
      for(let i = 0; i< this.rows.value.length; ){
        this.onRemoveRow(i);
      }
      document.getElementById('box').style.display = 'none';
      document.getElementById('full').style.display = 'block';
      document.getElementById('fullname').innerHTML = 'Бүтэн вагон';
      this.box_dup = 0;
    } else {
      for(let i = 0; i< this.rows.value.length; ){
        this.onRemoveRow(i);
      }
      document.getElementById('box').style.display = 'none';
      document.getElementById('full').style.display = 'block';
      document.getElementById('fullname').innerHTML = 'Бүтэн машин';
      this.box_dup = 0;
    }

  }

  addPrice() {
    if(this.price_arr_id != 0 && this.price_arr_id != this.priceArrOrig.length) {
      this.modalService.dismissAll();
      // this.priceRows.push(this.priceFormGroup());
      this.priceRows.insert(this.priceRows.length - 1, this.priceFormGroup());
      // this.priceFormGroup().concat(this.priceRows);
    }
  }

  addPriceOne(id) {
    this.new_tariff_prices.push(id + 1);
    this.new_tariff_prices_copy.push(id);
    this.new_tariff_prices_copy.push(id + 1);
    this.trace_price_mnt = this.trace_price_mnt - this.priceRows.value[id].amount_orig;
    this.trace_price_china = parseFloat((this.trace_price_mnt/this.Currency[2].rate).toFixed(2));
    this.trace_price_usd = parseFloat((this.trace_price_mnt/this.Currency[0].rate).toFixed(2));
    this.priceRows.insert(id + 1, 
    this.pr.group({
      agent_id: this.priceRows.value[id].agent_id,
      id: null,
      currency: this.priceRows.value[id].currency,
      name: this.priceRows.value[id].name,
      price: null,
      rate: {disabled: true, value: 1},
      rate_orig: 1,
      amount: {disabled: true, value: 0},
      amount_orig: 0,
      is_paid: null,
    }));
  }

  deletePrice(e) {
    if(e < this.priceRows.length - 1) {
      if(this.new_tariff_prices.includes(e)) {
        this.new_tariff_prices.splice(this.new_tariff_prices.indexOf(e), 1);
        this.new_tariff_prices_copy.splice(this.new_tariff_prices_copy.indexOf(e) - 1, 1);
        this.new_tariff_prices_copy.splice(this.new_tariff_prices_copy.indexOf(e), 1);
        this.trace_price_mnt = this.trace_price_mnt - this.priceRows.value[e].amount_orig;
        this.trace_price_mnt = this.trace_price_mnt + this.priceRows.value[e - 1].amount_orig;
      } else {
        this.trace_price_mnt = this.trace_price_mnt - this.priceRows.value[e].amount_orig;
      }      
      this.trace_price_china = parseFloat((this.trace_price_mnt/this.Currency[2].rate).toFixed(2));
      this.trace_price_usd = parseFloat((this.trace_price_mnt/this.Currency[0].rate).toFixed(2));
      this.priceRows.removeAt(e);
    }
  }

  priceFormGroup(): FormGroup {
    return this.pr.group({
      agent_id: null,
      id: null,
      currency:null,
      name: this.priceArr[this.price_arr_id - 1].name,
      price: null,
      rate: {disabled: true, value: 1},
      rate_orig: 1,
      amount: {disabled: true, value: 0},
      amount_orig: 0,
      is_paid: null,
    });
  }

  addExpenses() {
    this.expensesRow.push(this.expensesFormGroup());
  }

  deleteExpenses(rowIndex:number) {
    let per = this.expensesRow.value[rowIndex].percentage;
    this.expensesRow.removeAt(rowIndex);
    this.expenseCalc(per);
    this.changeQuoPrice();
  }

  expensesFormGroup(): FormGroup {
    return this.ex.group({
      id: null,
      engname: null,
      name: null,
      percentage: null,
      price: {disabled: true, value: null},
      price_orig: null,
      quantity: null,
      rate: {disabled: true, value: 1},
      rate_orig: 1,
      vat: {disabled: true, value: 0},
      vat_orig: 0,
      total_amount: {disabled: true, value: 1},
      total_orig: 1,
    });
  }
  expensesRow1 = [];

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id);
    if(this.id != null) {
      this.isNew = false;
      this.current_url = 'inquiry/edit;id=' + this.id;
    } else {
      this.current_url = 'inquiry/add';
    }    
    this.panelOpenState = true;

    this.spinner.show();
    this.rest.inquiryHelpers().subscribe(
      (res) => {
        this.Division = res.divisions;
        this.Condiotion = res.conditions;
        this.Transportation_mode = res.transport_modes;
        this.incoterms = res.incoterms;
        this.inquiry_type = res.inquiry_types;
        this.Border = res.borders;
        this.VIA = res.vias;
        this.Agent = res.agents;
        this.Currency = res.currency;
        this.consignees = res.consignees;
        this.consigneesOrig = res.consignees;
        this.suppliersOrig = res.suppliers;
        this.suppliers = res.suppliers;
        this.polCountry = res.countries;
        this.polCountryOrig = res.countries;
        this.finalCountry = res.countries;
        this.finalCountryOrig = res.countries;
        this.priceArr = res.newoffers;
        this.priceArrOrig = res.newoffers;
        // this.priceArrOrig.splice(this.priceArrOrig.length - 1, 1);
        this.users = res.users;
        this.reasons = res.reasons;
        this.transtimes = res.transtimes;
        for(let i = 0; i < res.users.length; i++) {
          if(res.users[i].permission == 4) {
            this.sales_users.push(res.users[i]);
          }
          if(res.users[i].permission == 5) {
            this.tariff_users.push(res.users[i]);
          }
        }
        if(this.isNew) {
          this.expensesRow1 = res.newexpenses;
          for(var i = 0 ; i < this.expensesRow1.length; i++){
            this.expensesRow.push(this.ex.group({
              id: this.expensesRow1[i].id,
              engname: this.expensesRow1[i].engname,
              name: this.expensesRow1[i].name,
              percentage: this.expensesRow1[i].percentage,
              price: {disabled: true, value: this.expensesRow1[i].price},
              price_orig: this.expensesRow1[i].price,
              quantity: this.expensesRow1[i].quantity,
              rate: {disabled: true, value: 1},
              rate_orig: 1,
              vat: {disabled: true, value: 0},
              vat_orig: 0,
              total_amount: {disabled: true, value: 1},
              total_orig: 1,
            }));
          }
  
          for(var i = this.priceArr.length - 1 ; i < this.priceArr.length; i++){
            this.priceRows.push(this.pr.group({
              agent_id:  this.priceArr[i].agent_id,
              id: this.priceArr[i].id,
              currency:this.priceArr[i].currency,
              name: this.priceArr[i].name,
              price: this.priceArr[i].price,
              rate: {disabled: true, value: this.priceArr[i].rate},
              rate_orig: 1,
              amount: {disabled: true, value: 0},
              amount_orig: 0,
              is_paid: this.priceArr[i].is_paid,
            }));
          }

          // localStorage
          if(this.route.snapshot.paramMap.get('dup_id') != null) {
            this.dup_id = this.route.snapshot.paramMap.get('dup_id');
            this.current_url = 'inquiry/add;dup_id=' + this.dup_id;
            this.getDuplicateDetail();
          } else {
            if(localStorage.getItem('perm') && localStorage.getItem('perm') == '4') {
              if(localStorage.getItem('name')) {
                this.name = localStorage.getItem('name');
              }
              if(localStorage.getItem('consignee_id')) {
                this.consignee_id = localStorage.getItem('consignee_id');
                const input = document.getElementById(
                  'consignee',
                ) as HTMLInputElement | null;    
                input.value = localStorage.getItem('consignee_name');
              }
              if(localStorage.getItem('commodity')) {
                this.commodity = localStorage.getItem('commodity');
              }
              if(localStorage.getItem('division_id')) {
                this.division_id = parseInt(localStorage.getItem('division_id'));
              }
              if(localStorage.getItem('condition_id')) {
                this.condition_id = parseInt(localStorage.getItem('condition_id'));
              }
              if(localStorage.getItem('trans_mode_id')) {
                this.trans_mode_id = parseInt(localStorage.getItem('trans_mode_id'));
                this.transportChange();
              }
              if(localStorage.getItem('trans_type_id')) {
                this.trans_type_id = parseInt(localStorage.getItem('trans_type_id'));
              }
              if(localStorage.getItem('incoterm_id')) {
                this.incoterm_id = parseInt(localStorage.getItem('incoterm_id'));
              }
              if(localStorage.getItem('incoterm_address')) {
                this.incoterm_address = localStorage.getItem('incoterm_address');
              }
              if(localStorage.getItem('inquiry_type_id')) {
                this.inquiry_type_id = parseInt(localStorage.getItem('inquiry_type_id'));
              }
              // if(localStorage.getItem('supplier_id')) {
              //   this.supplier_id = localStorage.getItem('supplier_id');
              //   const input2 = document.getElementById(
              //     'supplier',
              //   ) as HTMLInputElement | null;    
              //   input2.value = localStorage.getItem('supplier_name');
              // }
              if(localStorage.getItem('is_vat')) {
                this.is_vat = localStorage.getItem('is_vat') == 'true' ? 1 : 0;
              }
              if(localStorage.getItem('type')) {
                this.type = localStorage.getItem('type');
              }
              if(localStorage.getItem('gross_weight')) {
                this.gross_weight = localStorage.getItem('gross_weight');
              }
              if(localStorage.getItem('gross_cbm')) {
                this.gross_cbm = localStorage.getItem('gross_cbm');
              }
              if(localStorage.getItem('box_dup')) {
                this.box_dup = parseInt(localStorage.getItem('box_dup'));
              }
              if(localStorage.getItem('hemjees')) {
                this.hemjees = localStorage.getItem('hemjees');
              }
              if(localStorage.getItem('weights')) {
                // this.rows.push(JSON.parse(localStorage.getItem('weights')));
                if(this.type == 'box') {
                  // this.onAddRow();
                  document.getElementById('box').style.display = 'block';
                  document.getElementById('full').style.display = 'none';
                  let weights = JSON.parse(localStorage.getItem('weights'));
                  for(var i = 0 ; i < weights.length; i++){
                    this.rows.push(this.fb.group({
                      type: weights[i].type,                  
                      box_length: weights[i].box_length,
                      box_width: weights[i].box_width,
                      box_height: weights[i].box_height,
                      box_weight: weights[i].box_weight,                  
                      box_total_weight: weights[i].box_weight*weights[i].quantity,
                      volume_air: weights[i].volume_air,
                      total_volume_air: weights[i].volume_air*weights[i].quantity,
                      cbm: weights[i].cbm,
                      total_cbm: weights[i].cbm*weights[i].quantity,
                      quantity: weights[i].quantity,
                    }));
                    this.totalWeight(i);
                  }              
                }
              }
              if(localStorage.getItem('pol_country_id')) {
                this.pol_country_id = parseInt(localStorage.getItem('pol_country_id'));
                const input2 = document.getElementById(
                  'country',
                ) as HTMLInputElement | null;    
                input2.value = localStorage.getItem('pol_country_name');
                this.onPolCountry();
              }
              if(localStorage.getItem('pol_city_id')) {
                this.pol_city_id = parseInt(localStorage.getItem('pol_city_id'));
                const input2 = document.getElementById(
                  'city',
                ) as HTMLInputElement | null;    
                input2.value = localStorage.getItem('pol_city_name');
              }
              if(localStorage.getItem('pol_address')) {
                this.pol_address = localStorage.getItem('pol_address');
              }
              if(localStorage.getItem('border_id')) {
                this.border_id = parseInt(localStorage.getItem('border_id'));
              }
              if(localStorage.getItem('final_country_id')) {
                this.final_country_id = parseInt(localStorage.getItem('final_country_id'));
                const input2 = document.getElementById(
                  'finalCountry',
                ) as HTMLInputElement | null;    
                input2.value = localStorage.getItem('final_country_name');
                this.onFinalCountry();
              }
              if(localStorage.getItem('final_city_id')) {
                this.final_city_id = parseInt(localStorage.getItem('final_city_id'));
                const input2 = document.getElementById(
                  'finalCity',
                ) as HTMLInputElement | null;    
                input2.value = localStorage.getItem('final_city_name');
              }
              if(localStorage.getItem('final_address')) {
                this.final_address = localStorage.getItem('final_address');
              }
              if(localStorage.getItem('via_id')) {
                this.via_id = parseInt(localStorage.getItem('via_id'));
              }
              if(localStorage.getItem('remark_comment')) {
                this.remark_comment = localStorage.getItem('remark_comment');
              }
              if(localStorage.getItem('quotation_date')) {
                this.quotation_date = new Date(localStorage.getItem('quotation_date'));
              }
              if(localStorage.getItem('validaty_date')) {
                this.validaty_date = new Date(localStorage.getItem('validaty_date'));
              }
              if(localStorage.getItem('tariff_id')) {
                this.tariff_id = parseInt(localStorage.getItem('tariff_id'));
              }
            }
            if(localStorage.getItem('perm') && localStorage.getItem('perm') == '5') {
              if(localStorage.getItem('quotation_transit_time')) {
                this.quotation_transit_time = localStorage.getItem('quotation_transit_time');
              }
              if(localStorage.getItem('comment_tariff')) {
                this.comment_tariff = localStorage.getItem('comment_tariff');
              }
              if(localStorage.getItem('tariff_prices')) {
                let tariff_prices = JSON.parse(localStorage.getItem('tariff_prices'));
                for(var i = 0 ; i < tariff_prices.length; i++){
                  this.priceRows.push(this.pr.group({
                    agent_id: tariff_prices[i].agent_id,
                    id: tariff_prices[i].id,
                    currency:tariff_prices[i].currency,
                    name: tariff_prices[i].name,
                    price: tariff_prices[i].price,
                    rate: {disabled: true, value: tariff_prices[i].rate},
                    rate_orig: tariff_prices[i].rate,
                    amount: {disabled: true, value: tariff_prices[i].price},
                    amount_orig: tariff_prices[i].price,
                    is_paid: tariff_prices[i].is_paid,
                  }));
                }
              }
            }
          }
        }

        if(this.id != null) {
          this.rest.inquiryDetail(this.id).subscribe((res3) => {
            this.spinner.hide();
            this.name = res3.detail.name;
            const input = document.getElementById(
              'consignee',
            ) as HTMLInputElement | null;    
            input.value = res3.detail.consignee_name;
            this.consignee_id = res3.detail.consignee_id;
            this.commodity = res3.detail.commodity;
            this.division_id = res3.detail.division_id;
            this.condition_id = res3.detail.condition_id;
            this.trans_mode_id = res3.detail.trans_mode_id;
            this.transportChange();
            this.trans_type_id = res3.detail.trans_type_id;
            this.incoterm_id = res3.detail.incoterm_id;
            this.incoterm_address = res3.detail.incoterm_address;
            this.inquiry_type_id = res3.detail.inquiry_type_id;
            this.gross_weight = res3.detail.gross_weight;
            this.gross_cbm = res3.detail.gross_cbm;
            this.box_dup = res3.detail.box_dup;
            this.quotation_transit_time = res3.detail.transit_time;
            this.hemjees = res3.detail.hemjees;
            this.tariff_type = res3.detail.tariff_type;
            this.op_type = res3.detail.op_type;
            this.orig_inquiry_type = res3.detail.type;
            this.so_inquiry_type = res3.detail.inquiry_type;
            if(res3.detail.inquiry_type != 'operation') {
              this.Currency.splice(6);
            }
            const input1 = document.getElementById(
              'supplier',
            ) as HTMLInputElement | null;    
            input1.value = res3.detail.supplier_name;
            this.supplier_id = res3.detail.supplier_id;
            if(res3.detail.is_vat == 'y') {
              this.is_vat = 1;
            } else {
              this.is_vat = 0;
            }
            this.type = res3.inquiry_weights[0].type;
            if(this.type == 'box') {
              // this.onAddRow();
              document.getElementById('box').style.display = 'block';
              document.getElementById('full').style.display = 'none';
              for(var i = 0 ; i < res3.inquiry_weights.length; i++){
                this.rows.push(this.fb.group({
                  type: res3.inquiry_weights[i].type,                  
                  box_length: res3.inquiry_weights[i].box_length,
                  box_width: res3.inquiry_weights[i].box_width,
                  box_height: res3.inquiry_weights[i].box_height,
                  box_weight: res3.inquiry_weights[i].box_weight,                  
                  box_total_weight: res3.inquiry_weights[i].box_weight*res3.inquiry_weights[i].quantity,
                  volume_air: res3.inquiry_weights[i].volume_air,
                  total_volume_air: res3.inquiry_weights[i].volume_air*res3.inquiry_weights[i].quantity,
                  cbm: res3.inquiry_weights[i].cbm,
                  total_cbm: res3.inquiry_weights[i].cbm*res3.inquiry_weights[i].quantity,
                  quantity: res3.inquiry_weights[i].quantity,
                }));
                this.totalWeight(i);
              }              
            } else {
              document.getElementById('box').style.display = 'none';
              document.getElementById('full').style.display = 'block';
              if(this.type == 'fullPlane') {
                document.getElementById('fullname').innerHTML = 'Бүтэн онгоц';
              } else {
                document.getElementById('fullname').innerHTML = 'Бүтэн вагон';
              }
              this.box_file = res3.inquiry_weights[0].commodity_file;
              this.commodity_file = res3.inquiry_weights[0].commodity_file;
            }
            const input2 = document.getElementById(
              'country',
            ) as HTMLInputElement | null;    
            input2.value = res3.detail.pol_country_name;
            this.pol_country_id = res3.detail.pol_country_id;
            this.onPolCountry();
            const input3 = document.getElementById(
              'city',
            ) as HTMLInputElement | null;    
            input3.value = res3.detail.pol_city_name;
            this.pol_city_id = res3.detail.pol_city_id;
            const input4 = document.getElementById(
              'finalCountry',
            ) as HTMLInputElement | null;    
            input4.value = res3.detail.final_country_name;
            this.final_country_id = res3.detail.final_country_id;
            this.onFinalCountry();
            const input5 = document.getElementById(
              'finalCity',
            ) as HTMLInputElement | null;    
            input5.value = res3.detail.final_city_name;
            this.final_city_id = res3.detail.final_city_id;
            this.pol_address = res3.detail.pol_address;
            this.final_address = res3.detail.final_address;
            this.border_id = res3.detail.border_id;
            this.via_id = res3.detail.via_id;
            this.remark_comment = res3.detail.remark_comment;
            this.comment_tariff = res3.detail.tariff_comment;
            this.quotation_date = new Date(res3.detail.quotation_date);
            this.validaty_date = new Date(res3.detail.validaty_date);
            this.sales_id = res3.detail.sales_id;
            this.tariff_id = res3.detail.tariff_id;
            this.update_user = res3.detail.update_user;
            this.shipping_order_type = res3.detail.type;

            if(res3.inquiry_expences.length == 0) {
              this.expensesRow1 = res.newexpenses;
              let rand = Math.random() * (90 - 75) + 75;
              // console.log(rand.toFixed(0));
              for(var i = 0 ; i < this.expensesRow1.length; i++){
                if(i == 0) {
                  this.expensesRow.push(this.ex.group({
                    id: this.expensesRow1[i].id,
                    engname: this.expensesRow1[i].engname,
                    name: this.expensesRow1[i].name,
                    percentage: parseInt(rand.toFixed(0)),
                    price: {disabled: true, value: this.expensesRow1[i].price},
                    price_orig: this.expensesRow1[i].price,
                    quantity: this.expensesRow1[i].quantity,
                    rate: {disabled: true, value: 1},
                    rate_orig: 1,
                    vat: {disabled: true, value: 0},
                    total_amount: {disabled: true, value: 1},
                    total_orig: 1,
                  }));
                } else if(i == 1) {
                  this.expensesRow.push(this.ex.group({
                    id: this.expensesRow1[i].id,
                    engname: this.expensesRow1[i].engname,
                    name: this.expensesRow1[i].name,
                    percentage: (96 - parseInt(rand.toFixed(0))),
                    price: {disabled: true, value: this.expensesRow1[i].price},
                    price_orig: this.expensesRow1[i].price,
                    quantity: this.expensesRow1[i].quantity,
                    rate: {disabled: true, value: 1},
                    rate_orig: 1,
                    vat: {disabled: true, value: 0},
                    total_amount: {disabled: true, value: 1},
                    total_orig: 1,
                  }));
                } else {
                  this.expensesRow.push(this.ex.group({
                    id: this.expensesRow1[i].id,
                    engname: this.expensesRow1[i].engname,
                    name: this.expensesRow1[i].name,
                    percentage: this.expensesRow1[i].percentage,
                    price: {disabled: true, value: this.expensesRow1[i].price},
                    price_orig: this.expensesRow1[i].price,
                    quantity: this.expensesRow1[i].quantity,
                    rate: {disabled: true, value: 1},
                    rate_orig: 1,
                    vat: {disabled: true, value: 0},
                    total_amount: {disabled: true, value: 1},
                    total_orig: 1,
                  }));
                }
              }
            } else {
              this.expensesRow1 = res3.inquiry_expences;
              for(var i = 0 ; i < this.expensesRow1.length; i++){
                this.expensesRow.push(this.ex.group({
                  id: this.expensesRow1[i].id,
                  engname: this.expensesRow1[i].engname,
                  name: this.expensesRow1[i].name,
                  percentage: this.expensesRow1[i].percentage,
                  price: {disabled: true, value: this.expensesRow1[i].price},
                  price_orig: this.expensesRow1[i].price,
                  quantity: this.expensesRow1[i].quantity,
                  rate: {disabled: true, value: this.expensesRow1[i].rate},
                  rate_orig: this.expensesRow1[i].rate,
                  vat: {disabled: true, value: this.expensesRow1[i].vat},
                  vat_orig: this.expensesRow1[i].vat,
                  total_amount: {disabled: true, value: this.expensesRow1[i].total_amount},
                  total_orig: this.expensesRow1[i].total_amount,
                }));
              }
            }
            
            this.inquiry_tariff_prices_first = res3.inquiry_tariff_prices_first;
            if(res3.inquiry_tariff_prices_first.length == 0) {
              for(var i = this.priceArr.length - 1 ; i < this.priceArr.length; i++){
                if(i == this.priceArr.length - 1) {
                  this.priceRows.push(this.pr.group({
                    agent_id: this.priceArr[i].agent_id,
                    id: this.priceArr[i].id,
                    currency:this.priceArr[i].currency,
                    name: this.priceArr[i].name,
                    price: this.priceArr[i].price,
                    rate: {disabled: true, value: this.priceArr[i].rate},
                    rate_orig: this.priceArr[i].rate,
                    amount: {disabled: true, value: this.priceArr[i].price},
                    amount_orig: this.priceArr[i].price,
                    is_paid: this.priceArr[i].is_paid,
                  }));
                } else {
                  this.priceRows.push(this.pr.group({
                    agent_id: this.priceArr[i].agent_id,
                    id: this.priceArr[i].id,
                    currency:this.priceArr[i].currency,
                    name: this.priceArr[i].name,
                    price: this.priceArr[i].price,
                    rate: {disabled: true, value: this.priceArr[i].rate},
                    rate_orig: this.priceArr[i].rate,
                    amount: {disabled: true, value: 0},
                    amount_orig: 0,
                    is_paid: this.priceArr[i].is_paid,
                  }));
                }
                // this.changeTraceCurrency(i);
              }
            } else {
              let rr = 0;
              for(var i = 0 ; i < res3.inquiry_tariff_prices_first.length; i++){
                this.priceRows.push(this.pr.group({
                  agent_id:  res3.inquiry_tariff_prices_first[i].agent_id,
                  id: res3.inquiry_tariff_prices_first[i].id,
                  currency: res3.inquiry_tariff_prices_first[i].currency,
                  name: res3.inquiry_tariff_prices_first[i].name,
                  price: res3.inquiry_tariff_prices_first[i].price,
                  rate: {disabled: true, value: res3.inquiry_tariff_prices_first[i].rate},
                  rate_orig: res3.inquiry_tariff_prices_first[i].rate,
                  amount: {disabled: true, value: res3.inquiry_tariff_prices_first[i].amount},
                  amount_orig: res3.inquiry_tariff_prices_first[i].amount,
                  is_paid: res3.inquiry_tariff_prices_first[i].is_paid,
                }));
                if(res3.inquiry_tariff_prices_first[i].price != res3.inquiry_tariff_prices_last[i].price) {
                  this.new_tariff_prices.push(i + 1 + rr);
                  this.new_tariff_prices_copy.push(i + rr);
                  this.new_tariff_prices_copy.push(i + 1 + rr);
                  this.priceRows.push(this.pr.group({
                    agent_id:  res3.inquiry_tariff_prices_last[i].agent_id,
                    id: res3.inquiry_tariff_prices_last[i].id,
                    currency: res3.inquiry_tariff_prices_last[i].currency,
                    name: res3.inquiry_tariff_prices_last[i].name,
                    price: res3.inquiry_tariff_prices_last[i].price,
                    rate: {disabled: true, value: res3.inquiry_tariff_prices_last[i].rate},
                    rate_orig: res3.inquiry_tariff_prices_last[i].rate,
                    amount: {disabled: true, value: res3.inquiry_tariff_prices_last[i].amount},
                    amount_orig: res3.inquiry_tariff_prices_last[i].amount,
                    is_paid: res3.inquiry_tariff_prices_last[i].is_paid,
                  }));
                  rr++;
                }                
                this.changeTraceCurrency(i);
              }
              for(i = res3.inquiry_tariff_prices_first.length; i < res3.inquiry_tariff_prices_last.length; i++){
                this.priceRows.push(this.pr.group({
                  agent_id:  res3.inquiry_tariff_prices_last[i].agent_id,
                  id: res3.inquiry_tariff_prices_last[i].id,
                  currency: res3.inquiry_tariff_prices_last[i].currency,
                  name: res3.inquiry_tariff_prices_last[i].name,
                  price: res3.inquiry_tariff_prices_last[i].price,
                  rate: {disabled: true, value: res3.inquiry_tariff_prices_last[i].rate},
                  rate_orig: res3.inquiry_tariff_prices_last[i].rate,
                  amount: {disabled: true, value: res3.inquiry_tariff_prices_last[i].amount},
                  amount_orig: res3.inquiry_tariff_prices_last[i].amount,
                  is_paid: res3.inquiry_tariff_prices_last[i].is_paid,
                }));
                this.changeTraceCurrency(i);
              }
            }
            // QUOTATION
            this.quotations = res3.inquiry_quotations;
            if(res3.inquiry_quotations.length > 0) {
              this.quotation_currency_id = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].currency_id;
              this.quotation_price = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].price;
              this.quotation_rate = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].rate;
              this.quotation_profit = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].profit;
              // this.quotation_transit_time = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].transit_time;
              this.expense_total_name = 'Нийт зардлын дүн /' + res3.inquiry_quotations[res3.inquiry_quotations.length - 1].currency_name +'/';
              this.changeQuoPriceFirst();
            }
            // this.changeQuoPrice();
            if(this.tariff_type != '' && this.data.userData.permission == 4) {
              this.toastr.error('Тээврийн үнэ өөрчлөгдсөн байна. Шалгаад зөвшөөрнө үү!', 'Мэдэгдэл!');
            }
          }, (err1) => {
            console.log(err1);
            this.spinner.hide();
          });
        } else {
          this.spinner.hide();
          this.Currency.splice(6);
        }
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );

    this.addForm.addControl('rows', this.rows);
    this.price.addControl('priceRows', this.priceRows);
    this.expenses.addControl('expensesRow', this.expensesRow);
  }

  getDuplicateDetail() {
    this.spinner.show();
    this.rest.inquiryDetail(this.dup_id).subscribe((res3) => {
      this.spinner.hide();
      this.name = res3.detail.name;
      const input = document.getElementById(
        'consignee',
      ) as HTMLInputElement | null;    
      input.value = res3.detail.consignee_name;
      this.consignee_id = res3.detail.consignee_id;
      this.commodity = res3.detail.commodity;
      this.division_id = res3.detail.division_id;
      this.condition_id = res3.detail.condition_id;
      this.trans_mode_id = res3.detail.trans_mode_id;
      this.transportChange();
      this.trans_type_id = res3.detail.trans_type_id;
      this.incoterm_id = res3.detail.incoterm_id;
      this.incoterm_address = res3.detail.incoterm_address;
      this.inquiry_type_id = res3.detail.inquiry_type_id;
      this.gross_weight = res3.detail.gross_weight;
      this.gross_cbm = res3.detail.gross_cbm;
      this.box_dup = res3.detail.box_dup;
      this.quotation_transit_time = res3.detail.transit_time;
      this.hemjees = res3.detail.hemjees;
      this.tariff_type = res3.detail.tariff_type;
      this.op_type = res3.detail.op_type;
      if(res3.detail.inquiry_type != 'operation') {
        this.Currency.splice(6);
      }
      const input1 = document.getElementById(
        'supplier',
      ) as HTMLInputElement | null;    
      input1.value = res3.detail.supplier_name;
      this.supplier_id = res3.detail.supplier_id;
      if(res3.detail.is_vat == 'y') {
        this.is_vat = 1;
      } else {
        this.is_vat = 0;
      }
      this.type = res3.inquiry_weights[0].type;
      if(this.type == 'box') {
        // this.onAddRow();
        document.getElementById('box').style.display = 'block';
        document.getElementById('full').style.display = 'none';
        for(var i = 0 ; i < res3.inquiry_weights.length; i++){
          this.rows.push(this.fb.group({
            type: res3.inquiry_weights[i].type,                  
            box_length: res3.inquiry_weights[i].box_length,
            box_width: res3.inquiry_weights[i].box_width,
            box_height: res3.inquiry_weights[i].box_height,
            box_weight: res3.inquiry_weights[i].box_weight,                  
            box_total_weight: res3.inquiry_weights[i].box_weight*res3.inquiry_weights[i].quantity,
            volume_air: res3.inquiry_weights[i].volume_air,
            total_volume_air: res3.inquiry_weights[i].volume_air*res3.inquiry_weights[i].quantity,
            cbm: res3.inquiry_weights[i].cbm,
            total_cbm: res3.inquiry_weights[i].cbm*res3.inquiry_weights[i].quantity,
            quantity: res3.inquiry_weights[i].quantity,
          }));
          this.totalWeight(i);
        }              
      } else {
        document.getElementById('box').style.display = 'none';
        document.getElementById('full').style.display = 'block';
        if(this.type == 'fullPlane') {
          document.getElementById('fullname').innerHTML = 'Бүтэн онгоц';
        } else {
          document.getElementById('fullname').innerHTML = 'Бүтэн вагон';
        }
        this.box_file = res3.inquiry_weights[0].commodity_file;
        this.commodity_file = res3.inquiry_weights[0].commodity_file;
      }
      const input2 = document.getElementById(
        'country',
      ) as HTMLInputElement | null;    
      input2.value = res3.detail.pol_country_name;
      this.pol_country_id = res3.detail.pol_country_id;
      this.onPolCountry();
      const input3 = document.getElementById(
        'city',
      ) as HTMLInputElement | null;    
      input3.value = res3.detail.pol_city_name;
      this.pol_city_id = res3.detail.pol_city_id;
      const input4 = document.getElementById(
        'finalCountry',
      ) as HTMLInputElement | null;    
      input4.value = res3.detail.final_country_name;
      this.final_country_id = res3.detail.final_country_id;
      this.onFinalCountry();
      const input5 = document.getElementById(
        'finalCity',
      ) as HTMLInputElement | null;    
      input5.value = res3.detail.final_city_name;
      this.final_city_id = res3.detail.final_city_id;
      this.pol_address = res3.detail.pol_address;
      this.final_address = res3.detail.final_address;
      this.border_id = res3.detail.border_id;
      this.via_id = res3.detail.via_id;
      this.remark_comment = res3.detail.remark_comment;
      this.comment_tariff = res3.detail.tariff_comment;
      this.quotation_date = new Date(res3.detail.quotation_date);
      this.validaty_date = new Date(res3.detail.validaty_date);
      this.sales_id = res3.detail.sales_id;
      this.tariff_id = res3.detail.tariff_id;
      this.update_user = res3.detail.update_user;
      this.shipping_order_type = res3.detail.type;

      if(res3.inquiry_expences.length == 0) {
        let rand = Math.random() * (90 - 75) + 75;
        for(var i = 0 ; i < this.expensesRow1.length; i++){
          if(i == 0) {
            this.expensesRow.push(this.ex.group({
              id: this.expensesRow1[i].id,
              engname: this.expensesRow1[i].engname,
              name: this.expensesRow1[i].name,
              percentage: parseInt(rand.toFixed(0)),
              price: {disabled: true, value: this.expensesRow1[i].price},
              price_orig: this.expensesRow1[i].price,
              quantity: this.expensesRow1[i].quantity,
              rate: {disabled: true, value: 1},
              rate_orig: 1,
              vat: {disabled: true, value: 0},
              total_amount: {disabled: true, value: 1},
              total_orig: 1,
            }));
          } else if(i == 1) {
            this.expensesRow.push(this.ex.group({
              id: this.expensesRow1[i].id,
              engname: this.expensesRow1[i].engname,
              name: this.expensesRow1[i].name,
              percentage: (96 - parseInt(rand.toFixed(0))),
              price: {disabled: true, value: this.expensesRow1[i].price},
              price_orig: this.expensesRow1[i].price,
              quantity: this.expensesRow1[i].quantity,
              rate: {disabled: true, value: 1},
              rate_orig: 1,
              vat: {disabled: true, value: 0},
              total_amount: {disabled: true, value: 1},
              total_orig: 1,
            }));
          } else {
            this.expensesRow.push(this.ex.group({
              id: this.expensesRow1[i].id,
              engname: this.expensesRow1[i].engname,
              name: this.expensesRow1[i].name,
              percentage: this.expensesRow1[i].percentage,
              price: {disabled: true, value: this.expensesRow1[i].price},
              price_orig: this.expensesRow1[i].price,
              quantity: this.expensesRow1[i].quantity,
              rate: {disabled: true, value: 1},
              rate_orig: 1,
              vat: {disabled: true, value: 0},
              total_amount: {disabled: true, value: 1},
              total_orig: 1,
            }));
          }
        }
      } else {
        this.expensesRow1 = res3.inquiry_expences;
        for(var i = 0 ; i < this.expensesRow1.length; i++){
          this.expensesRow.push(this.ex.group({
            id: this.expensesRow1[i].id,
            engname: this.expensesRow1[i].engname,
            name: this.expensesRow1[i].name,
            percentage: this.expensesRow1[i].percentage,
            price: {disabled: true, value: this.expensesRow1[i].price},
            price_orig: this.expensesRow1[i].price,
            quantity: this.expensesRow1[i].quantity,
            rate: {disabled: true, value: this.expensesRow1[i].rate},
            rate_orig: this.expensesRow1[i].rate,
            vat: {disabled: true, value: this.expensesRow1[i].vat},
            vat_orig: this.expensesRow1[i].vat,
            total_amount: {disabled: true, value: this.expensesRow1[i].total_amount},
            total_orig: this.expensesRow1[i].total_amount,
          }));
        }
      }
      
      this.inquiry_tariff_prices_first = res3.inquiry_tariff_prices_first;
      if(res3.inquiry_tariff_prices_first.length == 0) {
        for(var i = this.priceArr.length - 1 ; i < this.priceArr.length; i++){
          if(i == this.priceArr.length - 1) {
            this.priceRows.push(this.pr.group({
              agent_id: this.priceArr[i].agent_id,
              id: this.priceArr[i].id,
              currency:this.priceArr[i].currency,
              name: this.priceArr[i].name,
              price: this.priceArr[i].price,
              rate: {disabled: true, value: this.priceArr[i].rate},
              rate_orig: this.priceArr[i].rate,
              amount: {disabled: true, value: this.priceArr[i].price},
              amount_orig: this.priceArr[i].price,
              is_paid: this.priceArr[i].is_paid,
            }));
          } else {
            this.priceRows.push(this.pr.group({
              agent_id: this.priceArr[i].agent_id,
              id: this.priceArr[i].id,
              currency:this.priceArr[i].currency,
              name: this.priceArr[i].name,
              price: this.priceArr[i].price,
              rate: {disabled: true, value: this.priceArr[i].rate},
              rate_orig: this.priceArr[i].rate,
              amount: {disabled: true, value: 0},
              amount_orig: 0,
              is_paid: this.priceArr[i].is_paid,
            }));
          }
          // this.changeTraceCurrency(i);
        }
      } else {
        let rr = 0;
        for(var i = 0 ; i < res3.inquiry_tariff_prices_first.length; i++){
          this.priceRows.push(this.pr.group({
            agent_id:  res3.inquiry_tariff_prices_first[i].agent_id,
            id: res3.inquiry_tariff_prices_first[i].id,
            currency: res3.inquiry_tariff_prices_first[i].currency,
            name: res3.inquiry_tariff_prices_first[i].name,
            price: res3.inquiry_tariff_prices_first[i].price,
            rate: {disabled: true, value: res3.inquiry_tariff_prices_first[i].rate},
            rate_orig: res3.inquiry_tariff_prices_first[i].rate,
            amount: {disabled: true, value: res3.inquiry_tariff_prices_first[i].amount},
            amount_orig: res3.inquiry_tariff_prices_first[i].amount,
            is_paid: res3.inquiry_tariff_prices_first[i].is_paid,
          }));
          if(res3.inquiry_tariff_prices_first[i].price != res3.inquiry_tariff_prices_last[i].price) {
            this.new_tariff_prices.push(i + 1 + rr);
            this.new_tariff_prices_copy.push(i + rr);
            this.new_tariff_prices_copy.push(i + 1 + rr);
            this.priceRows.push(this.pr.group({
              agent_id:  res3.inquiry_tariff_prices_last[i].agent_id,
              id: res3.inquiry_tariff_prices_last[i].id,
              currency: res3.inquiry_tariff_prices_last[i].currency,
              name: res3.inquiry_tariff_prices_last[i].name,
              price: res3.inquiry_tariff_prices_last[i].price,
              rate: {disabled: true, value: res3.inquiry_tariff_prices_last[i].rate},
              rate_orig: res3.inquiry_tariff_prices_last[i].rate,
              amount: {disabled: true, value: res3.inquiry_tariff_prices_last[i].amount},
              amount_orig: res3.inquiry_tariff_prices_last[i].amount,
              is_paid: res3.inquiry_tariff_prices_last[i].is_paid,
            }));
            rr++;
          }                
          this.changeTraceCurrency(i);
        }
        for(i = res3.inquiry_tariff_prices_first.length; i < res3.inquiry_tariff_prices_last.length; i++){
          this.priceRows.push(this.pr.group({
            agent_id:  res3.inquiry_tariff_prices_last[i].agent_id,
            id: res3.inquiry_tariff_prices_last[i].id,
            currency: res3.inquiry_tariff_prices_last[i].currency,
            name: res3.inquiry_tariff_prices_last[i].name,
            price: res3.inquiry_tariff_prices_last[i].price,
            rate: {disabled: true, value: res3.inquiry_tariff_prices_last[i].rate},
            rate_orig: res3.inquiry_tariff_prices_last[i].rate,
            amount: {disabled: true, value: res3.inquiry_tariff_prices_last[i].amount},
            amount_orig: res3.inquiry_tariff_prices_last[i].amount,
            is_paid: res3.inquiry_tariff_prices_last[i].is_paid,
          }));
          this.changeTraceCurrency(i);
        }
      }
      // QUOTATION
      this.quotations = res3.inquiry_quotations;
      if(res3.inquiry_quotations.length > 0) {
        this.quotation_currency_id = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].currency_id;
        this.quotation_price = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].price;
        this.quotation_rate = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].rate;
        this.quotation_profit = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].profit;
        // this.quotation_transit_time = res3.inquiry_quotations[res3.inquiry_quotations.length - 1].transit_time;
        this.expense_total_name = 'Нийт зардлын дүн /' + res3.inquiry_quotations[res3.inquiry_quotations.length - 1].currency_name +'/';
        this.changeQuoPriceFirst();
      }
      // this.changeQuoPrice();
    }, (err1) => {
      console.log(err1);
      this.spinner.hide();
    });
  }

  onAddRow() {
    this.rows.push(this.createItemFormGroup());
  }

  onRemoveRow(rowIndex:number) {
    this.total_weight = Math.floor((this.total_weight - this.rows.value[rowIndex].box_weight*this.rows.value[rowIndex].quantity)*100)/100;
    this.total_volume = Math.ceil((this.total_volume - this.rows.value[rowIndex].volume_air*this.rows.value[rowIndex].quantity)*100)/100;
    this.total_cbm = Math.ceil((this.total_cbm - this.rows.value[rowIndex].cbm*this.rows.value[rowIndex].quantity)*100)/100;
    this.total_quantity = this.total_quantity - this.rows.value[rowIndex].quantity;
    this.rows.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({
      type: this.type,
      box_length: this.box_length,
      box_width: this.box_width,
      box_height: this.box_height,
      box_weight:this.box_weight,
      box_total_weight: {disabled: true, value: 0},
      volume_air:this.volume_air,
      total_volume_air: {disabled: true, value: 0},
		  cbm:this.cbm,
      total_cbm: {disabled: true, value: 0},
		  quantity: 1,
    });
  }

  backList() {
    this.router.navigate(['/inquiry']);
  }

  onConsigneeSelected(option: any) {
    for(let i = 0; i < this.consignees.length; i++) {
      if(this.consignees[i].name == option.value) {
        this.consignee_id = this.consignees[i].id;
        if(this.isNew) {
          localStorage.setItem('consignee_id', this.consignee_id);
          localStorage.setItem('consignee_name', this.consignees[i].name);
        }
        break;
      }
    }
  }

  public _filterCons() {
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.consignee_id = input.value;
    if(this.isNew) {
      localStorage.setItem('consignee_id', this.consignee_id);
      localStorage.setItem('consignee_name', input.value);
    }
    this.consignees = this.consigneesOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }


  onSupplierSelected(option: any) {
    for(let i = 0; i < this.suppliers.length; i++) {
      if(this.suppliers[i].name == option.value) {
        this.supplier_id = this.suppliers[i].id;
        if(this.isNew) {
          localStorage.setItem('supplier_id', this.supplier_id);
          localStorage.setItem('supplier_name', option.value);
        }
        break;
      }
    }
  }

  public _filterSupp() {
    const input = document.getElementById(
      'supplier',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.supplier_id = input.value;
    if(this.isNew) {
      localStorage.setItem('supplier_id', this.supplier_id);
      localStorage.setItem('supplier_name', input.value);
    }
    this.suppliers = this.suppliersOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onCountrySelected(option: any) {
    for(let i = 0; i < this.polCountry.length; i++) {
      if(this.polCountry[i].name == option.value) {
        this.pol_country_id = this.polCountry[i].id;
        if(this.isNew) {
          localStorage.setItem('pol_country_id', this.pol_country_id);
          localStorage.setItem('pol_country_name', option.value);
        }
        this.spinner.show();
        this.rest.getCity(this.pol_country_id).subscribe(
          (res) => {
            this.polCity = res.citys;
            this.polCityOrig = res.citys;
            this.spinner.hide();
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          }
        );
        break;
      }
    }
  }

  onPolCountry() {
    this.spinner.show();
    this.rest.getCity(this.pol_country_id).subscribe(
      (res) => {
        this.polCity = res.citys;
        this.polCityOrig = res.citys;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  onFinalCountry() {
    this.spinner.show();
    this.rest.getCity(this.final_country_id).subscribe(
      (res) => {
        this.finalCity = res.citys;
        this.finalCityOrig = res.citys;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  onCitySelected(option: any) {
    for(let i = 0; i < this.polCity.length; i++) {
      if(this.polCity[i].name == option.value) {
        this.pol_city_id = this.polCity[i].id;
        if(this.isNew) {
          localStorage.setItem('pol_city_id', this.pol_city_id);
          localStorage.setItem('pol_city_name', option.value);
        }
        break;
      }
    }
  }

  public _filterCountry() {
    const input = document.getElementById(
      'country',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.polCountry = this.polCountryOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public _filterCity() {
    const input = document.getElementById(
      'city',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.polCity = this.polCityOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public _finalCity() {
    const input = document.getElementById(
      'finalCity',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.finalCity = this.finalCityOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public _finalCountry() {
    const input = document.getElementById(
      'finalCountry',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();
    this.finalCountry= this.finalCountryOrig.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onFinalCountrySelected(option: any) {
    for(let i = 0; i < this.finalCountry.length; i++) {
      if(this.finalCountry[i].name == option.value) {
        this.final_country_id = this.finalCountry[i].id;
        if(this.isNew) {
          localStorage.setItem('final_country_id', this.final_country_id);
          localStorage.setItem('final_country_name', option.value);
        }
        this.spinner.show();
        this.rest.getCity(this.final_country_id).subscribe(
          (res) => {
            this.finalCity = res.citys;
            this.finalCityOrig = res.citys;
            this.spinner.hide();
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          }
        );
        break;
      }
    }
  }

  onFinalCitySelected(option: any) {
    for(let i = 0; i < this.finalCity.length; i++) {
      if(this.finalCity[i].name == option.value) {
        this.final_city_id = this.finalCity[i].id;
        if(this.isNew) {
          localStorage.setItem('final_city_id', this.final_city_id);
          localStorage.setItem('final_city_name', option.value);
        }
        break;
      }
    }
  }

  transportChange(){
    this.rest.getTransportTypes(this.trans_mode_id).subscribe(
      (res) => {
        this.Transportation_type = res.transport_types;
        if(this.trans_mode_id == 3 || this.trans_mode_id == 4) {
          this.activaTab('dimension');
        }
        if(this.trans_mode_id == 1 || this.trans_mode_id == 2) {
          document.getElementById('gross_section').style.display = 'flex';
        } else {
          document.getElementById('gross_section').style.display = 'none';
          this.gross_weight = 0;
          this.gross_cbm = 0;
        }
      }, (err) => {
        console.log(err);
      }
    );
  }

  clearAll() {
    localStorage.removeItem('name');
    localStorage.removeItem('consignee_id');
    localStorage.removeItem('consignee_name');
    localStorage.removeItem('commodity');
    localStorage.removeItem('division_id');
    localStorage.removeItem('condition_id');
    localStorage.removeItem('trans_mode_id');
    localStorage.removeItem('trans_type_id');
    localStorage.removeItem('incoterm_id');
    localStorage.removeItem('incoterm_address');
    localStorage.removeItem('inquiry_type_id');
    localStorage.removeItem('is_vat');
    localStorage.removeItem('type');
    localStorage.removeItem('gross_weight');
    localStorage.removeItem('gross_cbm');
    localStorage.removeItem('box_dup');
    localStorage.removeItem('hemjees');
    localStorage.removeItem('weights');
    localStorage.removeItem('pol_country_id');
    localStorage.removeItem('pol_country_name');
    localStorage.removeItem('pol_city_id');
    localStorage.removeItem('pol_city_name');
    localStorage.removeItem('pol_address');
    localStorage.removeItem('border_id');
    localStorage.removeItem('final_country_id');
    localStorage.removeItem('final_country_name');
    localStorage.removeItem('final_city_id');
    localStorage.removeItem('final_city_name');
    localStorage.removeItem('final_address');
    localStorage.removeItem('via_id');
    localStorage.removeItem('remark_comment');
    localStorage.removeItem('quotation_date');
    localStorage.removeItem('validaty_date');
    localStorage.removeItem('tariff_id');
    localStorage.removeItem('quotation_transit_time');
    localStorage.removeItem('comment_tariff');
    localStorage.removeItem('tariff_prices');
  }

  save(id: any) {
    this.weights = [];
    this.commodity_file = 'SM-CERT-FILE-' + new Date().getTime();
    if(this.type == 'box') {
      this.weights = this.rows.value;
    } else {
      this.weights.push({
        type: this.type,
        commodity_file: this.commodity_file,
      });
    }
    for(let i = 0; i < this.weights.length; i++){
      this.box_weight = this.weights[i].box_weight;
      this.volume_air = this.weights[i].volume_air;
      this.cbm = this.weights[i].cbm;
      this.quantity = this.weights[i].quantity;
    }
    if(this.name == null || this.name == '') {
      this.toastr.error('Алдаа!', 'Асуулгын нэрээ оруулна уу!');
      return;
    }

    if(this.consignee_id == null || this.consignee_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын хүлээн авагчийг оруулна уу!');
      return;
    }
    if(this.commodity == null || this.commodity == '') {
      this.toastr.error('Алдаа!', 'Асуулгын барааны нэр оруулна уу!');
      return;
    }

    if(this.division_id == null || this.division_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын хэлтэсийн нэр оруулна уу!');
      return;
    }
    if(this.condition_id == null || this.condition_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын Condition -оо оруулна уу!');
      return;
    }

    if(this.trans_mode_id == null || this.trans_mode_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын Transportation mode -оо оруулна уу!');
      return;
    }

    if(this.trans_type_id == null || this.trans_type_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын Transportation type -аа оруулна уу!');
      return;
    }

    if(this.incoterm_id == null || this.incoterm_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын incoterm -ээ оруулна уу!');
      return;
    } else if(this.incoterm_id == 11) {
      if(this.incoterm_address == null || this.incoterm_address == '') {
        this.toastr.error('Алдаа!', 'Асуулгын Incoterm Address -аа оруулна уу!');
        return;
      }
    }

    if(this.inquiry_type_id == null || this.inquiry_type_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын inquiry type -аа оруулна уу!');
      return;
    }

    if(this.trans_mode_id == 1 || this.trans_mode_id == 2) {
      if(this.gross_weight == null || this.gross_weight == '') {
        this.toastr.error('Алдаа!', 'Асуулгын Нийт Gross Weight оруулна уу!');
        return;
      }
      if(this.gross_cbm == null || this.gross_cbm == '') {
        this.toastr.error('Алдаа!', 'Асуулгын Нийт CBM оруулна уу!');
        return;
      }
    }

    // if(this.supplier_id == null || this.supplier_id == '') {
    //   this.toastr.error('Алдаа!', 'Асуулгын supplier -аа оруулна уу!');
    //   return;
    // }
    if(this.type != 'box') {
      if(this.imageFile == null || this.imageFile == '') {
        this.toastr.error('Алдаа!', 'Та ачааны сертификат файлаа оруулна уу!');
        return;
      }
    }

    if(this.pol_country_id == null || this.pol_country_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын POL улсаа оруулна уу!');
      return;
    }

    if(this.pol_city_id == null || this.pol_city_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын POL хот оруулна уу!');
      return;
    }

    if(this.border_id == null || this.border_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын border оруулна уу!');
      return;
    }

    if(this.final_country_id == null || this.final_country_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын final country оруулна уу!');
      return;
    }

    if(this.final_city_id == null || this.final_city_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын final city  оруулна уу!');
      return;
    }

    if(this.via_id == null || this.via_id == '') {
      this.toastr.error('Алдаа!', 'Асуулгын via -г оруулна уу!');
      return;
    }

    if(this.quotation_date == null || this.quotation_date == '') {
      this.toastr.error('Алдаа!', 'Асуулгын quotation date -г оруулна уу!');
      return;
    }
    

    if(this.validaty_date == null || this.validaty_date == '') {
      this.toastr.error('Алдаа!', 'Асуулгын validaty date -г оруулна уу!');
      return;
    }

    if(this.data.userData.permission == 4) {
      if(this.tariff_id == null || this.tariff_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын tariff manager -г оруулна уу!');
        return;
      }
    }
    if(this.data.userData.permission == 5) {
      if(this.sales_id == null || this.sales_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын sales manager -г оруулна уу!');
        return;
      }
    }

    if(this.type == 'box') {
      if(this.hemjees == null || this.hemjees == '') {
        this.toastr.error('Алдаа!', 'Та ачааны хэмжээсээ оруулна уу!');
        return;
      }
      if(this.box_weight == null || this.box_weight == '') {
        this.toastr.error('Алдаа!', 'Асуулгын box weight хэсгийг  оруулна уу!');
        return;
      }

      if(this.volume_air == null || this.volume_air == '') {
        this.toastr.error('Алдаа!', 'Асуулгын volume air хэсгийг  оруулна уу!');
        return;
      }

      if(this.cbm == null || this.cbm == '') {
        this.toastr.error('Алдаа!', 'Асуулгын cbm хэсгийг  оруулна уу!');
        return;
      }
      
      if(this.quantity == null || this.quantity == '') {
        this.toastr.error('Алдаа!', 'Асуулгын quantity хэсгийг  оруулна уу!');
        return;
      }
    }
    if(this.incoterm_address == null) {
      this.incoterm_address = '';
    }
    if(this.pol_address == null) {
      this.pol_address = '';
    }
    if(this.final_address == null) {
      this.final_address = '';
    }
    if(this.remark_comment == null) {
      this.remark_comment = '';
    }
    if(this.gross_weight == null || this.gross_weight == '') {
      this.gross_weight = 0;
    }
    if(this.gross_cbm == null || this.gross_cbm == '') {
      this.gross_cbm = 0;
    }
    
    if(this.type != 'box') {
      this.spinner.show();
      this.rest.uploadFile(this.imageFile, this.commodity_file).subscribe((res2) => {
        if(res2.success) {
          this.rest.inquiryAdd(
            this.name,
            this.consignee_id,
            this.commodity,
            this.division_id,
            this.condition_id,
            this.trans_mode_id,
            this.trans_type_id,
            this.incoterm_id,
            this.incoterm_address,
            this.inquiry_type_id,
            this.supplier_id,
            this.is_vat,
            this.pol_country_id,
            this.pol_city_id,
            this.pol_address,
            this.border_id,
            this.final_country_id,
            this.final_city_id,
            this.final_address,
            this.via_id,
            this.remark_comment,
            this.quotation_date,
            this.validaty_date,
            this.box_dup,
            this.gross_weight,
            this.gross_cbm,
            this.hemjees,
            // this.so_start_date,
            this.data.userData.id,
            this.tariff_id,
            this.weights,
            ).subscribe(
            (res) => {
              this.spinner.hide();
              if(res.message == 'OK') {
                this.clearAll();
                this.toastr.success('Success', 'Амжилттай орууллаа.');
                if(id == 1) {
                  window.location.reload();
                } else {
                  this.router.navigate(['/inquiry']);
                }
              } else {
                this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
              }        
            }, (err) => {
              console.log(err);
              this.spinner.hide();
            }
          );
        } else {
          this.spinner.hide();
          this.toastr.error('Алдаа!', 'Файл upload хийж чадсангүй та дахин оролдоно уу!');
        }
      }, (err1) => {
        console.log(err1);
        this.spinner.hide();
      });
    } else {
      this.rest.inquiryAdd(
        this.name,
        this.consignee_id,
        this.commodity,
        this.division_id,
        this.condition_id,
        this.trans_mode_id,
        this.trans_type_id,
        this.incoterm_id,
        this.incoterm_address,
        this.inquiry_type_id,
        this.supplier_id,
        this.is_vat,
        this.pol_country_id,
        this.pol_city_id,
        this.pol_address,
        this.border_id,
        this.final_country_id,
        this.final_city_id,
        this.final_address,
        this.via_id,
        this.remark_comment,
        this.quotation_date,
        this.validaty_date,
        this.box_dup,
        this.gross_weight,
        this.gross_cbm,
        this.hemjees,
        // this.so_start_date,
        this.data.userData.id,
        this.tariff_id,
        this.weights,
        ).subscribe(
        (res) => {
          this.spinner.hide();
          if(res.message == 'OK') {
            this.clearAll();
            this.toastr.success('Success', 'Амжилттай орууллаа.');
            if(id == 1) {
              window.location.reload();          
            } else {
              this.router.navigate(['/inquiry']);
            }
          } else {
            this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
          }        
        }, (err) => {
          console.log(err);
          this.spinner.hide();
        }
      );
    }    
  }

  rowChange(e){
    console.log(e.target.value);
  }

  onChange(name: any, e) {
    if(this.isNew) {
      localStorage.setItem('perm', this.data.userData.permission);
      if(name == 'is_vat') {
        localStorage.setItem(name, e.checked);
      } else {
        if(e.target) {
          localStorage.setItem(name, e.target.value);
        } else {
          localStorage.setItem(name, e.value);
        }
      }
    }
  }

  edit() {
    if(this.orig_inquiry_type != null && this.orig_inquiry_type == 'SO' && this.data.userData.permission == 4
      && this.tariff_type != '') {
      this.toastr.error('Алдаа!', ' Та үнийн өөрчлөлтийг хадгалахын тулд санхүүгээс зөвшөөрөл авна уу!');
      return;
    }
    this.weights = [];
    if(this.type == 'box') {
      this.weights = this.rows.value;
    } else {
      this.weights.push({
        type: this.type,
        commodity_file: this.commodity_file,
      });
    }
    let exs = this.expensesRow.value;
    let tarif_prices = this.priceRows.value;
    let rr = 0;
    for(let k = 0; k < tarif_prices.length; k++) {
      if(this.new_tariff_prices.includes(k)) {
        tarif_prices.splice(k - 1 - rr, 1);
        rr++;
      }
    }
    let quos = {
      currency: this.quotation_currency_id,
      price: this.quotation_price,
      rate: this.quotation_rate,
      profit: this.quotation_profit,
      transit_time: this.quotation_transit_time
    };
    if(this.data.userData.permission == 4) {
      for(let i = 0; i < this.weights.length; i++){
        this.box_weight = this.weights[i].box_weight;
        this.volume_air = this.weights[i].volume_air;
        this.cbm = this.weights[i].cbm;
        this.quantity = this.weights[i].quantity;
      }
      if(this.name == null || this.name == '') {
        this.toastr.error('Алдаа!', 'Асуулгын нэрээ оруулна уу!');
        return;
      }
  
      if(this.consignee_id == null || this.consignee_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын хүлээн авагчийг оруулна уу!');
        return;
      }
      if(this.commodity == null || this.commodity == '') {
        this.toastr.error('Алдаа!', 'Асуулгын барааны нэр оруулна уу!');
        return;
      }
  
      if(this.division_id == null || this.division_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын хэлтэсийн нэр оруулна уу!');
        return;
      }
      if(this.condition_id == null || this.condition_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын Condition -оо оруулна уу!');
        return;
      }
  
      if(this.trans_mode_id == null || this.trans_mode_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын Transportation mode -оо оруулна уу!');
        return;
      }
  
      if(this.trans_type_id == null || this.trans_type_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын Transportation type -аа оруулна уу!');
        return;
      }
  
      if(this.incoterm_id == null || this.incoterm_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын incoterm -ээ оруулна уу!');
        return;
      }
  
      if(this.incoterm_id == null || this.incoterm_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын incoterm -ээ оруулна уу!');
        return;
      }
  
      if(this.inquiry_type_id == null || this.inquiry_type_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын inquiry type -аа оруулна уу!');
        return;
      }

      if(this.trans_mode_id == 1 || this.trans_mode_id == 2) {
        if(this.gross_weight == null || this.gross_weight == '') {
          this.toastr.error('Алдаа!', 'Асуулгын Нийт Gross Weight оруулна уу!');
          return;
        }
        if(this.gross_cbm == null || this.gross_cbm == '') {
          this.toastr.error('Алдаа!', 'Асуулгын Нийт CBM оруулна уу!');
          return;
        }
      }
  
      // if(this.supplier_id == null || this.supplier_id == '') {
      //   this.toastr.error('Алдаа!', 'Асуулгын supplier -аа оруулна уу!');
      //   return;
      // }
  
      if(this.pol_country_id == null || this.pol_country_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын POL улсаа оруулна уу!');
        return;
      }
  
      if(this.pol_city_id == null || this.pol_city_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын POL хот оруулна уу!');
        return;
      }
  
      if(this.border_id == null || this.border_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын border оруулна уу!');
        return;
      }
  
      if(this.final_country_id == null || this.final_country_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын final country оруулна уу!');
        return;
      }
  
      if(this.final_city_id == null || this.final_city_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын final city  оруулна уу!');
        return;
      }
  
      if(this.via_id == null || this.via_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын via -г оруулна уу!');
        return;
      }
  
      if(this.quotation_date == null || this.quotation_date == '') {
        this.toastr.error('Алдаа!', 'Асуулгын quotation date -г оруулна уу!');
        return;
      }
      
  
      if(this.validaty_date == null || this.validaty_date == '') {
        this.toastr.error('Алдаа!', 'Асуулгын validaty date -г оруулна уу!');
        return;
      }
  
      if(this.data.userData.permission == 4) {
        if(this.tariff_id == null || this.tariff_id == '') {
          this.toastr.error('Алдаа!', 'Асуулгын tariff manager -г оруулна уу!');
          return;
        }
      }
  
      if(this.type == 'box') {
        if(this.hemjees == null || this.hemjees == '') {
          this.toastr.error('Алдаа!', 'Та ачааны хэмжээсээ оруулна уу!');
          return;
        }
        if(this.box_weight == null || this.box_weight == '') {
          this.toastr.error('Алдаа!', 'Асуулгын box weight хэсгийг  оруулна уу!');
          return;
        }
  
        if(this.volume_air == null || this.volume_air == '') {
          this.toastr.error('Алдаа!', 'Асуулгын volume air хэсгийг  оруулна уу!');
          return;
        }
  
        if(this.cbm == null || this.cbm == '') {
          this.toastr.error('Алдаа!', 'Асуулгын cbm хэсгийг  оруулна уу!');
          return;
        }
        
        if(this.quantity == null || this.quantity == '') {
          this.toastr.error('Алдаа!', 'Асуулгын quantity хэсгийг  оруулна уу!');
          return;
        }
      }
      if(this.incoterm_address == null) {
        this.incoterm_address = '';
      }
      if(this.pol_address == null) {
        this.pol_address = '';
      }
      if(this.final_address == null) {
        this.final_address = '';
      }
      if(this.remark_comment == null) {
        this.remark_comment = '';
      }
      if(this.inquiry_tariff_prices_first.length > 0) {
        if(quos.currency == 0) {
          this.toastr.error('Алдаа!', 'Quotation валютаа сонгоно уу!');
          return;
        }
        if(quos.price == null || quos.price == '') {
          this.toastr.error('Алдаа!', 'Quotation үнийн дүнгээ оруулна уу!');
          return;
        }
        // if(quos.transit_time == null || quos.transit_time == '') {
        //   this.toastr.error('Алдаа!', 'Quotation хугацаагаа оруулна уу!');
        //   return;
        // }
        if(exs.length == 0) {
          this.toastr.error('Алдаа!', 'Тээврийн зардлаа оруулна уу!');
          return;
        }
      }
    }
    if(this.data.userData.permission == 5) {
      if(this.border_id == null || this.border_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын border оруулна уу!');
        return;
      }  
      if(this.via_id == null || this.via_id == '') {
        this.toastr.error('Алдаа!', 'Асуулгын via -г оруулна уу!');
        return;
      }
      // if(quos.transit_time == null || quos.transit_time == '') {
      //   this.toastr.error('Алдаа!', 'Тээврийн хугацаагаа оруулна уу!');
      //   return;
      // }
      if(this.quotation_transit_time == null || this.quotation_transit_time == '') {
        this.toastr.error('Алдаа!', 'Тээврийн хугацаагаа оруулна уу!');
        return;
      }
      if(quos.transit_time == null || quos.transit_time == '') {
        this.toastr.error('Алдаа!', 'Тээврийн хугацаагаа оруулна уу!');
        return;
      }
      if(tarif_prices.length == 0) {
        this.toastr.error('Алдаа!', 'Тээврийн үнээ оруулна уу!');
        return;
      }
      if(this.comment_tariff == null) {
        this.comment_tariff = '';
      }
    }
    this.spinner.show();
    this.rest.editInquiry(
      this.id,
      this.name,
      this.consignee_id,
      this.commodity,
      this.division_id,
      this.condition_id,
      this.trans_mode_id,
      this.trans_type_id,
      this.incoterm_id,
      this.incoterm_address,
      this.inquiry_type_id,
      this.supplier_id,
      this.is_vat,
      this.pol_country_id,
      this.pol_city_id,
      this.pol_address,
      this.border_id,
      this.final_country_id,
      this.final_city_id,
      this.final_address,
      this.via_id,
      this.remark_comment,
      this.comment_tariff,
      this.quotation_date,
      this.validaty_date,
      this.box_dup,
      this.gross_weight,
      this.gross_cbm,
      this.hemjees,
      this.quotation_transit_time,
      // this.so_start_date,
      this.data.userData.id,
      this.tariff_id,
      this.back_to_tariff,
      this.weights,
      quos,
      exs,
      tarif_prices
      ).subscribe((res) => {
        this.spinner.hide();
        if(res.message == 'OK') {
          this.toastr.success('Success', 'Амжилттай засварлалаа.');
          if(this.data.userData.permission == 4 && this.back_to_tariff == 1) {
            const userdata = {
              id: this.data.userData.id,
              lname: this.data.userData.lname,
              fname: this.data.userData.fname,
              permission: this.data.userData.permission,
              email: this.data.userData.email,
              phone: this.data.userData.phone,
              job_date: this.data.userData.job_date,
              image: this.data.userData.image,
              name: this.data.userData.name,
              password: this.data.userData.password,
              position_type: this.data.userData.position_type,
              create_date: this.data.userData.create_date,
              notifications: {
                inquiry: this.data.userData.notifications.inquiry - 1,
                shipping_order: this.data.userData.notifications.shipping_order,
                operation: this.data.userData.notifications.operation,
                custom: this.data.userData.notifications.custom,
                site: this.data.userData.notifications.site,
                invoices: this.data.userData.notifications.invoices
              },
            };
            const encrypt = CryptoJS.AES.encrypt(
              JSON.stringify(userdata),
              ENCRYPT_SECRET_KEY
            ).toString();
            localStorage.setItem('token', encrypt);
          }
          if(this.data.userData.permission == 5) {
            this.clearAll();
            const userdata = {
              id: this.data.userData.id,
              lname: this.data.userData.lname,
              fname: this.data.userData.fname,
              permission: this.data.userData.permission,
              email: this.data.userData.email,
              phone: this.data.userData.phone,
              job_date: this.data.userData.job_date,
              image: this.data.userData.image,
              name: this.data.userData.name,
              password: this.data.userData.password,
              position_type: this.data.userData.position_type,
              create_date: this.data.userData.create_date,
              notifications: {
                inquiry: this.data.userData.notifications.inquiry - 1,
                shipping_order: this.data.userData.notifications.shipping_order,
                operation: this.data.userData.notifications.operation,
                custom: this.data.userData.notifications.custom,
                site: this.data.userData.notifications.site,
                invoices: this.data.userData.notifications.invoices
              },
            };
            const encrypt = CryptoJS.AES.encrypt(
              JSON.stringify(userdata),
              ENCRYPT_SECRET_KEY
            ).toString();
            localStorage.setItem('token', encrypt);
          }
          this.router.navigate(['/inquiry']);
        } else {
          this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
        }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  moveSO() {
    this.spinner.show();
    this.rest.moveInquiry(this.id).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай SO болголоо.');
        if(this.data.userData.permission == 4) {
          const userdata = {
            id: this.data.userData.id,
            lname: this.data.userData.lname,
            fname: this.data.userData.fname,
            permission: this.data.userData.permission,
            email: this.data.userData.email,
            phone: this.data.userData.phone,
            job_date: this.data.userData.job_date,
            image: this.data.userData.image,
            name: this.data.userData.name,
            password: this.data.userData.password,
            position_type: this.data.userData.position_type,
            create_date: this.data.userData.create_date,
            notifications: {
              inquiry: this.data.userData.notifications.inquiry - 1,
              shipping_order: this.data.userData.notifications.shipping_order,
              operation: this.data.userData.notifications.operation,
              custom: this.data.userData.notifications.custom,
              site: this.data.userData.notifications.site,
              invoices: this.data.userData.notifications.invoices
            },
          };
          const encrypt = CryptoJS.AES.encrypt(
            JSON.stringify(userdata),
            ENCRYPT_SECRET_KEY
          ).toString();
          localStorage.setItem('token', encrypt);
        }
        this.router.navigate(['/inquiry']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  seePDF() {
    window.open('/uploads/files/' + this.box_file, '_blank');
  }

  download() {
    // this.router.navigate(['/inquiry/download', {id: this.id}]);
    window.open('/inquiry/download;id=' + this.id, '_blank');
  }

  close() {
    if(this.reason_id == null || this.reason_id == 0) {
      this.toastr.error('Алдаа', 'Та хаах болсон шалтгаанаа сонгоно уу.');
      return;
    }
    if(this.closed_comment == null) {
      this.closed_comment = '';
    }
    this.spinner.show();
    this.rest.closeInquiry(this.id, this.closed_comment, this.reason_id).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.modalService.dismissAll();
        this.toastr.success('Success', 'Амжилттай Inquiry-г хаалаа.');
        if(this.data.userData.permission == 4) {
          const userdata = {
            id: this.data.userData.id,
            lname: this.data.userData.lname,
            fname: this.data.userData.fname,
            permission: this.data.userData.permission,
            email: this.data.userData.email,
            phone: this.data.userData.phone,
            job_date: this.data.userData.job_date,
            image: this.data.userData.image,
            name: this.data.userData.name,
            password: this.data.userData.password,
            position_type: this.data.userData.position_type,
            create_date: this.data.userData.create_date,
            notifications: {
              inquiry: this.data.userData.notifications.inquiry - 1,
              shipping_order: this.data.userData.notifications.shipping_order,
              operation: this.data.userData.notifications.operation,
              custom: this.data.userData.notifications.custom,
              site: this.data.userData.notifications.site,
              invoices: this.data.userData.notifications.invoices
            },
          };
          const encrypt = CryptoJS.AES.encrypt(
            JSON.stringify(userdata),
            ENCRYPT_SECRET_KEY
          ).toString();
          localStorage.setItem('token', encrypt);
        }
        this.router.navigate(['/inquiry']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openPrice(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  totalWeight(i) {
    let weights = this.rows.value;   
    if(this.isNew) {
      localStorage.setItem('weights', JSON.stringify(weights));
    }
    if(weights[i].box_weight != null && weights[i].box_weight != '') {
      if(weights[i].quantity != null && weights[i].quantity != '') {
        this.total_weight = this.total_weight + weights[i].box_weight*weights[i].quantity;
        weights[i].box_total_weight = this.total_weight;
      } else {
        this.total_weight = this.total_weight + weights[i].box_weight;
        weights[i].box_total_weight = this.total_weight;
      }
    }
    weights[i].volume_air = Math.round((weights[i].box_length*weights[i].box_width*weights[i].box_height/6000) * 100) / 100;
    if(weights[i].quantity != null && weights[i].quantity != '') {
      this.total_volume = this.total_volume + weights[i].volume_air*weights[i].quantity;
      weights[i].total_volume_air = this.total_volume;
    } else {
      this.total_volume = this.total_volume + weights[i].volume_air;
      weights[i].total_volume_air = this.total_volume;
    }
    weights[i].cbm = weights[i].box_length*weights[i].box_width*weights[i].box_height/1000000;
    if(weights[i].quantity != null && weights[i].quantity != '') {
      this.total_cbm = this.total_cbm + weights[i].cbm*weights[i].quantity;
      weights[i].total_cbm = this.total_cbm.toFixed(3);
    } else {
      this.total_cbm = this.total_cbm + weights[i].cbm;
      weights[i].total_cbm = this.total_cbm.toFixed(3);
    }
    if(weights[i].quantity != null && weights[i].quantity != '') {
      this.total_quantity = this.total_quantity + weights[i].quantity;
    } else {
      this.total_quantity = this.total_quantity;
    }
    this.rows = this.fb.array([]);
    this.total_weight = 0;
    this.total_volume = 0;
    this.total_cbm = 0;
    this.total_quantity = 0;
    for(var j = 0; j < weights.length; j++) {
      this.rows.push(this.fb.group({
        type: weights[j].type,                  
        box_length: weights[j].box_length,
        box_width: weights[j].box_width,
        box_height: weights[j].box_height,
        box_weight: weights[j].box_weight,
        box_total_weight: {disabled:true, value: weights[j].box_weight*weights[j].quantity},
        volume_air: weights[j].volume_air,
        total_volume_air: {disabled:true, value: Math.round(weights[j].volume_air*weights[j].quantity * 100) / 100},
        cbm: weights[j].cbm,
        total_cbm: {disabled:true, value: Math.floor(weights[j].cbm*weights[j].quantity * 100) / 100},
        quantity: weights[j].quantity,
      }));
      this.total_weight = this.total_weight + weights[j].box_weight*weights[j].quantity;
      this.total_volume = this.total_volume + weights[j].volume_air*weights[j].quantity;
      this.total_cbm = this.total_cbm + weights[j].cbm*weights[j].quantity;
      this.total_quantity = this.total_quantity + weights[j].quantity;
    }
    this.total_volume = parseFloat((Math.round(this.total_volume * 100) / 100).toFixed(2));
    this.total_cbm = Math.floor(this.total_cbm * 1000) / 1000;
    this.addForm.setControl('rows', this.rows);
  }

  changeQuoCurrency() {
    for(let i = 0; i < this.Currency.length; i++) {
      if(this.Currency[i].id == this.quotation_currency_id) {
        this.quotation_rate = this.Currency[i].rate;
        this.expense_rate_name = this.Currency[i].name + ' Rate';
        this.expense_total_name = 'Нийт зардлын дүн /' + this.Currency[i].name +'/';
        this.changeQuoPrice();
        break;
      }
    }
  }

  changeQuoPrice() {
    // PROFIT TOMYO
    if(this.quotation_price != null) {
      // this.quotation_price = this.quotation_price + (this.quotation_profit - (this.quotation_price*this.quotation_rate - this.trace_price_mnt)) / this.quotation_rate;
      this.quotation_profit = this.quotation_price*this.quotation_rate - this.trace_price_mnt;
      this.PriceOffer = this.quotation_price*this.quotation_rate;
      this.ProfitMNT = this.quotation_price*this.quotation_rate - this.trace_price_mnt;
    }
    this.expenseCalc(0);
  }

  changeQuoPriceFirst() {
    // PROFIT TOMYO
    if(this.quotation_price != null) {
      this.quotation_price = Math.ceil(this.quotation_price + (this.quotation_profit - (this.quotation_price*this.quotation_rate - this.trace_price_mnt)) / this.quotation_rate);
      this.quotation_profit = this.quotation_price*this.quotation_rate - this.trace_price_mnt;
      this.PriceOffer = this.quotation_price*this.quotation_rate;
      this.ProfitMNT = this.quotation_price*this.quotation_rate - this.trace_price_mnt;
    }
    this.expenseCalc(0);
  }

  expenseCalc(percent: number) {
    // EXPENCES
    let expensesRow = this.expensesRow.value;
    this.expensesRow = this.ex.array([]);
    this.expences_quantity = 0;
    this.expences_percent = 0;
    this.expences_total = 0;
    this.expences_vat = 0;
    this.expences_price = 0;

    this.expences_total_cur = 0;
    this.expences_vat_cur = 0;
    this.expences_price_cur = 0;
    for(var j = 0; j < expensesRow.length; j++){
      if(this.is_vat == 0) {
        expensesRow[j].price_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
        expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
        expensesRow[j].rate_orig = this.quotation_rate;
        expensesRow[j].vat = 0;
      } else {
        if(j == 0 && expensesRow[j].name == 'Гадаад тээврийн зардал') {
          expensesRow[j].price_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].rate_orig = this.quotation_rate;
          if(percent < 50) {
            expensesRow[j].percentage = expensesRow[j].percentage + percent;
          }
          expensesRow[j].vat = 0;
        } else {
          expensesRow[j].price_orig = ((this.quotation_price*expensesRow[j].percentage/100)*10/11).toFixed(2);
          expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].rate_orig = this.quotation_rate;
          if(j == 0) {
            expensesRow[j].percentage = expensesRow[j].percentage + percent;
          }
          expensesRow[j].vat = (this.quotation_price*expensesRow[j].percentage/100 - (this.quotation_price*expensesRow[j].percentage/100)*10/11).toFixed(2);
        }
      }
      this.expensesRow.push(this.pr.group({
        id: expensesRow[j].id,
        name: expensesRow[j].name,
        quantity: expensesRow[j].quantity,
        percentage: expensesRow[j].percentage,
        engname: expensesRow[j].engname,
        price: {disabled: true, value: expensesRow[j].price_orig},
        price_orig: expensesRow[j].price_orig,
        rate: {disabled:true, value: expensesRow[j].rate_orig},
        rate_orig: expensesRow[j].rate_orig,
        total_amount: {disabled:true, value: expensesRow[j].total_orig},
        total_orig: expensesRow[j].total_orig,
        vat: {disabled: true, value: expensesRow[j].vat},
        vat_orig: expensesRow[j].vat
      }));
      this.expences_quantity++;
      this.expences_percent = this.expences_percent + expensesRow[j].percentage;
      this.expences_total = Math.round((this.expences_total + parseFloat(expensesRow[j].total_orig)) * 100) / 100;
      this.expences_vat = Math.round((this.expences_vat + parseFloat(expensesRow[j].vat)) * 100) / 100;
      this.expences_price = Math.round((this.expences_price + parseFloat(expensesRow[j].price_orig)) * 100) / 100;

      this.expences_total_cur = Math.round((this.expences_total_cur + parseFloat(expensesRow[j].total_orig)) * 100) / 100;
      this.expences_vat_cur = Math.round((this.expences_vat_cur + parseFloat(expensesRow[j].vat)) * 100) / 100;
      this.expences_price_cur = Math.round((this.expences_price_cur + parseFloat(expensesRow[j].price_orig)) * 100) / 100;
    }
    this.expences_total = Math.round((this.expences_total*this.quotation_rate) * 100) / 100;
    this.expences_vat = Math.round((this.expences_vat*this.quotation_rate) * 100) / 100;
    this.expences_price = Math.round((this.expences_price*this.quotation_rate) * 100) / 100;

    if(this.is_vat == 1 && this.ProfitMNT > 0) {
      this.ProfitMNT = this.ProfitMNT - this.expences_vat;
    }
    this.ProfitRMB = parseFloat((this.ProfitMNT/this.Currency[2].rate).toFixed(2));
    this.ProfitUSD = parseFloat((this.ProfitMNT/this.Currency[0].rate).toFixed(2));
    this.ProfitEURO = parseFloat((this.ProfitMNT/this.Currency[5].rate).toFixed(2));
    if(this.ProfitMNT > 0) {
      this.PureProfit = this.ProfitMNT - this.ProfitMNT/10;
    }
    // this.priceRows.reset();
    this.expenses.setControl('expensesRow', this.expensesRow);
  }

  changeTraceCurrency(id) {
    let priceRows = this.priceRows.value;
    if(!this.isNew && this.data.userData.permission == 5) {
      localStorage.setItem('tariff_prices', JSON.stringify(priceRows));
    }
    priceRows[id].rate_orig = this.Currency[priceRows[id].currency - 1].rate;
    priceRows[id].amount_orig = this.Currency[priceRows[id].currency - 1].rate*priceRows[id].price;
    this.priceRows = this.pr.array([]);
    this.trace_price_mnt = 0;
    this.trace_price_china = 0;
    this.trace_price_usd = 0;
    this.trace_price_confirmed = 0;
    for(var j = 0; j < priceRows.length; j++){
      this.priceRows.push(this.pr.group({
        agent_id: priceRows[j].agent_id,                  
        id: priceRows[j].id,
        currency: priceRows[j].currency,
        name: priceRows[j].name,
        price: priceRows[j].price,                  
        rate: {disabled:true, value: priceRows[j].rate_orig},
        rate_orig: priceRows[j].rate_orig,
        amount: {disabled:true, value: priceRows[j].amount_orig},
        amount_orig: priceRows[j].amount_orig,
        is_paid: priceRows[j].is_paid,
      }));
      this.trace_price_mnt = this.trace_price_mnt + priceRows[j].amount_orig;
      if(this.new_tariff_prices.includes(j)) {
        this.trace_price_mnt = this.trace_price_mnt - priceRows[j - 1].amount_orig;
      }
      if(priceRows[j].is_paid != null) {
        this.trace_price_confirmed = this.trace_price_confirmed + priceRows[j].amount_orig;
      }
    }
    this.trace_price_china = parseFloat((this.trace_price_mnt/this.Currency[2].rate).toFixed(2));
    this.trace_price_usd = parseFloat((this.trace_price_mnt/this.Currency[0].rate).toFixed(2));
    // this.priceRows.reset();
    this.price.setControl('priceRows', this.priceRows);
  }

  transPrice(id) {
    let priceRows = this.priceRows.value;
    priceRows[id].amount_orig = this.Currency[priceRows[id].currency - 1].rate*priceRows[id].price;
    this.priceRows = this.pr.array([]);
    this.trace_price_mnt = 0;
    this.trace_price_china = 0;
    this.trace_price_usd = 0;
    this.trace_price_confirmed = 0;    
    for(var j = 0; j < priceRows.length; j++) {
      this.priceRows.push(this.pr.group({
        agent_id: priceRows[j].agent_id,                  
        id: priceRows[j].id,
        currency: priceRows[j].currency,
        name: priceRows[j].name,
        price: priceRows[j].price,                  
        rate: {disabled:true, value: priceRows[j].rate_orig},
        rate_orig: priceRows[j].rate_orig,
        amount: {disabled:true, value: priceRows[j].amount_orig},
        amount_orig: priceRows[j].amount_orig,
        is_paid: priceRows[j].is_paid,
      }));
      this.trace_price_mnt = this.trace_price_mnt + priceRows[j].amount_orig;
      if(this.new_tariff_prices.includes(j)) {
        this.trace_price_mnt = this.trace_price_mnt - priceRows[j - 1].amount_orig;
      }
      if(priceRows[j].is_paid != null) {
        this.trace_price_confirmed = this.trace_price_confirmed + priceRows[j].amount_orig;
      }
    }
    this.trace_price_china = parseFloat((this.trace_price_mnt/this.Currency[2].rate).toFixed(2));
    this.trace_price_usd = parseFloat((this.trace_price_mnt/this.Currency[0].rate).toFixed(2));
    // this.priceRows.reset();
    this.price.setControl('priceRows', this.priceRows);
  }

  expensePercent(id) {    
    // EXPENCES
    let expensesRow = this.expensesRow.value;
    this.expensesRow = this.ex.array([]);
    this.expences_quantity = 0;
    this.expences_percent = 0;
    this.expences_total = 0;
    this.expences_vat = 0;
    this.expences_price = 0;
    if(id > 1) {
      expensesRow[id].percentage = 1;
    } else if(id == 1) {
      expensesRow[id].percentage = 11;
    } else {
      expensesRow[id+1].percentage = 100 - expensesRow[id].percentage - (expensesRow.length - 2);
    }
    for(var j = 0; j < expensesRow.length; j++){
      if(this.is_vat == 0) {
        expensesRow[j].price_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
        expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
        expensesRow[j].rate_orig = this.quotation_rate;
        expensesRow[j].vat = 0;
      } else {
        if(j == 0) {
          expensesRow[j].price_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].rate_orig = this.quotation_rate;
          expensesRow[j].vat = 0;
        } else {
          expensesRow[j].price_orig = ((this.quotation_price*expensesRow[j].percentage/100)*10/11).toFixed(2);
          expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].rate_orig = this.quotation_rate;
          expensesRow[j].vat = (this.quotation_price*expensesRow[j].percentage/100 - (this.quotation_price*expensesRow[j].percentage/100)*10/11).toFixed(2);
        }
      }
      this.expensesRow.push(this.pr.group({
        id: expensesRow[j].id,
        name: expensesRow[j].name,
        quantity: expensesRow[j].quantity,
        percentage: expensesRow[j].percentage,
        engname: expensesRow[j].engname,
        price: {disabled: true, value: expensesRow[j].price_orig},
        price_orig: expensesRow[j].price_orig,
        rate: {disabled:true, value: expensesRow[j].rate_orig},
        rate_orig: expensesRow[j].rate_orig,
        total_amount: {disabled:true, value: expensesRow[j].total_orig},
        total_orig: expensesRow[j].total_orig,
        vat: {disabled: true, value: expensesRow[j].vat},
        vat_orig: expensesRow[j].vat,
      }));
      this.expences_quantity++;
      this.expences_percent = this.expences_percent + expensesRow[j].percentage;
      this.expences_total = Math.round((this.expences_total + parseFloat(expensesRow[j].total_orig)) * 100) / 100;
      this.expences_vat = Math.round((this.expences_vat + parseFloat(expensesRow[j].vat)) * 100) / 100;
      this.expences_price = Math.round((this.expences_price + parseFloat(expensesRow[j].price_orig)) * 100) / 100;
    }
    // this.priceRows.reset();
    this.expenses.setControl('expensesRow', this.expensesRow);
  }

  expenseVat(id) {
    // EXPENCES
    let expensesRow = this.expensesRow.value;
    this.expensesRow = this.ex.array([]);
    this.expences_quantity = 0;
    this.expences_percent = 0;
    this.expences_total = 0;
    this.expences_vat = 0;
    this.expences_price = 0;
    for(var j = 0; j < expensesRow.length; j++){
      if(this.is_vat == 0) {
        expensesRow[j].price_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
        expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
        expensesRow[j].rate_orig = this.quotation_rate;
        expensesRow[j].vat = 0;
      } else {        
        if(j == 0) {
          expensesRow[j].price_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].rate_orig = this.quotation_rate;
          expensesRow[j].vat = 0;
        } else {
          expensesRow[j].price_orig = ((this.quotation_price*expensesRow[j].percentage/100)*10/11).toFixed(2);
          expensesRow[j].total_orig = (this.quotation_price*expensesRow[j].percentage/100).toFixed(2);
          expensesRow[j].rate_orig = this.quotation_rate;
          expensesRow[j].vat = (this.quotation_price*expensesRow[j].percentage/100 - (this.quotation_price*expensesRow[j].percentage/100)*10/11).toFixed(2);
        }
      }
      this.expensesRow.push(this.pr.group({
        id: expensesRow[j].id,
        name: expensesRow[j].name,
        quantity: expensesRow[j].quantity,
        percentage: expensesRow[j].percentage,
        engname: expensesRow[j].engname,
        price: {disabled: true, value: expensesRow[j].price_orig},
        price_orig: expensesRow[j].price_orig,
        rate: {disabled:true, value: expensesRow[j].rate_orig},
        rate_orig: expensesRow[j].rate_orig,
        total_amount: {disabled:true, value: expensesRow[j].total_orig},
        total_orig: expensesRow[j].total_orig,
        vat: expensesRow[j].vat,
      }));
      this.expences_quantity++;
      this.expences_percent = this.expences_percent + expensesRow[j].percentage;
      this.expences_total = Math.round((this.expences_total + parseFloat(expensesRow[j].total_orig)) * 100) / 100;
      this.expences_vat = Math.round((this.expences_vat + parseFloat(expensesRow[j].vat)) * 100) / 100;
      this.expences_price = Math.round((this.expences_price + parseFloat(expensesRow[j].price_orig)) * 100) / 100;
    }
    // this.priceRows.reset();
    this.expenses.setControl('expensesRow', this.expensesRow);
  }

  activaTab(tab) {
    let navs = document.getElementsByClassName('scarytab');
    for(let i = 0; i < navs.length; i++) {
      navs[i].classList.remove('active');
      navs[i].setAttribute('aria-selected', 'false');
    }
    // navs[tab].classList.add('active');
    // navs[tab].setAttribute('aria-selected', 'true');
    document.getElementById(tab + '-tab').classList.add('active');
    document.getElementById(tab + '-tab').setAttribute('aria-selected', 'true');
    let tabs = document.getElementsByClassName('tab-pane');
    for(let j = 0; j < tabs.length; j++) {
      tabs[j].classList.remove('show');
      tabs[j].classList.remove('active');
    }
    // tabs[tab].classList.add('show');
    // tabs[tab].classList.add('active');
    document.getElementById(tab).classList.add('show');
    document.getElementById(tab).classList.add('active');
  }

}
