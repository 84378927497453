import { Injectable } from '@angular/core';

@Injectable()
export class DataProvider {
  public menuPageTitle: string = 'home';
  public userData: any;
  public interval: any;
  constructor() { }
}
export function console_log(res) {
  console.log(res);
}