<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Dashboard</h1>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link scarytab active" id="super-tab" data-toggle="tab" href="#super" (click)="activaTab(0)" role="tab" aria-controls="super" aria-selected="true">Шилдгүүд</a>
          </li>
          <li class="nav-item" *ngIf="data.userData.permission == 1 || data.userData.permission == 2 || data.userData.permission == 6">
            <a class="nav-link scarytab" id="profit-tab" data-toggle="tab" href="#profit" (click)="activaTab(1)" role="tab" aria-controls="profit" aria-selected="false">Ашиг/Нэхэмжлэх</a>
          </li>
          <li class="nav-item" *ngIf="data.userData.permission == 1 || data.userData.permission == 2 || data.userData.permission == 6">
            <a class="nav-link scarytab" id="so-tab" data-toggle="tab" href="#so" (click)="activaTab(2)" role="tab" aria-controls="so" aria-selected="false">SO calendar</a>
          </li>
        </ul>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <!-- <div class="row">
      <div class="col-lg-3 col-6">
        <div class="small-box bg-info">
          <div class="inner">
            <h3>150</h3>
            <p>New Orders</p>
          </div>
          <div class="icon">
            <i class="ion ion-bag"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="small-box bg-success">
          <div class="inner">
            <h3>53<sup style="font-size: 20px;">%</sup></h3>
            <p>Bounce Rate</p>
          </div>
          <div class="icon">
            <i class="ion ion-stats-bars"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="small-box bg-warning">
          <div class="inner">
            <h3>44</h3>
            <p>User Registrations</p>
          </div>
          <div class="icon">
            <i class="ion ion-person-add"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="small-box bg-danger">
          <div class="inner">
            <h3>65</h3>

            <p>Unique Visitors</p>
          </div>
          <div class="icon">
            <i class="ion ion-pie-graph"></i>
          </div>
          <a href="#" class="small-box-footer"
            >More info <i class="fas fa-arrow-circle-right"></i
          ></a>
        </div>
      </div>
    </div> -->
    <!-- /.row -->
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="super" role="tabpanel" aria-labelledby="super-tab">
        <div class="testimonial-group" style="margin-left: 15px;">          
          <div class="row" *ngIf="data.userData.permission == 4 || data.userData.permission == 1 || data.userData.permission == 2
          || data.userData.permission == 6" style="overflow: auto; white-space: nowrap;
          display: inline-block;width: 100%;">   
            <h3 style="margin-left: 15px;">Энэ сарын шилдгүүд /SALES/</h3>         
            <div class="col-lg-3 col-6" *ngFor="let list of sales_lists;let index = index;" style="display: inline-block;float: none;">
              <!-- small box -->
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{list.profit == null ? 0 : list.profit | currency : ' '}}<sup style="font-size: 20px;">₮</sup></h3>
                  <p>{{index + 1}}. {{list.lastname.substring(0,1)}}.{{list.firstname}}</p>
                </div>
                <div class="icon">
                  <i class="ion ion-stats-bars"></i>
                </div>
              </div>
            </div>
          </div>        
        </div>

        <div class="testimonial-group" style="margin-left: 15px;">
          <div class="row" *ngIf="data.userData.permission == 5 || data.userData.permission == 1 || data.userData.permission == 2
          || data.userData.permission == 6" style="overflow: auto; white-space: nowrap;
          display: inline-block;width: 100%;">
            <h3 style="margin-left: 15px;">Энэ сарын шилдгүүд /TARIFF/</h3>
            <div class="col-lg-3 col-6" *ngFor="let list of tariff_lists;let index = index;" style="display: inline-block;float: none;">
              <!-- small box -->
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>{{list.count == null ? 0 : list.count}}<sup style="font-size: 20px;">ш</sup></h3>
                  <p>{{index + 1}}. {{list.lastname.substring(0,1)}}.{{list.firstname}}</p>
                </div>
                <div class="icon">
                  <i class="ion ion-stats-bars"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="testimonial-group" style="margin-left: 15px;">
          <div class="row" *ngIf="data.userData.permission == 8 || data.userData.permission == 1 || data.userData.permission == 2
          || data.userData.permission == 6" style="overflow: auto; white-space: nowrap;
          display: inline-block;width: 100%;">
            <h3 style="margin-left: 15px;">Custom list</h3>
            <div class="col-lg-3 col-6" *ngFor="let list of custom_lists;let index = index;" style="display: inline-block;float: none;">
              <!-- small box -->
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{list.count == null ? 0 : list.count}}<sup style="font-size: 20px;">ш</sup></h3>
                  <p>{{index + 1}}. {{list.lastname.substring(0,1)}}.{{list.firstname}}</p>
                </div>
                <div class="icon">
                  <i class="ion ion-stats-bars"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="testimonial-group" style="margin-left: 15px;">
          <div class="row" *ngIf="data.userData.permission == 9 || data.userData.permission == 1 || data.userData.permission == 2
          || data.userData.permission == 6" style="overflow: auto; white-space: nowrap;
          display: inline-block;width: 100%;">
            <h3 style="margin-left: 15px;">Site list</h3>
            <div class="col-lg-3 col-6" *ngFor="let list of site_lists;let index = index;" style="display: inline-block;float: none;">
              <!-- small box -->
              <div class="small-box bg-danger">
                <div class="inner">
                  <h3>{{list.count == null ? 0 : list.count}}<sup style="font-size: 20px;">ш</sup></h3>
                  <p>{{index + 1}}. {{list.lastname.substring(0,1)}}.{{list.firstname}}</p>
                </div>
                <div class="icon">
                  <i class="ion ion-stats-bars"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="profit" role="tabpanel" aria-labelledby="profit-tab">
        <div class="row" *ngIf="data.userData.permission == 6 || data.userData.permission == 1 || data.userData.permission == 2">          
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-success">
              <div class="inner">
                <h3>{{prev_year_profit | currency : ' '}}<sup style="font-size: 20px;">₮</sup></h3>
                <p>Өмнөх жилийн ашиг</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-success">
              <div class="inner">
                <h3>{{prev_month_profit | currency : ' '}}<sup style="font-size: 20px;">₮</sup></h3>
                <p>Өмнөх сарын ашиг</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-success">
              <div class="inner">
                <h3>{{month_profit | currency : ' '}}<sup style="font-size: 20px;">₮</sup></h3>
                <p>Энэ сарын ашиг</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="testimonial-group" style="margin-left: 15px;">
          <div class="row" *ngIf="data.userData.permission == 1 || data.userData.permission == 2
          || data.userData.permission == 6" style="overflow: auto; white-space: nowrap;
          display: inline-block;width: 100%;">
            <h3 style="margin-left: 15px;">Энэ сарын нэхэмжлэхүүд</h3>
            <div class="col-lg-3 col-6" *ngFor="let invoice of invoice_lists;let index = index;" style="display: inline-block;float: none;">
              <!-- small box -->
              <div class="small-box bg-warning">
                <div class="inner">
                  <h3>{{invoice.amount | currency : ' '}}<sup style="font-size: 20px;">₮</sup></h3>
                  <p>{{index + 1}}. {{invoice.shipping_order_name}}</p>
                </div>
                <div class="icon">
                  <i class="ion ion-stats-bars"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="so" role="tabpanel" aria-labelledby="so-tab" style="border: 1px solid #d3d3d3;">
        <div class="calendar-head">
          <button (click)="onPreviousMonth()" class="calendar-btn"> < </button>
          <button (click)="onNextMonth()" class="calendar-btn"> > </button>
          <span class="calendar-name">Shipping Orders {{ calendarCreator.getMonthName(monthNumber2) }} &nbsp; {{ year }} </span>
        </div>
        
        <br>
        
        <div class="calendar">
          <span *ngFor='let weekDay of weekDaysName' class="day-head">
            {{ weekDay }}
          </span>
        
          <span *ngFor='let day of monthDays' class="day">
            <div *ngIf="day.number && now_day == day.number && now_month == day.month" class="active">{{day.number}}</div>
            <div *ngIf="day.number && now_day != day.number && now_month == day.month">{{day.number}}</div>
            <div *ngIf="day.number && now_day != day.number && now_month != day.month">{{day.number}}</div>
            <div *ngIf="day.number && now_day == day.number && now_month != day.month">{{day.number}}</div>
            <div *ngIf="!day.number">&nbsp;</div> 
            <div *ngIf="day.number && day.quantity != 0" class="calendar-so-count" (click)="openUsers(soModal, day.year,day.month,day.number)">{{day.quantity}}</div>           
          </span>
        </div>
      </div>
    </div>

  </div>
  <ng-template #soModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{schedule_date}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">      
      <p *ngFor="let sche of schedules" style="border-bottom: 1px solid #d3d3d3;">{{sche.name}} <span style="float: right;">{{sche.quantity}}</span></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="close()">Хаах</button>
    </div>
  </ng-template>
  <!-- Main row -->
</section>
<!-- /.content -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>