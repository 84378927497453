import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  public isNew = true;
  id;
  lastname;
  firstname;
  phone;
  email;
  password;
  permissions = [];
  position_types = [];
  permission_id;
  position_type_id;
  job_date;

  constructor(private data: DataProvider, private appService: AppService,
    private route: ActivatedRoute,private rest: CallapiService,private toastr: ToastrService,
    private router: Router,private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'users';
  }

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id != null) {
      this.isNew = false;
    }
    this.spinner.show();
    this.rest.usersHelpers().subscribe((res) => {
      this.permissions = res.permissions;
      this.position_types = res.position_types;
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
    if(!this.isNew) {
      this.spinner.show();
      this.rest.userDetail(this.id).subscribe((res) => {
        this.spinner.hide();
        this.lastname = res.detail.lastname;
        this.firstname = res.detail.firstname;
        this.phone = res.detail.phone_number;
        this.email = res.detail.email;
        this.password = res.detail.password;
        if(res.detail.job_date != null) {
          this.job_date = new Date(res.detail.job_date);
        }
        this.permission_id = res.detail.permission;
        this.position_type_id = res.detail.position_type;
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
    } 
  }

  edit() {
    if(this.lastname == null || this.lastname == '') {
      this.toastr.error('Алдаа!', 'Та овгоо оруулна уу!');
      return;
    }
    if(this.firstname == null || this.firstname == '') {
      this.toastr.error('Алдаа!', 'Та нэрээ оруулна уу!');
      return;
    }
    if(this.phone == null || this.phone == '') {
      this.toastr.error('Алдаа!', 'Та утсаа оруулна уу!');
      return;
    }
    if(this.email == null || this.email == '') {
      this.toastr.error('Алдаа!', 'Та имэйл ээ оруулна уу!');
      return;
    }
    if(this.password == null || this.password == '') {
      this.toastr.error('Алдаа!', 'Та нууц үгээ оруулна уу!');
      return;
    }
    if(this.permission_id == null || this.permission_id == 0) {
      this.toastr.error('Алдаа!', 'Та нэвтрэх төрлөө сонгоно уу!');
      return;
    }
    if(this.position_type_id == null || this.position_type_id == 0) {
      this.toastr.error('Алдаа!', 'Та ажлын төрлөө сонгоно уу!');
      return;
    }
    if(this.job_date == null) {
      this.job_date = '';
    }
    const data = {
      id: this.id,
      lastname: this.lastname,
      firstname: this.firstname,
      phone: this.phone,
      email: this.email,
      password: this.password,
      permission_id: this.permission_id,
      position_type_id: this.position_type_id,
      type: 'edit',
      job_date: this.job_date
    }
    this.spinner.show();
    this.rest.editUser(data).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай засварлалаа.');
        this.router.navigate(['/users']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  save() {
    if(this.lastname == null || this.lastname == '') {
      this.toastr.error('Алдаа!', 'Та овгоо оруулна уу!');
      return;
    }
    if(this.firstname == null || this.firstname == '') {
      this.toastr.error('Алдаа!', 'Та нэрээ оруулна уу!');
      return;
    }
    if(this.phone == null || this.phone == '') {
      this.toastr.error('Алдаа!', 'Та утсаа оруулна уу!');
      return;
    }
    if(this.email == null || this.email == '') {
      this.toastr.error('Алдаа!', 'Та имэйл ээ оруулна уу!');
      return;
    }
    if(this.password == null || this.password == '') {
      this.toastr.error('Алдаа!', 'Та нууц үгээ оруулна уу!');
      return;
    }
    if(this.permission_id == null || this.permission_id == 0) {
      this.toastr.error('Алдаа!', 'Та нэвтрэх төрлөө сонгоно уу!');
      return;
    }
    if(this.position_type_id == null || this.position_type_id == 0) {
      this.toastr.error('Алдаа!', 'Та ажлын төрлөө сонгоно уу!');
      return;
    }
    if(this.job_date == null) {
      this.job_date = '';
    }
    const data = {
      lastname: this.lastname,
      firstname: this.firstname,
      phone: this.phone,
      email: this.email,
      password: this.password,
      permission_id: this.permission_id,
      position_type_id: this.position_type_id,
      type: 'add',
      job_date: this.job_date
    }
    this.spinner.show();
    this.rest.addUser(data).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай нэмлээ.');
        this.router.navigate(['/users']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  backList() {
    this.router.navigate(['/users']);
  }
}
