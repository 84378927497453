<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2" >
        <div class="col-sm-6 col-xs-6 col-6">
          <h1>Closed inquiry</h1>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content">
    <!-- Default box -->
    <div class="container-fluid">
      <div class="card">
        
        <div class="card-body">
          <!-- <form name="form"> -->
            <div class="mb-3 row">
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">Name</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="nameFormControl"
                    [(ngModel)]="searchTerm"
                    name="searchName"
                  />
                </mat-form-field>
                <!-- <input
                  id="table-complete-search"
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  name="searchTerm"
                  [(ngModel)]="searchTerm"
                /> -->
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">Consignee</mat-label>
                  <input type="text"
                        matInput
                        class="cons-input"
                        [formControl]="Consignee"
                        [matAutocomplete]="auto1"
                        (ngModelChange)="_filterCons()"
                        id="consignee"
                        >
                        
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="onConsigneeSelected($event.option)" >
                    <mat-option *ngFor="let option of consignees" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Start</mat-label>
                  <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="startDate" >
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>End</mat-label>
                  <input matInput [matDatepicker]="picker1"  (click)="picker1.open()"  [(ngModel)]="endDate">
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
  
              <span class="col col-form-label" *ngIf="loading">Loading...</span>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Шинэчлэх"
                (click)="clear()"
              >
                <i class="fa fa-times"></i>
              </button>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Хайх"
                (click)="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          
            <div class="table_wrapper">
              <table class="table table-striped table-hovered">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <!-- <th scope="col">#</th> -->
                    <th scope="col">Name</th>
                    <th scope="col">Consignee</th>
                    <th scope="col">Phone</th>
                    <th scope="col">e-mail</th>
                    <th scope="col">Validation Date</th>
                    <th scope="col">Quotation Date</th>
                    <th scope="col">Sales</th>
                    <th scope="col">Tariff</th>
                    <th scope="col">CBM</th>
                    <th scope="col">Closed at</th>
                    <th scope="col">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let closed of closedInquirys">                
                    <th scope="row">
                      <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Сэргээх"
                      (click)="restore(closed.id)"
                    >
                    <i class="fas fa-undo"></i>
                    </button>

                    </th>
                    <th scope="row">{{ closed.name }}</th>
                    <td>
                        {{ closed.consignee_name }}
                    </td>
                    <td>
                        {{closed.consignee_phone}}
                    </td>
                    <td>
                        {{closed.consginee_email}}
                    </td>
                    <td>{{closed.validaty_date}}</td>
                    <td>{{closed.quotation_date}}</td>
                    <td>{{closed.sales_name}}</td>
                    <td>{{closed.tariff_name}}</td>
                    <td>{{closed.cbm}}</td>
                    <td>{{closed.closed_at}}</td>
                    <td>{{closed.reason_name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          <!-- </form> -->
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
            <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
          </div>
          <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
            <label class="pages">No closed Inquiry</label>
          </div>
          <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
            <span
              [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
              (click)="prevPage()"
              class="prev"
              >&laquo;
            </span>
            <span
              *ngFor="let page of pagesIndex"
              [ngClass]="{ tod: currentIndex == page }"
              class="dot"
              (click)="setPage(page)"
            >{{page}}</span>
            <span
              [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
              (click)="nextPage()"
              class="next"
              >&raquo;
            </span>
          </div>
        </div>
        <!-- /.card-footer-->
      </div>
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
    <p style="color: #bf2429 " > Уншиж байна... </p>
  </ngx-spinner>