<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Site Manager</h1>
        </div>
      </div>
    </div>
</section>
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">        
        <div class="col-md-6">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Site мэдээлэл</h3>
            </div> 
            <div class="card-body m-0">
                <div class="row bordered item-row" *ngFor="let site of sites;let index = index;">
                    <div class="col-12 col-sm-12" (click)="loadSite(index)">
                        <p>
                          {{site.shipping_order_name}}/{{site.cntr_name}}
                        </p>
                        <p class="subtitle">{{site.consignee_name}}, {{site.consignee_phone}}, {{site.consignee_email}}</p>
                        <p class="subtitle">{{site.trans_type_name}}/<span *ngIf="site.check_term == 1" style="color: red;">ТӨЛБӨР ТӨЛӨГДӨӨГҮЙ</span>
                          <span *ngIf="site.check_term == 3" style="color: green;">ТӨЛБӨР ТӨЛӨГДСӨН</span>
                          <span *ngIf="site.check_term == 2" style="color: green;">CREDIT-тэй ТӨЛБӨР ТӨЛӨГДӨӨГҮЙ</span></p>
                        <div class="right-arrow">
                            <button
                                type="button"
                                class="btn btn-tool table-tool"
                                data-widget="collapse"
                                data-toggle="tooltip"
                                title="Дэлгэрэнгүй"
                            >
                            <i class="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <p *ngIf="sites.length == 0">Мэдээлэл одоогоор ирээгүй байна.</p>
            </div>          
            <div class="card-footer">
              <div *ngIf="sites.length >= 1" style="display: inline-block;">
                <label class="pages">All {{sites.length}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" id="right-side" style="display: none;">
            <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">Shipment Information</h3>
                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Ачаа хүлээлцэх акт"
                      (click)="openAct(shipping_order_id)"
                    >
                      <i class="fa fa-film"></i>
                    </button>
                  </div>
                </div> 
                <div class="card-body m-0">
                    <div class="row bordered mb-3">
                        <div class="col-12 col-sm-4">
                          <p>
                            {{commodity}}
                          </p>
                          <p  class="subtitle">Ачааны нэр</p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                          <p>{{trans_type_name}}</p>
                          <p class="subtitle">
                            Transportation Type
                          </p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                          <p>
                            {{shipping_order_name}}
                          </p>
                          <p class="subtitle">
                            Tracking Code
                          </p>
                        </div>
                      </div>
                      <div class="row bordered mb-3">
                        <div class="col-12 col-sm-4">
                          <p>
                            {{cntr_name}}
                          </p>
                          <p  class="subtitle">Cntr/AWB/Truck</p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                            <p>{{pol_country_name}}</p>
                            <p class="subtitle">
                              {{pol_city_name}}
                            </p>
                          </div>
                          <div class="col-12 col-sm-4 ">
                            <p>{{final_country_name}}</p>
                            <p class="subtitle">
                              {{final_city_name}}
                            </p>
                          </div>
                      </div>
                      <div class="row bordered mb-3">
                        <div class="col-12 col-sm-4">
                          <p> {{box_weight}} kg </p>
                          <p class="subtitle">Ачааны жин</p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                          <p>{{box_length}}x{{box_width}}x{{box_height}}</p>
                          <p class="subtitle">
                            /L x W x H/ (cm)
                          </p>
                        </div>
                        <div class="col-12 col-sm-4">
                            <p> {{terminal_name}} </p>
                            <p class="subtitle">Терминал</p>
                          </div>
                      </div>
                      <div class="row bordered mb-3">
                        <div class="col-12 col-sm-3">
                          <p>
                            {{ata_destination}}
                          </p>
                          <p  class="subtitle">ATA Destination</p>
                        </div>
                        <div class="col-12 col-sm-3">
                            <p>{{ata_border}}</p>
                            <p class="subtitle">
                                ATA Border
                            </p>
                          </div>
                          <div class="col-12 col-sm-3">
                            <p>{{eta_border}}</p>
                            <p class="subtitle">
                                ETA Border
                            </p>
                          </div>
                          <div class="col-12 col-sm-3">
                            <p>{{atd_border}}</p>
                            <p class="subtitle">
                                ATD Border
                            </p>
                          </div>
                      </div>
                </div>      
                <div class="card-footer">
                
                </div>
            </div>
            <div class="card card-primary" *ngIf="data.userData.permission == 9">
                <!-- <div class="card-header">
                  <h3 class="card-title">Shipment Information</h3>
                </div>  -->
                <div class="card-body m-0">
                    <div class="row">
                        <div class="col-6">
                          <mat-form-field class="example-full-width fsize">
                            <mat-label>Үнэлгээ</mat-label>
                            <mat-select [(ngModel)]="assessment">
                              <mat-option value="1">1</mat-option>
                              <mat-option value="2">2</mat-option>
                              <mat-option value="3">3</mat-option>
                              <mat-option value="4">4</mat-option>
                              <mat-option value="5">5</mat-option>
                              <mat-option value="6">6</mat-option>
                              <mat-option value="7">7</mat-option>
                              <mat-option value="8">8</mat-option>
                              <mat-option value="9">9</mat-option>
                              <mat-option value="10">10</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="example-full-width fsize">
                                <mat-label>Comments</mat-label>
                                <textarea matInput class="textHeight" rows="5" [(ngModel)]="comments" ></textarea>
                            </mat-form-field>
                        </div>                        
                      </div>
                      <p *ngIf="!check_term" style="color: red;">ТӨЛБӨР ТӨЛӨГДӨӨГҮЙ ДУТУУ БАЙНА.</p>
                      <button *ngIf="check_term" type="button" color="primary" class="savebtn ml-3 btn btn-primary" (click)="editSite()">Шинэчлэх</button>
                </div>
            </div>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
    <p style="color: #bf2429 " > Уншиж байна... </p>
  </ngx-spinner>