import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import { ENCRYPT_SECRET_KEY } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMAGE_FILE_URL } from '../../../environments/environment';

@Component({
  selector: 'app-operation-edit',
  templateUrl: './operation-edit.component.html',
  styleUrls: ['./operation-edit.component.scss'],
})
export class OperationEditComponent implements OnInit {
  fileName = '';
  progress = 0;
  id;
  country = [];
  city = [];
  status = [];
  status_id;
  country_id;
  city_id;
  date;
  eta_border;
  ata_border;
  atd_border;
  ata_destination;
  shipping_order_name;
  cntr_name;
  cntr_name_new;
  pol_country_name;
  pol_city_name;
  final_country_name;
  final_city_name;
  trans_type_name;
  terminal_name;
  consignee_name;
  sales_name;
  tariff_name;
  commodity;
  box_weight;
  box_height;
  box_width;
  box_length;
  quotation_date;
  custom_id;
  custom_users = [];
  shipping_order_info_traces= [];
  shipping_order_files = [];
  imageFile = [];
  shipping_order_id;
  cntrs = [];
  closeResult = '';
  imageFileUrl = IMAGE_FILE_URL;

  constructor(
    public appService: AppService,
    public data: DataProvider,
    public router: Router,
    public rest: CallapiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    ) {
    data.menuPageTitle = 'operation';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.spinner.show();
    this.rest.operationHelpers().subscribe((res) => {
      this.country = res.countries;
      this.status = res.tracing_statuses;

      this.rest.operationDetail(this.id).subscribe((res1) => {
        this.spinner.hide();
        this.shipping_order_name = res1.detail.shipping_order_name;
        this.cntr_name = res1.detail.cntr_name;
        this.pol_country_name = res1.detail.pol_country_name;
        this.pol_city_name = res1.detail.pol_city_name;
        this.final_country_name = res1.detail.final_country_name;
        this.final_city_name = res1.detail.final_city_name;
        if(res1.detail.eta_border != null) {
          this.eta_border = new Date(res1.detail.eta_border);
        }
        if(res1.detail.ata_border != null) {
          this.ata_border = new Date(res1.detail.ata_border);
        }
        if(res1.detail.atd_border != null) {
          this.atd_border = new Date(res1.detail.atd_border);
        }
        if(res1.detail.ata_destination != null) {
          this.ata_destination = new Date(res1.detail.ata_destination);
        }
        this.trans_type_name = res1.detail.trans_type_name;
        this.consignee_name = res1.detail.consignee_name;
        this.terminal_name = res1.detail.terminal_name;
        this.sales_name = res1.detail.sales_name;
        this.tariff_name = res1.detail.tariff_name;
        this.commodity = res1.detail.commodity;
        this.box_height = res1.detail.box_height;
        this.box_length = res1.detail.box_length;
        this.box_weight = res1.detail.box_weight;
        this.box_width = res1.detail.box_width;
        this.quotation_date = res1.detail.quotation_date;
        this.custom_users = res1.detail.custom_users;
        this.shipping_order_id = res1.detail.shipping_order_id;
        this.shipping_order_info_traces = res1.shipping_order_info_traces;
        this.shipping_order_files = res1.shipping_order_files;
        this.cntrs = res1.detail.cntrs;
        this.custom_id = res1.detail.custom_id;
      },(err) => {
        console.log(err);
        this.spinner.hide();
      });
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  setFileName(fileInput) {
    let files: File[] = fileInput.files;
    if (files.length < 1) {
      document.getElementById('img-label').innerHTML = 'Зургууд оруулах';
      return;
    }
    for(let i = 0; i < files.length; i++) {
      let file = files[i];
      const reader = new FileReader();
      let self = this;
      reader.onload = function(e) {
        self.imageFile.push(e.target.result);
      };
      reader.readAsDataURL(file);
      document.getElementById('img-label').innerHTML = file.name;
    }
  }

  countryChange() {
    this.spinner.show();
    this.rest.getCity(this.country_id).subscribe(
      (res) => {
        this.spinner.hide();
        this.city = res.citys;
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  backList() {
    this.router.navigate(['/operation']);
  }

  editCntr() {
    if(this.cntr_name_new == null || this.cntr_name_new == '') {
      this.toastr.error('Алдаа!', 'CNTR нэрээ оруулна уу!');
      return;
    }
    this.spinner.show();
    var m = new Date(new Date().toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
    var dateString =
        m.getFullYear() + "-" +
        ("0" + (m.getMonth()+1)).slice(-2) + "-" +
        ("0" + m.getDate()).slice(-2) + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) + ":" +
        ("0" + m.getSeconds()).slice(-2);
    this.rest.editOperation({id: this.id, user_id: this.data.userData.id, shipping_order_id: this.shipping_order_id, cntr_name: this.cntr_name_new, type: 'nogaali'}).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай хадгаллаа.');
        this.cntrs.push({name: this.cntr_name_new, cntr_date: dateString});
        this.cntr_name = this.cntr_name_new;
        this.cntr_name_new = '';        
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  addTrace() {
    if(this.country_id == null || this.country_id == '') {
      this.toastr.error('Алдаа!', 'Улсаа сонгоно уу!');
      return;
    }
    if(this.city_id == null || this.city_id == '') {
      this.toastr.error('Алдаа!', 'Хотоо сонгоно уу!');
      return;
    }
    if(this.date == null || this.date == '') {
      this.toastr.error('Алдаа!', 'Та огноогоо сонгоно уу!');
      return;
    }
    if(this.status_id == null || this.status_id == '') {
      this.toastr.error('Алдаа!', 'Та статусаа сонгоно уу!');
      return;
    }
    this.spinner.show();
    this.rest.addOperationTrace({id: this.id, country_id: this.country_id, city_id: this.city_id, 
      trace_date: this.date, tracing_status_id: this.status_id}).subscribe((res) => {
        this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай нэмлээ.');
        this.getTraces();
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  setGaali() {
    if(this.shipping_order_files.length == 0) {
      this.toastr.error('Алдаа!', 'Та эхлээд явцын зургуудаа оруулна уу!');
      return;
    }
    // if(this.eta_border == null || this.eta_border == '') {
    //   this.toastr.error('Алдаа!', 'ETA огноогоо сонгоно уу!');
    //   return;
    // }
    // if(this.ata_border == null || this.ata_border == '') {
    //   this.toastr.error('Алдаа!', 'АTA огноогоо сонгоно уу!');
    //   return;
    // }
    // if(this.atd_border == null || this.atd_border == '') {
    //   this.toastr.error('Алдаа!', 'ATD огноогоо сонгоно уу!');
    //   return;
    // }
    // if(this.ata_destination == null || this.ata_destination == '') {
    //   this.toastr.error('Алдаа!', 'ATA Destination огноогоо сонгоно уу!');
    //   return;
    // }
    if((this.eta_border == null || this.eta_border == '') && (this.ata_border == null || this.ata_border == '')
      && (this.atd_border == null || this.atd_border == '') && (this.ata_destination == null || this.ata_destination == '')) {
      this.toastr.error('Алдаа!', 'Та огноогоо сонгоно уу! Бүгд хоосон байж болохгүй!');
      return;
    }
    if(this.custom_id == null || this.custom_id == '') {
      this.toastr.error('Алдаа!', 'Та Custom Manager ээ сонгоно уу!');
      return;
    }
    this.spinner.show();
    this.rest.editOperation({id: this.id, user_id: this.data.userData.id, eta_border: this.eta_border, ata_border: this.ata_border, 
      atd_border: this.atd_border, ata_destination: this.ata_destination,custom_id: this.custom_id, type: 'gaali'}).subscribe((res) => {
        this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай шинэчиллээ.');
        if(this.data.userData.permission == 5) {
          const userdata = {
            id: this.data.userData.id,
            lname: this.data.userData.lname,
            fname: this.data.userData.fname,
            permission: this.data.userData.permission,
            email: this.data.userData.email,
            phone: this.data.userData.phone,
            job_date: this.data.userData.job_date,
            image: this.data.userData.image,
            name: this.data.userData.name,
            password: this.data.userData.password,
            position_type: this.data.userData.position_type,
            create_date: this.data.userData.create_date,
            notifications: {
              inquiry: this.data.userData.notifications.inquiry,
              shipping_order: this.data.userData.notifications.shipping_order,
              operation: this.data.userData.notifications.operation - 1,
              custom: this.data.userData.notifications.custom,
              site: this.data.userData.notifications.site,
              invoices: this.data.userData.notifications.invoices
            },
          };
          const encrypt = CryptoJS.AES.encrypt(
            JSON.stringify(userdata),
            ENCRYPT_SECRET_KEY
          ).toString();
          localStorage.setItem('token', encrypt);
        }
        this.router.navigate(['/operation']);
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  getTraces() {
    this.rest.operationTraceList(this.id).subscribe((res) => {
      this.shipping_order_info_traces = res;
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  deleteTrace(id) {
    this.spinner.show();
    this.rest.deleteOperationTrace({id: id}).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай устгалаа.');
        this.getTraces();
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  setFiles() {
    if(this.imageFile.length == 0) {
      this.toastr.error('Алдаа!', 'Та зургуудаа оруулна уу!');
      return;
    }
    let count = 0;
    this.spinner.show();
    for(let i = 1; i <= this.imageFile.length; i++) {
      let image = 'SM-OPERATION-IMAGES-' + i + '-' + new Date().getTime;
      this.rest.uploadImage(this.imageFile[i - 1], image).subscribe((res) => {
        if(res.success) {
          this.rest.fileOperation({id: this.id, name: '', image: image, type: '1'}).subscribe((res) => {
            if(res.message == 'OK') {
              count++;
              if(count == this.imageFile.length) {
                this.spinner.hide();
                this.toastr.success('Success', 'Амжилттай зургууд хууллаа.');
                window.location.reload();
              }
            } else {
              this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
            }
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          });
        } else {
          this.spinner.hide();
        }
      }, (err1) => {
        console.log(err1);
        this.spinner.hide();
      });
    }
  }

  report() {
    window.open('/operation/report;id=' + this.id, '_blank');
  }

  showCntr(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
