<div class="main" *ngIf="type == 'mnt'">
<div class="pageA4">
  <div class="printArea">

    <div class="invoiceContainer">
      <table width="100%">
        <tr>
          <td width="33.3%" class="align-center"> <img src="/assets/img/logo.png" width="90" /> </td>
          <td width="33.3%" class="align-center">НЭХЭМЖЛЭХ</td>
          <td width="33.4%" class="align-center">Санхүү, эдийн засгийн сайд, Үндэсний статистикийн газрын даргын 2018 оны 12-р сарын 5-ны 347 тоот тушаалын хавсралт</td>
        </tr>
      </table>
      <table width="100%">
        <tr>
          <td width="33.3%" class="align-center back-grey "> Төлөгч<br>байгууллага </td>
          <td width="23.7%" class="align-center">{{payer_name}}</td>
          <td width="53%" class="no-padding">
            <table width="100%" class="margin-outline">
              <tr>
                <td class="back-grey ">Нэхэмжлэхийн дугаар</td>
                <td class="back-grey ">{{invoice_number}}</td>
              </tr>
              <tr>
                <td>Нэхэмжилсэн огноо</td>
                <td>{{invoice_date}}</td>
              </tr>
              <tr>
                <td class="back-grey ">Төлбөр төлөх хугацаа</td>
                <td class="back-grey ">{{invoice_date}}</td>
              </tr>
              <tr>
                <td colspan="2" class="align-center">
                  Монгол улс, Улаанбаатар хот, Хан-Уул дүүрэг,
                  15-р хороо, Махатма Гандигийн гудамж, 
                  Галакси Тауэр 1005 тоот. <br />
                  Утас: +976-76000099, 90720099 <br />
                  И-мэйл: accountant@simataimongolia.com
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table width="100%">
        <tr>
          <td width="40%">Тээврийн төрөл</td>
          <td width="60%">
            {{trans_type_name}}                                                                                                                                                                   
          </td>
        </tr>
        <tr>
          <td width="40%" class="back-grey">Ачаа нийлүүлэгч</td>
          <td width="60%" class="back-grey">--/--</td>
        </tr>
        <tr>
          <td width="40%">Ачааны дугаар</td>
          <td width="60%">{{shipping_order_name}}</td>
        </tr>
        <tr>
          <td width="40%" class="back-grey">Ачааны жин</td>
          <td width="60%" class="back-grey">
            {{box_weight}} кг, {{box_height*box_length*box_width}} м3
          </td>
        </tr>
        <tr>
          <td width="40%">Ачааны нэр</td>
          <td width="60%">{{commodity}}</td>
        </tr>
      </table>

      <table width="100%" class="align-center">
        <tr>
          <td class="back-grey" width="30%">Нэрс</td>
          <td class="back-grey" width="58">Тоо хэмжээ</td>
          <td class="back-grey">Нэгж үнэ</td>
          <td class="back-grey">Дүн</td>
          <td class="back-grey">Ханш</td>
          <td class="back-grey">Нийт дүн</td>
        </tr>
        
                                  <!-- Gadaad -->
                                                          <tr>
                <td width="30%">
                  Гадаад тээврийн зардал <br>
                  {{pol_city_name}}, 
                  {{pol_country_name}}
                                        -
                  {{border_name}}
                               
                </td>
                <td>1.00</td>
                <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}}</td>
                <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}}</td>
                <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].rate | currency : ' '}}</td>
                <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}} </td>
              </tr>
                                            
                                        
                        <tr>
            <td class="back-grey"> <strong> Нийт дүн </strong></td>
            <td colspan="4" class="back-grey"></td>                    
            <td *ngIf="inquiry_expences.length > 0" class="back-grey"> <strong> {{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}} </strong></td>
          </tr>
          <!-- Dotood -->
                                                      
                <tr *ngIf="inquiry_expences.length > 1">
                  <td>Дотоод тээврийн зардал</td>
                  <td>1.00 </td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].price | currency : ' '}}</td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].price | currency : ' '}}</td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].rate | currency : ' '}}</td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].price | currency : ' '}}</td>
                </tr>
                                                                                  
                                                    <tr>
            <td>Дотоод зардал</td>
            <td colspan="4"></td>                    
            <td *ngIf="inquiry_expences.length > 2">{{currency_name}} {{inquiry_expences[2].price | currency : ' '}}</td>
            <td *ngIf="inquiry_expences.length == 2">{{currency_name}} 0.00</td>
          </tr>
          <tr>
            <td>НӨАТ</td>
            <td colspan="4"></td>                    
            <td *ngIf="inquiry_expences.length > 2">{{currency_name}} {{(inquiry_expences[2].vat+inquiry_expences[1].vat) | currency : ' '}}</td>
            <td *ngIf="inquiry_expences.length == 2">{{currency_name}} {{inquiry_expences[1].vat | currency : ' '}}</td>
            <td *ngIf="inquiry_expences.length == 1">{{currency_name}} 0.00</td>
          </tr>
          <tr>
            <td class="back-grey"> <strong> Нийт дүн </strong> </td>
            <td colspan="4" class="back-grey"></td>                    
            <td *ngIf="inquiry_expences.length > 0" class="back-grey"><strong> {{currency_name}} {{(price-inquiry_expences[0].total_amount) | currency : ' '}} </strong></td>
          </tr>           
          <tr>
            <td class="back-grey"><strong style="text-transform: uppercase">Нийт төлөх дүн</strong></td>
            <td colspan="4" class="back-grey"></td>
            <td class="back-grey"><strong>{{currency_name}} {{price | currency : ' '}}</strong></td>
          </tr>
          <tr *ngIf="custom == null">
            <td class="back-grey"><strong style="text-transform: uppercase">Нэхэмжлэх дүн</strong></td>
            <td colspan="4" class="back-grey"></td>
            <td class="back-grey"><strong>{{currency_name}} {{invoice_amount | currency : ' '}} / {{pre_payment}}%</strong></td>
          </tr>
                  </table>

      <table width="100%" class="align-center">
        <tr>
          <td width="30%"></td>
          <td colspan="5">&nbsp;</td>
        </tr>
        <tr>
          <td class="back-grey"><strong>Мөнгөн дүн үсгээр</strong></td>
          <td colspan="5" class="back-grey">
            {{total_charac}}
          </td>
        </tr>
        <tr>
          <td width="30%"></td>
          <td colspan="5">&nbsp;</td>
        </tr>
        <tr>
          <td width="30%" class="back-grey"><strong>Дансны мэдээлэл</strong></td>
          <td colspan="5" class="back-grey">
            <strong>
              Голомт банк: MNT-3655101683, USD-3655101684, RMB-3655101685 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Симатай Монголиа ХХК
            </strong>
          </td>
        </tr>
      </table>

      <table width="100%" class="align-center">
        <tr>
          <td width="30%">
            (Тамга)
          </td>
          <td width="25%">
            
          </td>
          <td class="no-padding">

            
            <div style="text-align: left; text-transform: uppercase; font-size: 10px; padding: 10px 0; padding-left: 5px;">
              Гүйцэтгэх захирал &nbsp;
              
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Б.Тэмүүлэн
            </div>
            <div class="back-grey" style="border-top: 1px solid #000; border-bottom: 1px solid #000; padding: 40px 0">&nbsp;</div>
            <div style="text-align: left; text-transform: uppercase; font-size: 10px; padding: 10px 0; padding-left: 5px;">
              Нягтлан бодогч 
              
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              С.Уянга
            </div>
          </td>
        </tr>
      </table>

    </div>

  </div>
</div>
</div>

<div class="main" *ngIf="type == 'usd'">
  <div class="pageA4">
    <div class="printArea">
  
      <div class="invoiceContainer">
        <table width="100%">
          <tr>
            <td width="33.3%" class="align-center"> <img src="/assets/img/logo.png" width="90" /> </td>
            <td width="33.3%" class="align-center">INVOICE</td>
            <td width="33.4%" class="align-center">Санхүү, эдийн засгийн сайд, Үндэсний статистикийн газрын даргын 2018 оны 12-р сарын 5-ны 347 тоот тушаалын хавсралт</td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td width="33.3%" class="align-center back-grey "> Payer<br>company </td>
            <td width="23.7%" class="align-center">{{payer_name}}</td>
            <td width="53%" class="no-padding">
              <table width="100%" class="margin-outline">
                <tr>
                  <td class="back-grey ">Invoice number</td>
                  <td class="back-grey ">{{invoice_number}}</td>
                </tr>
                <tr>
                  <td>Invoice date</td>
                  <td>{{invoice_date}}</td>
                </tr>
                <tr>
                  <td class="back-grey ">Due date</td>
                  <td class="back-grey ">{{invoice_date}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="align-center">
                    Монгол улс, Улаанбаатар хот, Хан-Уул дүүрэг,
                    15-р хороо, Махатма Гандигийн гудамж, 
                    Галакси Тауэр 1005 тоот. <br />
                    Phone: +976-76000099, 90720099 <br />
                    Email: accountant@simataimongolia.com
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table width="100%">
          <tr>
            <td width="40%">Transport type</td>
            <td width="60%">
              {{trans_type_name}}                                                                                                                                                                   
            </td>
          </tr>
          <tr>
            <td width="40%" class="back-grey">Commodity supplier</td>
            <td width="60%" class="back-grey">--/--</td>
          </tr>
          <tr>
            <td width="40%">Commodity number</td>
            <td width="60%">{{shipping_order_name}}</td>
          </tr>
          <tr>
            <td width="40%" class="back-grey">Commodity Weight</td>
            <td width="60%" class="back-grey">
              {{box_weight}} кг, {{box_height*box_length*box_width}} м3
            </td>
          </tr>
          <tr>
            <td width="40%">Commodity name</td>
            <td width="60%">{{commodity}}</td>
          </tr>
        </table>
  
        <table width="100%" class="align-center">
          <tr>
            <td class="back-grey" width="30%">Names</td>
            <td class="back-grey" width="58">Quantity</td>
            <td class="back-grey">Price</td>
            <td class="back-grey">Total</td>
            <td class="back-grey">Currency</td>
            <td class="back-grey">Total price</td>
          </tr>
          
                                    <!-- Gadaad -->
                                                            <tr>
                  <td width="30%">
                    Foreign shipping costs <br>
                    {{pol_city_name}}, 
                    {{pol_country_name}}
                                          -
                    {{border_name}}
                                 
                  </td>
                  <td>1.00</td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}}</td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}}</td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].rate | currency : ' '}}</td>
                  <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}} </td>
                </tr>
                                              
                                          
                          <tr>
              <td class="back-grey"> <strong> Total amount </strong></td>
              <td colspan="4" class="back-grey"></td>                    
              <td *ngIf="inquiry_expences.length > 0" class="back-grey"> <strong> {{currency_name}} {{inquiry_expences[0].total_amount | currency : ' '}} </strong></td>
            </tr>
            <!-- Dotood -->
                                                        
                  <tr *ngIf="inquiry_expences.length > 1">
                    <td>Internal transport costs</td>
                    <td>1.00 </td>
                    <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].price | currency : ' '}}</td>
                    <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].price | currency : ' '}}</td>
                    <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].rate | currency : ' '}}</td>
                    <td *ngIf="inquiry_expences.length > 0">{{currency_name}} {{inquiry_expences[1].price | currency : ' '}}</td>
                  </tr>
                                                                                    
                                                      <tr>
              <td>Internal costs</td>
              <td colspan="4"></td>                    
              <td *ngIf="inquiry_expences.length > 2">{{currency_name}} {{inquiry_expences[2].price | currency : ' '}}</td>
              <td *ngIf="inquiry_expences.length == 2">{{currency_name}} 0.00</td>
            </tr>
            <tr>
              <td>VAT</td>
              <td colspan="4"></td>                    
              <td *ngIf="inquiry_expences.length > 2">{{currency_name}} {{(inquiry_expences[2].vat+inquiry_expences[1].vat) | currency : ' '}}</td>
              <td *ngIf="inquiry_expences.length == 2">{{currency_name}} {{inquiry_expences[1].vat | currency : ' '}}</td>
              <td *ngIf="inquiry_expences.length == 1">{{currency_name}} 0.00</td>
            </tr>
            <tr>
              <td class="back-grey"> <strong> Total amount </strong> </td>
              <td colspan="4" class="back-grey"></td>                    
              <td *ngIf="inquiry_expences.length > 0" class="back-grey"><strong> {{currency_name}} {{(price-inquiry_expences[0].total_amount) | currency : ' '}} </strong></td>
            </tr>           
            <tr>
              <td class="back-grey"><strong style="text-transform: uppercase">Total amount</strong></td>
              <td colspan="4" class="back-grey"></td>
              <td class="back-grey"><strong>{{currency_name}} {{price | currency : ' '}}</strong></td>
            </tr>
            <tr>
              <td class="back-grey"><strong style="text-transform: uppercase">Invoice amount</strong></td>
              <td colspan="4" class="back-grey"></td>
              <td class="back-grey"><strong>{{currency_name}} {{invoice_amount | currency : ' '}} / {{pre_payment}}%</strong></td>
            </tr>
                    </table>
  
        <table width="100%" class="align-center">
          <tr>
            <td width="30%"></td>
            <td colspan="5">&nbsp;</td>
          </tr>
          <tr>
            <td class="back-grey"><strong>Amount in letters</strong></td>
            <td colspan="5" class="back-grey">
              {{total_charac}}
            </td>
          </tr>
          <tr>
            <td width="30%"></td>
            <td colspan="5">&nbsp;</td>
          </tr>
          <tr>
            <td width="30%" class="back-grey"><strong>Account information</strong></td>
            <td colspan="5" class="back-grey">
              <strong>
                Golomt bank: MNT-3655101683, USD-3655101684, RMB-3655101685 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Simatai Mongolia LLC
              </strong>
            </td>
          </tr>
        </table>
  
        <table width="100%" class="align-center">
          <tr>
            <td width="30%">
              (Stamp)
            </td>
            <td width="25%">
              
            </td>
            <td class="no-padding">
  
              
              <div style="text-align: left; text-transform: uppercase; font-size: 10px; padding: 10px 0; padding-left: 5px;">
                CEO &nbsp;
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                B.Temuulen
              </div>
              <div class="back-grey" style="border-top: 1px solid #000; border-bottom: 1px solid #000; padding: 40px 0">&nbsp;</div>
              <div style="text-align: left; text-transform: uppercase; font-size: 10px; padding: 10px 0; padding-left: 5px;">
                Accountant
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;
                S.Uyanga
              </div>
            </td>
          </tr>
        </table>
  
      </div>
  
    </div>
  </div>
  </div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>