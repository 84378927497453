<footer class="main-footer">
  <div class="float-right d-none d-sm-block">
    <b>Version</b> {{ appVersion }}
  </div>
  <strong
    >Copyright &copy; 2022-{{date}}
    <a href="http://unisub-tech.com" target="_blank">UNISUB LLC</a></strong
  >
  All rights reserved.
</footer>
