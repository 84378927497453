<nav class="main-header navbar navbar-expand navbar-white navbar-light sticky">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()" role="button"
        ><i class="fas fa-bars"></i
      ></a>
    </li>
    <h3>Тээвэр зуучийн систем</h3>
  </ul>

  <ul class="navbar-nav ml-auto">
    <!-- <app-messages-dropdown-menu></app-messages-dropdown-menu> -->
    <app-notifications-dropdown-menu title="Notifications"></app-notifications-dropdown-menu>
    <!-- <app-user-dropdown-menu></app-user-dropdown-menu> -->
    <li class="nav-item">
      <a
        class="nav-link"
        data-widget="control-sidebar"
        data-slide="true"
        (click)="logout()"
        role="button"
        title="Гарах"
      >
        <i class="fas fa-sign-out-alt" style="font-size: 25px;"></i>
      </a>
    </li>
  </ul>
</nav>
