import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import * as CryptoJS from 'crypto-js';
import { ENCRYPT_SECRET_KEY } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-side-manager',
  templateUrl: './side-manager.component.html',
  styleUrls: ['./side-manager.component.scss'],
})

export class SideManagerComponent implements OnInit {
  sites = [];
  commodity;
  trans_type_name;
  shipping_order_name;
  cntr_name;
  pol_country_name;
  pol_city_name;
  final_country_name;
  final_city_name;
  box_weight;
  box_length;
  box_width;
  box_height;
  terminal_name;
  ata_border;
  eta_border;
  atd_border;
  ata_destination;
  comments;
  cc_started;
  cc_finished;
  id;
  assessment;
  finance_check;
  payment_term_name;
  check_term = true;
  shipping_order_id;

  constructor(public appService: AppService, public data: DataProvider, public router: Router,
    private rest: CallapiService, private toastr: ToastrService, private spinner: NgxSpinnerService,) {
    data.menuPageTitle = 'side-manager';
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    this.spinner.show();
    this.rest.sitesList().subscribe((res) =>{
      this.sites = res;
      for(let i = 0; i < this.sites.length; i++) {
        if(this.sites[i].payment_term_name == 'Without Credit' && this.sites[i].finance_check == '') {
          this.sites[i].check_term = 1;
        } else if(this.sites[i].payment_term_name == 'With Credit' && this.sites[i].finance_check == '') {
          this.sites[i].check_term = 2;
        } else {
          this.sites[i].check_term = 3;
        }
      }
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  loadSite(id) {
    this.commodity = this.sites[id].commodity;
    this.trans_type_name = this.sites[id].trans_type_name;
    this.shipping_order_name = this.sites[id].shipping_order_name;
    this.cntr_name = this.sites[id].cntr_name;
    this.pol_country_name = this.sites[id].pol_country_name;
    this.pol_city_name = this.sites[id].pol_city_name;
    this.final_country_name = this.sites[id].final_country_name;
    this.final_city_name = this.sites[id].final_city_name;
    this.box_weight = this.sites[id].box_weight;
    this.box_length = this.sites[id].box_length;
    this.box_width = this.sites[id].box_width;
    this.box_height = this.sites[id].box_height;
    this.terminal_name = this.sites[id].terminal_name;
    this.ata_border = this.sites[id].ata_border;
    this.eta_border = this.sites[id].eta_border;
    this.atd_border = this.sites[id].atd_border;
    this.ata_destination = this.sites[id].ata_destination;
    this.finance_check = this.sites[id].finance_check;
    this.payment_term_name = this.sites[id].payment_term_name;
    this.shipping_order_id = this.sites[id].shipping_order_id;
    if(this.payment_term_name == 'Without Credit' && this.finance_check == '') {
      this.check_term = false;
    }
    this.id = this.sites[id].id;
    document.getElementById('right-side').style.display = 'block';
    let items = document.getElementsByClassName('item-row');
    for(let i = 0; i < items.length; i++) {
      items[i].classList.remove('active');
    }
    items[id].classList.add('active');
  }

  editSite() {
    if(this.assessment == null || this.assessment == '') {
      this.toastr.error('Алдаа!', 'Та үнэлгээгээ сонгоно уу!');
      return;
    }
    if(this.comments == null) {
      this.comments = '';
    }
    this.spinner.show();
    this.rest.editSite({id: this.id, assessment: this.assessment, comments: this.comments}).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай хүлээлгэж өглөө.');
        if(this.data.userData.permission == 9) {
          const userdata = {
            id: this.data.userData.id,
            lname: this.data.userData.lname,
            fname: this.data.userData.fname,
            permission: this.data.userData.permission,
            email: this.data.userData.email,
            phone: this.data.userData.phone,
            job_date: this.data.userData.job_date,
            image: this.data.userData.image,
            name: this.data.userData.name,
            password: this.data.userData.password,
            position_type: this.data.userData.position_type,
            create_date: this.data.userData.create_date,
            notifications: {
              inquiry: this.data.userData.notifications.inquiry,
              shipping_order: this.data.userData.notifications.shipping_order,
              operation: this.data.userData.notifications.operation,
              custom: this.data.userData.notifications.custom,
              site: this.data.userData.notifications.site - 1,
              invoices: this.data.userData.notifications.invoices
            },
          };
          const encrypt = CryptoJS.AES.encrypt(
            JSON.stringify(userdata),
            ENCRYPT_SECRET_KEY
          ).toString();
          localStorage.setItem('token', encrypt);
        }
        window.location.reload();
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  openAct(id: any) {
    window.open('/custom/act;id=' + id, '_blank');
  }

}
