<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="containeriu">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h5 class="title-text">Shipment Information</h5>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <button
            type="button"
            class="btn btn-tool back-tool"
            data-widget="collapse"
            data-toggle="tooltip"
            title="Буцах"
            (click)="backList()"
          >
            <i class="fa fa-arrow-circle-left"></i>
          </button>
        </ol>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <mat-accordion class="card">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="card-title">Main Information</h3>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="card-body" class="m-0">
          <div class="row bordered mb-3">
            <div class="col-12 col-sm-4">
              <p>
                {{consignee_name}}
              </p>
              <p  class="subtitle">Захиалгын нэр</p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>{{trans_type_name}}</p>
              <p class="subtitle">
                Transportation Equipment
              </p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>
                {{shipping_order_name}}
              </p>
              <p class="subtitle">
                Tracking Code
              </p>
            </div>
          </div>
          <div class="row bordered mb-3">
            <div class="col-12 col-sm-4">
              <p>{{cntr_name}}</p>
              <p class="subtitle">Cntr/AWB/Truck</p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>{{pol_country_name}}</p>
              <p class="subtitle">
                {{pol_city_name}}
              </p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>{{final_country_name}}</p>
              <p class="subtitle">
                {{final_city_name}}
              </p>
            </div>
          </div>
          <div class="row bordered mb-3">
            <div class="col-12 col-sm-4">
              <p> {{box_weight}} kg </p>
              <p class="subtitle">Жин</p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>{{commodity}}</p>
              <p class="subtitle">
                Commodity
              </p>
            </div>
            <div class="col-12 col-sm-4 ">
              <mat-form-field class="example-full-width" >
                <input type="text"
                       aria-label="Number"
                       matInput
                       [(ngModel)]="terminal_name"
                       disabled
                       id="terminal">
              </mat-form-field>
              <p class="subtitle">
                Terminal
              </p>
            </div>
          </div>
          <div class="row bordered mb-3">
            <div class="col-12 col-sm-4">
              <p>No</p>
              <p  class="subtitle">Хүргэлт</p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>No</p>
              <p class="subtitle">
                УБ-д гааль хийлгэх
              </p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>{{box_height}}x{{box_length}}x{{box_width}}</p>
              <p class="subtitle">
                /L x W x H/ (cm)
              </p>
            </div>
          </div>
          <div class="row bordered mb-3">
            <div class="col-12 col-sm-4">
              <p>{{sales_name}}</p>
              <p class="subtitle">Sales Manager</p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>{{tariff_name}}</p>
              <p class="subtitle">
                Tariff Manager
              </p>
            </div>
            <div class="col-12 col-sm-4 ">
              <p>{{quotation_date}}</p>
              <p class="subtitle">
                Quotation date
              </p>
            </div>
          </div>

          <div class="d-flex fsize">
            <mat-form-field >
              <mat-label>Cntr/AWB/Truck/Wagon</mat-label>
              <input matInput type="text" [(ngModel)]="cntr_name_new">
            </mat-form-field>
            <button type="button" color="primary" class="savebtn ml-3 btn btn-primary" (click)="editCntr()">Хадгалах</button>
            <button type="button" color="primary" class="savebtn ml-3 btn btn-primary" (click)="showCntr(cntrModal)">All CNTR</button>
          </div>
        </div>
        
      </mat-expansion-panel>
    </mat-accordion>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            Tracing Info
            <span style="float: right;">
              <button
                type="button"
                class="btn btn-tool table-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Тээврийн мэдээ"
                (click)="report()"
              >
              <i class="fas fa-file"></i>
              </button>
            </span>
          </div>
          <div class="card-body d-flex">
            <mat-form-field class="example-full-width text pr-3">
              <mat-label>Country</mat-label>
              <mat-select  [(ngModel)]="country_id" (selectionChange) = "countryChange()">
                <mat-option
                  *ngFor="let con of country"
                  [value]="con.id"
                >
                  {{ con.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width text pr-3">
              <mat-label>Citys</mat-label>
              <mat-select [(ngModel)]="city_id">
                <mat-option
                  *ngFor="let cit of city"
                  [value]="cit.id"
                >
                  {{ cit.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width fsize pr-3" >
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="date" >
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
    
            <mat-form-field class="example-full-width text pr-3">
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="status_id">
                <mat-option
                  *ngFor="let sta of status"
                  [value]="sta.id"
                >
                  {{ sta.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button type="button" color="primary" class="savebtn ml-3 btn btn-primary" (click)="addTrace()">Нэмэх</button>
          </div>
          <div class="table_wrapper" *ngIf="shipping_order_info_traces.length > 0">
            <table class="table table-striped table-hovered custom-table">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Location</th>
                  <th scope="col"></th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trace of shipping_order_info_traces;let index = index;">                  
                  <th>{{ index+1 }}</th>
                  <td>{{trace.country_name}}</td>
                  <td>{{trace.city_name}}</td>
                  <td>{{trace.trace_date}}</td>
                  <td>{{trace.status_name}}</td>
                  <th scope="row">
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Устгах"
                      (click)="deleteTrace(trace.id)"
                    >
                    <i class="fa fa-trash"></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>        
      </div>
      <div class="col-8">
        <div class="card">
          <div class="card-header">
            Tracing photos
          </div>
          <div class="card-body">
            <div class="row">
              <div class=" col-12">
                <p>Явцын Зургууд</p>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" multiple="" (change)="setFileName(fileInput)" #fileInput class="custom-file-input" id="file" name="file" accept="image/x-png,image/gif,image/jpeg,image/png">
                    <label class="custom-file-label" for="file" id="img-label">Зургууд оруулах</label>
                  </div>
                  <div class="custom-file">
                    <button type="button" color="primary" class="savebtn ml-3 btn btn-primary" (click)="setFiles()">Хадгалах</button>
                  </div>
                </div>
              </div>    
            </div>            
          </div>
          <div class="card-body d-flex">
            <div class="row">
              <div class=" col-12">
                <ngb-carousel *ngIf="shipping_order_files.length > 0">
                  <ng-template ngbSlide *ngFor="let file of shipping_order_files">
                    <div class="picsum-img-wrapper">
                      <img src="{{imageFileUrl}}/images/{{file.image}}" [alt]="file.name" />
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-header">
            Хилийн мэдээ
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <mat-form-field class="example-full-width fsize pr-3 mb-5" >
                  <mat-label>ETA Border</mat-label>
                  <input matInput [matDatepicker]="picker1"  (click)="picker1.open()" [(ngModel)]="eta_border" >
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="example-full-width fsize pr-3 mb-5" >
                  <mat-label>ATA Border</mat-label>
                  <input matInput [matDatepicker]="picker3"  (click)="picker3.open()" [(ngModel)]="atd_border" >
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="example-full-width fsize pr-3 mb-5" >
                  <mat-label>ATD Border</mat-label>
                  <input matInput [matDatepicker]="picker2"  (click)="picker2.open()" [(ngModel)]="ata_border" >
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="example-full-width fsize pr-3 mb-5" >
                  <mat-label>ATA Destination</mat-label>
                  <input matInput [matDatepicker]="picker4"  (click)="picker4.open()" [(ngModel)]="ata_destination" >
                  <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
              </div>
              <mat-form-field class="customWidth pr-3" style="width: 100%;">
                <mat-label>Customs</mat-label>
                <mat-select [(ngModel)]="custom_id">
                  <mat-option
                    *ngFor="let custom of custom_users"
                    [value]="custom.id"
                  >
                    {{ custom.lastname }} -н {{ custom.firstname }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <button type="button" color="primary" class="savebtn ml-3 btn btn-primary" (click)="setGaali()">Шинэчлэх</button>
          </div>
        </div>

      </div>
    </div>

  </div>

  <ng-template #cntrModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Тээврийн замын бүх Cntr/AWB/Truck/Wagon</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p *ngFor="let cnt of cntrs" style="border-bottom: 1px solid #d3d3d3;">{{cnt.name}} <span style="float: right;">{{cnt.cntr_date}}</span></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Ok</button>
    </div>
  </ng-template>
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
  <p style="color: #bf2429 " > Уншиж байна... </p>
</ngx-spinner>