import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-inquiry-download',
  templateUrl: './inquiry-download.component.html',
  styleUrls: ['./inquiry-download.component.scss'],
})
export class InquiryDownloadComponent implements OnInit {
  public id;
  public consignee_name;
  public commodity;
  public validaty_date;
  pol_country_name;
  pol_city_name;
  final_country_name;
  final_city_name;
  expenses = [];
  supplier_name;
  transit_time;
  quantity;
  incoterm_name;
  user_name;
  user_phone;
  inquiry_type_name;
  transport_mode_name;
  cbm;
  box_weight;
  dateNow;
  total = 0;
  constructor(
    private data: DataProvider,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Price Offer");
  }

  ngOnInit() {
    if (!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get('id');

    this.spinner.show();
    this.rest.inquiryDetail(this.id).subscribe((res) => {
      this.spinner.hide();
      this.consignee_name = res.detail.consignee_name;
      this.commodity = res.detail.commodity;
      this.validaty_date = res.detail.validaty_date.substring(0, 10);
      this.pol_country_name = res.detail.pol_country_name;
      this.pol_city_name = res.detail.pol_city_name;
      this.final_country_name = res.detail.final_country_name;
      this.final_city_name = res.detail.final_city_name;
      this.expenses = res.inquiry_expences;
      this.supplier_name = res.detail.supplier_name;
      this.transit_time = res.inquiry_quotations.transit_time;
      this.quantity = res.inquiry_weights[0].quantity;
      this.incoterm_name = res.detail.incoterm_name;
      this.user_name = res.detail.user_name;
      this.user_phone = res.detail.user_phone;
      this.inquiry_type_name = res.detail.inquiry_type_name;
      this.transport_mode_name = res.detail.transport_mode_name;
      this.cbm = res.inquiry_weights[0].cbm;
      this.box_weight =res.inquiry_weights[0].box_weight;
      this.dateNow = new Date().toISOString().substring(0,10);
      for(let i = 0; i < this.expenses.length; i++) {
        this.total = this.total + this.expenses[i].total_amount;
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }
}
