<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Custom</h1>
        </div>
      </div>
    </div>
</section>
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">        
        <div class="col-md-4">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Custom мэдээлэл</h3>
            </div> 
            <div class="card-body m-0">
                <div class="row bordered item-row" *ngFor="let custom of customs;let index = index;">
                    <div class="col-12 col-sm-12" (click)="loadCustom(index)">
                        <p>
                          {{custom.shipping_order_name}}/{{custom.cntr_name}}
                        </p>
                        <p class="subtitle">{{custom.consignee_name}}, {{custom.consignee_phone}}, {{custom.consignee_email}}</p>
                        <p class="subtitle">{{custom.trans_type_name}}/<span style="color: red;">{{type == 'uncustom' ? 'Хилийн огноо дутуу' : ''}}</span></p>
                        <div class="right-arrow">
                            <button
                                type="button"
                                class="btn btn-tool table-tool"
                                data-widget="collapse"
                                data-toggle="tooltip"
                                title="Дэлгэрэнгүй"
                            >
                            <i class="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <p *ngIf="customs.length == 0">Мэдээлэл одоогоор ирээгүй байна.</p>
            </div>          
            <div class="card-footer">
              <div *ngIf="customs.length >= 1" style="display: inline-block;">
                <label class="pages">All {{customs.length}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" id="right-side" style="display: none;">
            <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">Shipment Information</h3>
                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Inquiry харах"
                      (click)="inquiryBack(inquiry_id)"
                    >
                      <i class="nav-icon fas fa-notes-medical"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Operation зургууд"
                      (click)="openImage(imageModal, id)"
                    >
                      <i class="fas fa-file"></i>
                    </button>                    
                  </div>
                </div> 
                <div class="card-body m-0">
                    <div class="row bordered mb-3">
                        <div class="col-12 col-sm-4">
                          <p>
                            {{commodity}}
                          </p>
                          <p  class="subtitle">Ачааны нэр</p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                          <p>{{trans_type_name}}</p>
                          <p class="subtitle">
                            Transportation Type
                          </p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                          <p>
                            {{shipping_order_name}}
                          </p>
                          <p class="subtitle">
                            Tracking Code
                          </p>
                        </div>
                      </div>
                      <div class="row bordered mb-3">
                        <div class="col-12 col-sm-4">
                          <p>
                            {{cntr_name}}
                          </p>
                          <p  class="subtitle">Cntr/AWB/Truck</p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                            <p>{{pol_country_name}}</p>
                            <p class="subtitle">
                              {{pol_city_name}}
                            </p>
                          </div>
                          <div class="col-12 col-sm-4 ">
                            <p>{{final_country_name}}</p>
                            <p class="subtitle">
                              {{final_city_name}}
                            </p>
                          </div>
                      </div>
                      <div class="row bordered mb-3">
                        <div class="col-12 col-sm-4">
                          <p> {{box_weight}} kg </p>
                          <p class="subtitle">Ачааны жин</p>
                        </div>
                        <div class="col-12 col-sm-4 ">
                          <p>{{box_length}}x{{box_width}}x{{box_height}}</p>
                          <p class="subtitle">
                            /L x W x H/ (cm)
                          </p>
                        </div>
                        <div class="col-12 col-sm-4">
                            <p> {{terminal_name}} </p>
                            <p class="subtitle">Терминал</p>
                          </div>
                      </div>
                      <div class="row bordered mb-3">
                        <div class="col-12 col-sm-3">
                          <p>
                            {{ata_destination}}
                          </p>
                          <p  class="subtitle">ATA Destination</p>
                        </div>
                        <div class="col-12 col-sm-3">
                            <p>{{ata_border}}</p>
                            <p class="subtitle">
                                ATA Border
                            </p>
                          </div>
                          <div class="col-12 col-sm-3">
                            <p>{{eta_border}}</p>
                            <p class="subtitle">
                                ETA Border
                            </p>
                          </div>
                          <div class="col-12 col-sm-3">
                            <p>{{atd_border}}</p>
                            <p class="subtitle">
                                ATD Border
                            </p>
                          </div>
                      </div>
                </div>      
                <div class="card-footer">
                  
                </div>
            </div>
            <div class="card card-primary" *ngIf="data.userData.permission == 8">
                <!-- <div class="card-header">
                  <h3 class="card-title">Shipment Information</h3>
                </div>  -->
                <div class="card-body m-0">
                    <div class="row">
                        <div class="col-6">
                          <mat-form-field class="example-full-width fsize" >
                            <mat-label>CC Started</mat-label>
                            <input matInput [matDatepicker]="picker1" (click)="picker1.open()" [(ngModel)]="cc_started" >
                            <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field class="example-full-width fsize" >
                            <mat-label>CC Finished</mat-label>
                            <input matInput [matDatepicker]="picker3" (click)="picker3.open()" [(ngModel)]="cc_finished" >
                            <mat-datepicker #picker3></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field class="example-full-width fsize">
                            <mat-label>Sites</mat-label>
                            <mat-select [(ngModel)]="site_id">
                              <mat-option
                                *ngFor="let site of site_users"
                                [value]="site.id"
                              >
                                {{ site.lastname }} -н {{ site.firstname }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="example-full-width fsize">
                                <mat-label>Comments</mat-label>
                                <textarea matInput class="textHeight" rows="8" [(ngModel)]="comments" ></textarea>
                            </mat-form-field>
                        </div>                        
                      </div>
                      <button type="button" color="primary" class="savebtn ml-3 btn btn-primary" (click)="editCustom()">Шинэчлэх</button>
                </div>
            </div>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
    <ng-template #imageModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Тээврийн явцын зургууд</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <ngb-carousel *ngIf="shipping_order_files.length > 0">
          <ng-template ngbSlide *ngFor="let file of shipping_order_files">
            <div class="picsum-img-wrapper">
              <img src="{{imageFileUrl}}/images/{{file.image}}" [alt]="file.name" />
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Ok</button>
      </div>
    </ng-template>
  </section>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
    <p style="color: #bf2429 " > Уншиж байна... </p>
  </ngx-spinner>