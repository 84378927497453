import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/utils/services/app.service';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-payable',
  templateUrl: './payable.component.html',
  styleUrls: ['./payable.component.scss'],
})

export class PayableComponent implements OnInit {
  public id: any;
  countries = [];
  Consignee = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  nameFormControl = new FormControl();
  consignee: any;
	total = 13;
  loading = false;
  searchTerm = '';
  page = 1;
  filteredItems = [];
  payables_old = [];
  pages : number = 6;
  pageSize : number = 10;
  pageNumber : number = 0;
  currentIndex : number = 1;
  items = [];
  pagesIndex : Array<number>;
  pageStart : number = 1;
  consignees = [];
  consigneesOrig = [];
  consignee_id;
  startDate;
  payables =[];
  allTotal = 0;

  constructor(
    public appService: AppService,
    public data: DataProvider,
    public router: Router,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    ) {
    data.menuPageTitle = 'payable';
  }

  public _filterCons() {
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    const filterValue = input.value.toLowerCase();    
    this.consignees = this.consigneesOrig.filter(option => option.name.toLowerCase().includes(filterValue));
    if(this.consignees.length == 0) {
      this.consignee_id = input.value;
    }
  }

  onConsigneeSelected(option: any) {
    for(let i = 0; i < this.consignees.length; i++) {
      if(this.consignees[i].name == option.value) {
        this.consignee_id = this.consignees[i].id;
        break;
      }
    }
  }

  ngOnInit() {
    if(!this.appService.checkAuth()) {
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
    this.spinner.show();
    this.rest.payableList().subscribe(
      (res) => {
        this.payables = res;
        let prevSM = '';
        let sum = 0;
        for(let i = 0; i < this.payables.length; i++) {
          if(prevSM == '') {
            sum = sum + this.payables[i].price*this.payables[i].rate;
            prevSM = this.payables[i].shipping_order_name;
          } else if(prevSM === this.payables[i].shipping_order_name) {
            sum = sum + this.payables[i].price*this.payables[i].rate;
            prevSM = this.payables[i].shipping_order_name;
            this.payables[i].shipping_order_name = '';
            if(i == this.payables.length - 1) {
              this.payables.splice(i + 1, 0, {shipping_order_name: 'НИЙТ ДҮН',price: sum,rate: 1,currency_name: 'MNT',confirmed: '',id: 0});
              this.allTotal = this.allTotal + sum;
              i++;
            }
          } else {
            this.payables.splice(i, 0, {shipping_order_name: 'НИЙТ ДҮН',price: sum,rate: 1,currency_name: 'MNT',confirmed: '',id: 0});
            this.allTotal = this.allTotal + sum;
            i++;
            sum = this.payables[i].price*this.payables[i].rate;
            prevSM = this.payables[i].shipping_order_name;
          }          
        }
        this.rest.consignees().subscribe(
          (res) => {
            this.consignees = res.consignees;
            this.consigneesOrig = res.consignees;
            this.spinner.hide();
          }, (err) => {
            console.log(err);
            this.spinner.hide();
          }
        );
    
        this.filteredItems = this.payables;
        this.payables_old = this.payables;
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;
        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
    this.filteredItems = this.payables;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;
    this.pageNumber = parseInt(
      '' + this.filteredItems.length / this.pageSize
    );
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }
    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.payables = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  clear() {
    this.currentIndex = 1;
    this.pageStart = 1;
    this.searchTerm = '';
    this.consignee_id = '';
    const input = document.getElementById(
      'consignee',
    ) as HTMLInputElement | null;    
    input.value = '';
    this.startDate = '';
    this.filteredItems = this.payables_old;
    this.refreshItems();
  }

  search() {
    let ch = 0;
    if(this.searchTerm) {
      this.filteredItems = this.filteredItems.filter(option => option.shipping_order_name.toLowerCase().includes(this.searchTerm));
    } else {
      ch++;
    }
    if(this.consignee_id) {
      this.filteredItems = this.filteredItems.filter(option => option.consignee_id == this.consignee_id);
    } else {
      ch++;
    }
    if(this.startDate) {
      var m = new Date(new Date(this.startDate).toLocaleString('en', {timeZone: 'Asia/Ulaanbaatar'}));
      var dateString =
          m.getFullYear() + "-" +
          ("0" + (m.getMonth()+1)).slice(-2) + "-" +
          ("0" + m.getDate()).slice(-2) + " " +
          ("0" + m.getHours()).slice(-2) + ":" +
          ("0" + m.getMinutes()).slice(-2) + ":" +
          ("0" + m.getSeconds()).slice(-2);
      this.filteredItems = this.filteredItems.filter(option => option.so_start_date >= dateString.substring(0,10));
    } else {
      ch++;
    }
    if(ch == 3) {
      this.filteredItems = this.payables_old;
    }
    this.refreshItems();
  }

  shippingOrderBack(id: any) {
    this.router.navigate(['/shipping-order/edit', {id: id}]);
  }

  download(id: any) {
    this.spinner.show();
    this.rest.payableFinish(id).subscribe((res) => {
      this.spinner.hide();
      if(res.message == 'OK') {
        this.toastr.success('Success', 'Амжилттай төлсөн.');
        window.location.reload();
      } else {
        this.toastr.error('Алдаа!', 'Системийн алдаа гарлаа та дахин оролдоно уу!');
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    });
  }

  print(id: any, pre_payment: any) {
    
  }

  exportExcel() { 
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Payable');
    worksheet.columns = [
      { header: 'SO/No', key: 'No', width: 15 },
      { header: 'Agent', key: 'Agent', width: 32 },
      { header: 'Total', key: 'Total', width: 10 },
      { header: 'Currency', key: 'Currency', width: 10 },
      { header: 'Rate', key: 'Rate', width: 10 },
      { header: 'Total(MNT)', key: 'TotalMNT', width: 10 },
      { header: 'Paid Amount(MNT)', key: 'Income', width: 10 },
      { header: 'Unpaid Amount(MNT)', key: 'Rest', width: 10 },
      { header: 'Confirmed At', key: 'Confirmed', width: 10 },
    ];

    for(let i = 0; i < this.payables_old.length; i++) {
      worksheet.addRow(
        {
          No: this.payables_old[i].shipping_order_name, 
          Agent: this.payables_old[i].agent_name, 
          Total: this.payables_old[i].price, 
          Currency: this.payables_old[i].currency_name,
          Rate: this.payables_old[i].price_rate,
          TotalMNT: this.payables_old[i].price,
          Income: 0.00,
          Rest: this.payables_old[i].price,
          Confirmed: this.payables_old[i].confirmed
        }, 'n'
      );
    }
 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Payable.xlsx');
    })
  }
}
