<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6 col-xs-6 col-6">
          <h1>Finance</h1>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
            <div class="mb-3 row">
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">SM</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="nameFormControl"
                    [(ngModel)]="searchTerm"
                    name="searchName"
                  />
                </mat-form-field>
                <!-- <input
                  id="table-complete-search"
                  type="text"
                  class="form-control"
                  placeholder="Name"
                  name="searchTerm"
                  [(ngModel)]="searchTerm"
                /> -->
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="text">Consignee</mat-label>
                  <input type="text"
                        matInput
                        class="cons-input"
                        [formControl]="Consignee"
                        [matAutocomplete]="auto1"
                        (ngModelChange)="_filterCons()"
                        id="consignee"
                        >
                        
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  (optionSelected)="onConsigneeSelected($event.option)" >
                    <mat-option *ngFor="let option of consignees" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Start</mat-label>
                  <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="startDate" >
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
    
              <span class="col col-form-label" *ngIf="loading">Loading...</span>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Шинэчлэх"
                (click)="clear()"
              >
                <i class="fa fa-times"></i>
              </button>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Хайх"
                (click)="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          <div class="table_wrapper">
            <table class="table table-bordered table-hovered custom-table">
              <thead>
                <tr>
                  <th scope="col">Actions</th>
                  <!-- <th scope="col">Төлөв</th> -->
                  <th scope="col">SO/No</th>
                  <th scope="col">Cntr/AWB/Truck/Wagon</th>
                  <th scope="col">Completion</th>
                  <th scope="col">Consignee</th>
                  <th scope="col">Payer</th>
                  <th scope="col">THC</th>
                  <th scope="col">Payment Type</th>
                  <th scope="col">Origin</th>
                  <th scope="col">Via</th>
                  <th scope="col">Destination</th>
                  <th scope="col">Total</th>
                  <th scope="col">Cntr Status</th>
                  <th scope="col">Sales Manager</th>
                  <th scope="col">Tariff Manager</th>
                  <th scope="col">Invoice</th>
                  <th scope="col">Comment</th>
                  <th scope="col">Released</th>
                  <th scope="col">ATA Border</th>
                  <th scope="col">ATA UB</th>
                  <th scope="col">Shipping Order At</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let finance of finances">                
                  <th scope="row">
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Shipping order Print"
                      (click)="download(finance.id)"
                    >
                    <i class="fas fa-print"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Нэхэмжлэх үүсгэх"
                      (click)="createInvoice(finance.id, finance.pre_payment)"
                      *ngIf="data.userData.permission == 6 || data.userData.permission == 2 || data.userData.permission == 1"
                    >
                    <i class="fas fa-credit-card"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Custom Invoice үүсгэх"
                      (click)="print(finance.id)"
                      *ngIf="data.userData.permission == 6 || data.userData.permission == 2 || data.userData.permission == 1"
                    >
                    <i class="fas fa-plus-square"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Ачааг дуусгах хүсэлт илгээх"
                      (click)="invoiceFinish(finance.shipping_order_info_id, 'y')"
                    >
                      <i class="fas fa-share"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-tool table-tool"
                      data-widget="collapse"
                      data-toggle="tooltip"
                      title="Ачааг дуусгах хүсэлт илгээх төлбөр дараа авах"
                      (click)="invoiceFinish(finance.shipping_order_info_id, 'n')"
                    >
                      <i class="fa fa-share-square"></i>
                    </button>
                  </th>
                  <!-- <th scope="row" *ngIf="finance.cntr_name == ''" style="color: red;">Тээврийн мэдээ дутуу байна</th>
                  <th scope="row" *ngIf="finance.cntr_name != '' && finance.files.length == 0" style="color: red;">Тээврийн файл ороогүй байна</th>
                  <th scope="row" *ngIf="finance.cntr_name != '' && finance.files.length > 0 && finance.eta_border == null" style="color: red;">
                    Хилийн мэдээ ороогүй байна</th> -->
                  <th scope="row"
                  title="Shipping order харах"
                  (click)="shippingOrderBack(finance.id)"
                  class="edit">
                  #{{ finance.shipping_order_name }}</th>
                  <td>{{finance.cntr_name}}</td>
                  <td>
                    <div style="width: 75px;border:1px solid #e7aead;
                    height: 17px;/*opacity: 0.3;*/color: #fff;font-size: 12px;
                    border-color: #e7aead !important;text-align: center;
                    "
                    [ngStyle]="{'background': 'linear-gradient(to right, #bf0603 ' + finance.pre_payment + '%, #e7aead ' + (100-finance.pre_payment) + '%)'}">
                      {{finance.pre_payment}}%
                    </div>
                  </td>
                  <td>{{finance.consignee_name}}</td>
                  <td>{{finance.payer_name}}</td>
                  <td>{{finance.terminal_name}}</td>
                  <td>{{finance.payment_type_name}}</td>
                  <td>{{finance.pol_country_name}}, {{finance.pol_city_name}}</td>
                  <td>{{finance.via_name}}</td>
                  <td>{{finance.final_country_name}}, {{finance.final_city_name}}</td>
                  <td>{{finance.price*finance.price_rate | currency : ' '}}</td>
                  <td>{{finance.container_name}}</td>
                  <td>{{finance.sales_name}}</td>
                  <td>{{finance.tariff_name}}</td>
                  <td>{{finance.invoice_date}}</td>
                  <td>{{finance.comments}}</td>
                  <td>{{finance.released_at}}</td>
                  <td>{{finance.ata_border}}</td>
                  <td>{{finance.ata_destination}}</td>
                  <td>{{finance.so_start_date}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
            <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
          </div>
          <div *ngIf="filteredItems.length >= 1" style="display: inline-block;float: right;">
            <label>НИЙТ ДҮН: {{allTotal | currency : ' '}}₮</label>
          </div>
          <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
            <label class="pages">No Finance List</label>
          </div>
          <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
            <span
              [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
              (click)="prevPage()"
              class="prev"
              >&laquo;
            </span>
            <span
              *ngFor="let page of pagesIndex"
              [ngClass]="{ tod: currentIndex == page }"
              class="dot"
              (click)="setPage(page)"
            >{{page}}</span>
            <span
              [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
              (click)="nextPage()"
              class="next"
              >&raquo;
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
    <p style="color: #bf2429 " > Уншиж байна... </p>
  </ngx-spinner>
  