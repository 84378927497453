<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2" >
        <div class="col-sm-6 col-xs-6 col-6">
          <h1>Report ({{sname}})</h1>
        </div>
        <div class="col-sm-6 col-xs-6 col-6">
          <ol class="breadcrumb float-right" >
            <button
              type="button"
              class="btn btn-tool"
              data-widget="collapse"
              data-toggle="tooltip"
              title="Excel Export"
              (click)="exportExcel()"
            >
              <i class="fas fa-file-excel"></i>
            </button>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content">
    <!-- Default box -->
    <div class="container-fluid">
      <div class="card">
        
        <div class="card-body">
          <!-- <form name="form"> -->
            <div class="mb-3 row">
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="type" (selectionChange)="changeName()">
                        <mat-option value="Payable">Payable</mat-option>
                        <mat-option value="Receivable">Receivable</mat-option>
                        <mat-option value="TransMode">TransMode</mat-option>
                        <mat-option value="TransMode FCL">TransMode FCL</mat-option>
                        <mat-option value="Sales Profit">Sales Profit</mat-option>
                        <mat-option value="Tariff Profit">Tariff Profit</mat-option>
                        <mat-option value="Sales Shipment">Sales Shipment</mat-option>
                        <mat-option value="Tariff Shipment">Tariff Shipment</mat-option>
                        <!-- <mat-option value="Payer Profit">Payer Profit</mat-option> -->
                        <mat-option value="Consignee">Consignee</mat-option>
                        <mat-option value="Average Profit">Average Profit</mat-option>
                        <mat-option value="Container Status">Container Status</mat-option>
                        <!-- <mat-option value="Operation Manager">Operation Manager</mat-option> -->
                        <mat-option value="Terminal">Terminal</mat-option>
                        <!-- <mat-option value="Agent">Agent</mat-option> -->
                        <!-- <mat-option value="Agent Payment">Agent Payment</mat-option> -->
                        <mat-option value="Via">Via</mat-option>
                        <mat-option value="SO Sales">SO Sales</mat-option>
                        <mat-option value="SO Tariff">SO Tariff</mat-option>
                        <mat-option value="Inquiry">Inquiry</mat-option>
                        <mat-option value="Inquiry Cost">Inquiry Cost</mat-option>
                        <mat-option value="SO Cost">SO Cost</mat-option>
                        <mat-option value="Finished Cost">Finished Cost</mat-option>
                        <mat-option value="Inquiry History">Inquiry History</mat-option>
                        <mat-option value="SO History">SO History</mat-option>
                        <mat-option value="Operation History">Operation History</mat-option>
                        <mat-option value="Finance History">Finance History</mat-option>
                        <mat-option value="Цалин">Цалин</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width fsize" appearance="outline">
                    <mat-label>Start</mat-label>
                    <input matInput [matDatepicker]="picker"  (click)="picker.open()" [(ngModel)]="startDate" >
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-xs-3 col-sm-auto fsize">
                <mat-form-field class="example-full-width fsize" appearance="outline">
                    <mat-label>End</mat-label>
                    <input matInput [matDatepicker]="picker1"  (click)="picker1.open()"  [(ngModel)]="endDate">
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>  
              <span class="col col-form-label" *ngIf="loading">Loading...</span>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Шинэчлэх"
                (click)="clear()"
              >
                <i class="fa fa-times"></i>
              </button>
              <button
                type="button"
                class="btn btn-search-tool"
                data-widget="collapse"
                data-toggle="tooltip"
                title="Хайх"
                (click)="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          
            <div class="table_wrapper">
              <table class="table table-striped table-hovered" *ngIf="type == 'Payable' || type == 'Receivable'">
                <thead>
                  <tr>
                    <th scope="col">SO/NO</th>
                    <th scope="col">Байгууллагын нэр</th>
                    <th scope="col">Нийт дүн /MNT/</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.shipping_order_name }}</th>
                    <th scope="row">{{ report.agent_name }}</th>
                    <td>{{report.amount | currency : ' '}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'TransMode'">
                <thead>
                  <tr>
                    <th scope="col">Transportation Mode</th>
                    <th scope="col">Shipment Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.trans_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'TransMode FCL'">
                <thead>
                  <tr>
                    <th scope="col">FCL Type Name</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.trans_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Sales Profit'">
                <thead>
                  <tr>
                    <th scope="col">Sales Name</th>
                    <th scope="col">Total Profit/MNT/</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.sales_name }}</th>
                    <td>{{ report.profit | currency : ' ' }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Tariff Profit'">
                <thead>
                  <tr>
                    <th scope="col">Tariff Name</th>
                    <th scope="col">Total Profit/MNT/</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.tariff_name }}</th>
                    <td>{{ report.profit | currency : ' ' }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Sales Shipment'">
                <thead>
                  <tr>
                    <th scope="col">Sales Name</th>
                    <th scope="col">Total Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.sales_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Tariff Shipment'">
                <thead>
                  <tr>
                    <th scope="col">Tariff Name</th>
                    <th scope="col">Total Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.tariff_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Consignee'">
                <thead>
                  <tr>
                    <th scope="col">Consignee Name</th>
                    <th scope="col">Total Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.consignee_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Average Profit'">
                <thead>
                  <tr>
                    <th scope="col">Transportation Mode</th>
                    <th scope="col">Average Profit/MNT/</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.trans_name }}</th>
                    <td>{{ report.profit | currency : ' ' }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Container Status'">
                <thead>
                  <tr>
                    <th scope="col">Container Name</th>
                    <th scope="col">Total Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.container_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Terminal'">
                <thead>
                  <tr>
                    <th scope="col">Terminal Name</th>
                    <th scope="col">Total Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.terminal_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Via'">
                <thead>
                  <tr>
                    <th scope="col">Via Name</th>
                    <th scope="col">Total Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.via_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'SO Sales'">
                <thead>
                  <tr>
                    <th scope="col">Sales Name</th>
                    <th scope="col">Total Quantity</th>
                    <th scope="col">Total Profit/MNT/</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.sales_name }}</th>
                    <td>{{ report.quantity }}</td>
                    <td>{{ report.profit | currency : ' ' }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'SO Tariff'">
                <thead>
                  <tr>
                    <th scope="col">Tariff Name</th>
                    <th scope="col">Total Quantity</th>
                    <th scope="col">Total Profit/MNT/</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.tariff_name }}</th>
                    <td>{{ report.quantity }}</td>
                    <td>{{ report.profit | currency : ' ' }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-hovered" *ngIf="type == 'Inquiry'">
                <thead>
                  <tr>
                    <th scope="col">Төрөл</th>
                    <th scope="col">Total Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th scope="row">{{ report.inquiry_name }}</th>
                    <td>{{ report.quantity }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="row" *ngIf="type == 'Inquiry Cost'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'sales'">
                    <!-- small box -->
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3>Орлого: {{report.amount | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Ашиг: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p (click)="reportUser(report.id,report.type)" style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="type == 'Inquiry Cost'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'tariff'">
                    <!-- small box -->
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>Орлого: {{report.amount | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Зарлага: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p (click)="reportUser(report.id,report.type)" style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="type == 'SO Cost'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'sales'">
                    <!-- small box -->
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3>Орлого: {{report.amount | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Ашиг: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p (click)="reportUser(report.id,report.type)" style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="type == 'SO Cost'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'tariff'">
                    <!-- small box -->
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>Орлого: {{report.amount | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Зарлага: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p (click)="reportUser(report.id,report.type)" style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="type == 'Finished Cost'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'sales'">
                    <!-- small box -->
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3>Орлого: {{report.amount | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Ашиг: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p (click)="reportUser(report.id,report.type)" style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="type == 'Finished Cost'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'tariff'">
                    <!-- small box -->
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>Орлого: {{report.amount | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Зарлага: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p (click)="reportUser(report.id,report.type)" style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table class="table table-striped table-hovered" *ngIf="type == 'Inquiry History'">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">User</th>
                    <th scope="col">Төрөл</th>
                    <th scope="col">Тайлбар</th>
                    <th scope="col">Огноо</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th>{{ report.name }}</th>
                    <th scope="row">{{ report.sales_lastname }}-н {{ report.sales_firstname }}</th>                    
                    <td>{{ report.sales_type }}</td>
                    <td>{{ report.descr }}</td>
                    <td>{{ report.history_date }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-striped table-hovered" *ngIf="type == 'SO History'">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">User</th>
                    <th scope="col">Төрөл</th>
                    <th scope="col">Тайлбар</th>
                    <th scope="col">Огноо</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th>{{ report.shipping_order_name }}</th>
                    <th scope="row">{{ report.sales_lastname }}-н {{ report.sales_firstname }}</th>                    
                    <td>{{ report.sales_type }}</td>
                    <td>{{ report.descr }}</td>
                    <td>{{ report.history_date }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-striped table-hovered" *ngIf="type == 'Operation History'">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">User</th>
                    <th scope="col">Төрөл</th>
                    <th scope="col">Тайлбар</th>
                    <th scope="col">Огноо</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th>{{ report.shipping_order_name }}</th>
                    <th scope="row">{{ report.sales_lastname }}-н {{ report.sales_firstname }}</th>                    
                    <td>{{ report.sales_type }}</td>
                    <td>{{ report.descr }}</td>
                    <td>{{ report.history_date }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-striped table-hovered" *ngIf="type == 'Finance History'">
                <thead>
                  <tr>
                    <th scope="col">SO Name</th>
                    <th scope="col">Tariff Price Name</th>
                    <th scope="col">User</th>
                    <th scope="col">Төрөл</th>
                    <th scope="col">Тайлбар</th>
                    <th scope="col">Огноо</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of reports">
                    <th>{{ report.shipping_order_name }}</th>
                    <th>{{ report.name }}</th>
                    <th scope="row">{{ report.sales_lastname }}-н {{ report.sales_firstname }}</th>                    
                    <td>{{ report.sales_type }}</td>
                    <td>{{ report.descr }}</td>
                    <td>{{ report.history_date }}</td>
                  </tr>
                </tbody>
              </table>

              <div class="row" *ngIf="type == 'Цалин'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'sales'">
                    <!-- small box -->
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3>Цалин: {{report.profit/10 | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Ашиг: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="type == 'Цалин'">
                <div *ngFor="let report of reports" style="width: 100%;">
                  <div class="col-lg-3 col-6" *ngIf="report.type == 'tariff'">
                    <!-- small box -->
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>Цалин: {{report.profit/10 | currency : ' '}}<sup style="font-size: 14px;">₮</sup><span style="float: right;">{{report.quantity}}ш</span></h3>
                        <h3>Ашиг: {{report.profit | currency : ' '}}<sup style="font-size: 14px;">₮</sup></h3>
                        <p style="cursor: pointer;text-align: center;">{{report.name}} </p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          <!-- </form> -->
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <div *ngIf="filteredItems.length >= 1" style="display: inline-block;">
            <label class="pages">(All {{filteredItems.length}}) Page {{ currentIndex }}/{{ pageNumber }}</label>
          </div>
          <div *ngIf="filteredItems.length == 0" style="display: inline-block;">
            <label class="pages">No Report({{type}}) List</label>
          </div>
          <div class="page-point" *ngIf="filteredItems.length >= 1" style="display: inline-block;margin-left: 15px;">
            <span
              [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
              (click)="prevPage()"
              class="prev"
              >&laquo;
            </span>
            <span
              *ngFor="let page of pagesIndex"
              [ngClass]="{ tod: currentIndex == page }"
              class="dot"
              (click)="setPage(page)"
            >{{page}}</span>
            <span
              [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
              (click)="nextPage()"
              class="next"
              >&raquo;
            </span>
          </div>
        </div>
        <!-- /.card-footer-->
      </div>
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#bf2429" type = "square-loader" [fullScreen] = "true">
    <p style="color: #bf2429 " > Уншиж байна... </p>
  </ngx-spinner>